import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce as _debounce } from 'lodash';
import moment from 'moment';
import { Modal } from '@salesforce/design-system-react';

import FilterSelect from 'containers/CentralWalletSystem/Filter/FilterSelect';

import DatePickerField from 'components/Inputs/Datepicker/DatePickerField';
import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import DownloadButton from 'components/Buttons/DownloadButton/DownloadButton';
import ModalButton from 'components/Buttons/ModalButton/ModalButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import Grid from 'components/Grid/Grid';
import AuditTrailList from 'components/AuditTrailList/AuditTrailList';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import LookupFieldWithExpanded from 'components/Inputs/LookupFieldWithExpanded';

import { ReactComponent as AuditTrailIcon } from 'assets/icons/ic-audit-trail.svg';

import client from 'helpers/ApiClient';
import { BfaAdminUserStatuses, BfaAdminUserTypes } from 'utils/lookup';
import { useErrorModal, useLoader, useToggle } from 'utils/hooks';
import { formatDate, formatParams } from 'utils/common';
import userFilterSchema from 'utils/formSchemas/bfaAdminPortal/userFilter';
import { useHasUserPermissionWithModal } from 'utils/permissions';

import {
	AuditTrailResp,
	UsersFilterOptions,
	UsersFilterFormData,
} from '../types';
import styles from './UserHeader.module.css';

type Props = {
	onSubmit: (values: any) => void;
	initialValues?: Partial<UsersFilterFormData>;
	onDownload: () => void;
	showErrorMessage: (
		header: any,
		message: any,
		instruction?: any,
		onRetryBtnClick?: () => void
	) => void;
};

const TABLE_WIDTH = 100 / 5;

const AUDIT_TRAIL_COLUMNS = () => [
	{
		Header: 'Date and Time',
		id: 'createdDate',
		sortable: false,
		width: `${TABLE_WIDTH}%`,
		accessor: ({ createdDate }) => formatDate(createdDate),
	},
	{
		Header: 'Logged By',
		id: 'loggedBy',
		sortable: false,
		width: `${TABLE_WIDTH}%`,
	},
	{
		Header: 'Action',
		id: 'action',
		sortable: false,
		width: `${TABLE_WIDTH}%`,
	},
	{
		Header: 'Source IP',
		id: 'sourceIP',
		sortable: false,
		width: `${TABLE_WIDTH}%`,
	},
	{
		Header: 'Details',
		id: 'remarks',
		sortable: false,
		width: `${TABLE_WIDTH}%`,
	},
];

const UserHeader: React.FC<Props> = ({ onSubmit, onDownload }) => {
	const {
		getValues,
		handleSubmit,
		control,
		formState: { isValid, isDirty },
		reset,
		watch,
		trigger,
	} = useForm<UsersFilterFormData>({
		mode: 'all',
		resolver: yupResolver(userFilterSchema),
	});

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const [data, setData] = useState<AuditTrailResp | undefined>();
	const {
		value: isAuditTrailOpen,
		valueOn: openAuditTrail,
		valueOff: closeAuditTrail,
	} = useToggle();

	const handleFilterSubmit = (values) => {
		const { dateFrom, dateTo, ...rest } = values;
		onSubmit(
			{
				dateFrom: dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : undefined,
				dateTo: dateTo ? moment(dateTo).format('YYYY-MM-DD') : undefined,
				...rest,
			} || {}
		);
	};

	const handleSearch = useCallback(
		_debounce((v) => {
			const value = v || '';
			const filters = getValues() || {};
			if (value.length >= 3 || value === '') {
				handleFilterSubmit({ ...filters, search: value });
			}
		}, 500),
		[isDirty, isValid]
	);

	const handleReset = () => {
		reset();
		handleFilterSubmit({});
	};

	const fetchAuditTrail = async (params?: UsersFilterOptions) => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const apiUrl = 'v2/bfa-admin-portal/users/audit-trail/download';

			const param = {
				page: params?.page,
				limit: params?.limit,
			};

			await client
				.get(apiUrl, {
					params: param,
				})
				.then((response) => {
					setData(response.data);
				});
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => {
					closeAuditTrail();
					fetchAuditTrail(params);
				}
			);
		} finally {
			hideLoading();
			openAuditTrail();
		}
	};

	const hideAllModal = () => {
		hideErrorModal();
		closeAuditTrail();
	};

	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	// Retriggers the validation
	useEffect(() => {
		trigger('dateTo');
	}, [watch('dateFrom')]);

	useEffect(() => {
		trigger('dateFrom');
	}, [watch('dateTo')]);

	return (
		<>
			{isLoading && (
				<FullPageLoader open={isLoading} message={loadingMessage} />
			)}

			{isAuditTrailOpen && !isErrorModalShown && (
				<Modal
					isOpen={true}
					ariaHideApp={false}
					size="medium"
					onRequestClose={closeAuditTrail}
				>
					<AuditTrailList
						title="Audit Trail"
						columns={AUDIT_TRAIL_COLUMNS()}
						onClose={closeAuditTrail}
						auditTrailData={data}
						hasDetails={true}
						handleTableFetch={fetchAuditTrail}
					/>
				</Modal>
			)}

			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideAllModal}>
					<ErrorModalBody>
						<div className={styles.errorBodyContent}>
							<div className={styles.errorHeader}>{errorHeader}</div>
							<div className={styles.errorBody}>{errorMessage}</div>
							<div className={styles.errorFooter}>{errorInstruction}</div>
						</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => retryBtnOnClick.action()}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}

			<Filter title={'User List'} padding={'1.2vmax 0.7vmax 1.2vmax 0.7vmax'}>
				<FilterOuter>
					<Grid container align="end">
						<Grid container size={6} of={6} align="end" gutters="x-small">
							<Grid column size={3} of={10}>
								<InputWithIcon
									onChange={handleSearch}
									placeholder="Search"
									className={styles.searchBar}
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
										},
									]}
								/>
							</Grid>
							<Grid column size={2} of={10}>
								<DownloadButton
									className={styles.downloadButton}
									fullWidth
									onClick={() => {
										if (hasUserPermission('list.user.download')) {
											onDownload();
										}
									}}
								/>
							</Grid>
							<Grid column size={2} of={10}>
								<ModalButton
									onClick={() => {
										if (hasUserPermission('user.audit-trail')) {
											fetchAuditTrail({ page: 1, limit: 25 });
										}
									}}
									name="Audit&nbsp;Trail"
									icon={<AuditTrailIcon />}
								/>
							</Grid>
						</Grid>
					</Grid>
				</FilterOuter>
				<FilterInner>
					<Grid
						container
						align="spread"
						verticalAlign="start"
						gutters="xx-small"
						className={styles.filter}
					>
						<Grid column size={2} of={12}>
							<DatePickerField
								control={control}
								name="dateFrom"
								label="Date From"
								className={styles.dateField}
							/>
						</Grid>
						<Grid column size={2} of={12}>
							<DatePickerField
								control={control}
								name="dateTo"
								label="Date To"
								className={styles.dateField}
							/>
						</Grid>
						<Grid column size={2} of={12}>
							<InputWithIcon
								control={control}
								name="username"
								label="Username"
								placeholder="Username"
								icons={[
									{
										path: 'utility/search',
										isLeft: false,
										className: styles.inputIcon,
									},
								]}
								labelClassName={styles.label}
								adjustInputHeight={true}
								className={styles.textFieldWithIcon}
							/>
						</Grid>
						<Grid column size={2} of={12}>
							<LookupFieldWithExpanded
								name="role"
								displayName="Role"
								control={control}
								apiRoute="/v2/bfa-admin-portal/utils/roles"
								isSearchable
								className={styles.lookupTextField}
								labelClassName={styles.lookupTextLabel}
							/>
						</Grid>
						<Grid column size={2} of={12}>
							<LookupFieldWithExpanded
								name="branch"
								displayName="Branch"
								control={control}
								apiRoute="/v2/bfa-admin-portal/utils/branches"
								isSearchable
								className={styles.lookupTextField}
								labelClassName={styles.lookupTextLabel}
							/>
						</Grid>
						<Grid column size={2} of={12}>
							<FilterSelect
								name="userType"
								label="User Type"
								control={control}
								options={BfaAdminUserTypes}
								style={{ width: '100%' }}
							/>
						</Grid>
						<Grid column size={2} of={12}>
							<FilterSelect
								name="status"
								label="Status"
								control={control}
								options={BfaAdminUserStatuses}
								style={{ width: '100%' }}
							/>
						</Grid>
						<Grid column size={2} of={12}>
							<OutlineButton
								fullWidth
								onClick={handleReset}
								disabled={!isDirty}
								className={styles.buttons}
							>
								Clear All Filters
							</OutlineButton>
						</Grid>
						<Grid column size={2} of={12}>
							<PrimaryButton
								disabled={!(isValid && isDirty)}
								onClick={handleSubmit((filter = {}) =>
									handleFilterSubmit(filter)
								)}
								fullWidth
								className={styles.buttons}
							>
								Filter
							</PrimaryButton>
						</Grid>
					</Grid>
				</FilterInner>
			</Filter>
		</>
	);
};

export default UserHeader;
