import styles from './PrimaryScrollableList.module.css';
import cx from 'classnames';
import PrimaryButton from '../../Buttons/PrimaryButton';
import { Dropdown } from '@salesforce/design-system-react/module/components';
import React, { useState } from 'react';
import InputWithIcon from '../../Inputs/InputWithIcon/InputWithIcon';
import { SLDSDropdownOptionType } from 'types';
import { Icon } from '@salesforce/design-system-react/module/components';

type Props = {
	primaryButtonOnClick: any;
	primaryButtonTitle: string;
	listTitle: string;
	dropdownOptions: SLDSDropdownOptionType[];
	dropdownPlaceholder: string;
	dropdownOnSelect: any;
	dropdownOnClick: any;
	searchFieldOnChange: any;
	searchPlaceholder: string;
};

const PrimaryScrollableList: React.FC<Props> = ({
	primaryButtonOnClick,
	primaryButtonTitle,
	listTitle,
	dropdownOptions,
	dropdownOnClick,
	dropdownOnSelect,
	dropdownPlaceholder,
	searchFieldOnChange,
	searchPlaceholder,
	children,
}) => {
	const [dropdownValue, setDropdownValue] = useState(null);
	const searchRef: React.RefObject<HTMLInputElement> = React.createRef();

	const handleClearDropdown = () => {
		setDropdownValue(null);
		dropdownOnClick();
	};
	return (
		<div
			className={cx(
				'slds-col slds-small-size_5-of-12 slds-large-size_3-of-12',
				styles.listContainer
			)}
		>
			<div className="slds-grid slds-grid_align-spread">
				<div className={cx('slds-col slds-size_1-of-1', styles.listTitle)}>
					{listTitle}
				</div>
				<PrimaryButton
					className="slds-col slds-size_1-of-1"
					onClick={primaryButtonOnClick}
				>
					{primaryButtonTitle}
				</PrimaryButton>
			</div>
			<div className={styles.dropdownContainer}>
				<Dropdown
					label={dropdownValue || dropdownPlaceholder}
					iconCategory="utility"
					iconName={dropdownValue ? null : 'down'}
					iconPosition="right"
					onSelect={({ value }: any) => {
						setDropdownValue(value);
						dropdownOnSelect(value);
					}}
					options={dropdownOptions}
					buttonClassName={cx(
						'slds-grid slds-grid_align-spread',
						styles.dropdownButton,
						dropdownValue
							? styles.dropdownActiveState
							: styles.dropdownInitState,
						styles.dropdownActiveStateIcon
					)}
					triggerClassName={styles.dropdownTriggerButton}
					className={cx(styles.dropdownItem)}
				/>
				{dropdownValue && (
					<div className={styles.clearIcon} onClick={handleClearDropdown}>
						<Icon category="utility" name="clear" size="xx-small" />
					</div>
				)}
			</div>
			<div style={{ marginTop: '12px' }}>
				<InputWithIcon
					placeholder={searchPlaceholder}
					className={styles.searchBar}
					icons={[
						{
							path: 'utility/search',
							isLeft: true,
							className: styles.inputIcon,
						},
					]}
					ref={searchRef}
					onChange={(ev: any) => {
						const val = ev.target.value;
						if (val.length >= 3) {
							searchFieldOnChange(val);
						} else {
							if (val === '') {
								searchFieldOnChange('');
							}
						}
					}}
				/>
			</div>
			<div className={styles.scrollableContainer}>{children}</div>
		</div>
	);
};

export default PrimaryScrollableList;
