import {
	Button,
	Dropdown,
	DropdownTrigger,
	Pill,
} from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import { ReactNode, useRef, useState } from 'react';
import { useEffect, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import Label from '../Label/Label';
import styles from './DropdownCheckboxWithPill.module.css';
import _ from 'lodash';

export type SelectOption = {
	label: any;
	value: any;
	className?: any;
	disabled?: boolean;
};

type Tag = {
	value: any;
	index: number;
};

export type SelectFieldProps = {
	label?: string;
	placeholder?: string;
	onChange?: (value: any) => any;
	className?: string;
	value?: any;
	labelPrefix?: string;
	defaultValue?: any;
	error?: string;
	name?: string;
	options: SelectOption[];
	required?: boolean;
	isLoading?: boolean;
	displayErrorMessage?: boolean;
	disabled?: boolean;
	onOpen?: () => void;
	onBlur?: () => void;
	optional?: boolean;
	getOptionValue?: (option: any) => any;
	getComparator?: (option: SelectOption, value: any) => boolean;
	size?: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large';
	inputRef?: any;
	initialArray?: any[];
	referenceCheckedItems?: Array<any>;
	setReferenceCheckedItems?: (c: Array<any>) => void;
	hasBadgeCount?: boolean;
	isReturnAllOnChange?: boolean;
	tagClassName?: string;
	renderTag?: (tag: any, index: number) => ReactNode;
	hasError?: (tag: any, index: number) => boolean;
	errorClassName?: any;
	onTagClick?: (tag: any, index: number) => void;
	onDelete?: (index: number) => void;
	tags?: string[];
	checkedItems?: Array<any>;
};

const PlainSelectField: React.FC<SelectFieldProps> = ({
	renderTag,
	label,
	placeholder,
	onOpen,
	className = '',
	value,
	defaultValue,
	error,
	name,
	options,
	required = false,
	isLoading = false,
	disabled = false,
	size = 'medium',
	optional = false,
	displayErrorMessage = true,
	onBlur,
	inputRef = null,
	tagClassName = '',
	referenceCheckedItems,
	setReferenceCheckedItems,
	hasError = () => false,
	errorClassName = '',
	onTagClick = () => {},
	onDelete,
	tags,
	onChange,
	isReturnAllOnChange = false,
}) => {
	const placeText = placeholder !== undefined ? placeholder : label;
	const [currVal, setCurrVal] = useState(defaultValue);
	const [initialized, setInitialized] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	// const [selectedPills, setSelectedPills] = useState<any>(referenceCheckedItems || []);
	const [checkedItems, setCheckedItems] = useState<any>(referenceCheckedItems);
	const stateCheckedItems = setReferenceCheckedItems
		? referenceCheckedItems
		: checkedItems;
	const stateSetCheckItems = setReferenceCheckedItems
		? setReferenceCheckedItems
		: setCheckedItems;

	const pillRef = useRef<HTMLDivElement>(null);
	const [activeTag, setActiveTag] = useState<Tag | undefined>();
	useEffect(() => {
		setCurrVal(value || defaultValue);
		setInitialized(true);
	}, []);

	useEffect(() => {
		if (initialized && value) {
			setCurrVal(value);
		}
	}, [initialized, value]);

	const formattedOptions = useMemo(() => {
		return (
			!isLoading &&
			options.map((o) => ({
				className: cx(styles.selectItem, {
					[styles.selectItemActive]: value?.value === o.value,
				}),
				...o,
			}))
		);
	}, [isLoading, options, value]);

	const menuStyle = useMemo(
		() => ({ minWidth: dropdownRef.current?.clientWidth }),
		[dropdownRef.current?.clientWidth]
	);

	const handleTagBlur = (value: any, index: number) => {
		if (activeTag && activeTag.index === index) {
			setActiveTag(undefined);
		}
	};
	const pillHeight = useMemo(() => {
		return pillRef?.current?.clientHeight || 0;
	}, [pillRef.current?.clientHeight]);

	const handlePillClick = (event, tag, index) => {
		event.stopPropagation();
		onTagClick(tag, index);
		setActiveTag({ value: tag, index });
	};

	const handlePillBlur = (value, index) => {
		if (activeTag && activeTag.index === index) {
			setActiveTag(undefined);
		}
		handleTagBlur(value, index);
	};

	const handleTagDelete = (index: number) => {
		if (onDelete) {
			onDelete(index);
		}

		if (activeTag && activeTag.index === index) {
			setActiveTag(undefined);
		}

		setCheckedItems((prevSelectedPills) => {
			const updatedPills = [...prevSelectedPills];
			updatedPills.splice(index, 1);
			return updatedPills;
		});

		if (stateSetCheckItems) {
			const updatedCheckedItems = [...stateCheckedItems];
			updatedCheckedItems.splice(index, 1);
			stateSetCheckItems(updatedCheckedItems);
		}
	};

	const TagInput = ({
		tags,
		error,
		pillHeight,
		selectedPills,
		renderTag,
		tagClassName,
		hasError,
		errorClassName,
		activeTag,
		disabled,
		pillRef,
		onBlur,
	}) => {
		return (
			<div
				className={cx(styles.tagInput, {
					[styles.empty]: !tags || tags.length === 0,
					[styles.hasError]: !!error,
				})}
				style={{ maxHeight: pillHeight ? pillHeight * 3.5 : undefined }}
			>
				{selectedPills?.map(
					(tag, index) =>
						tag && (
							<div
								className={styles.tagContainer}
								key={index}
								tabIndex={0}
								ref={index === 0 ? pillRef : null}
								onClick={(event) => {
									handlePillClick(event, tag, index);
								}}
								onBlur={() => handlePillBlur(tag, index)}
							>
								{renderTag ? (
									renderTag(tag, index)
								) : (
									<Pill
										key={index}
										labels={{ label: tag }}
										className={cx(styles.tag, tagClassName, {
											[styles.error]: hasError(tag, index),
											[errorClassName]: hasError(tag, index),
											[styles.activeTag]:
												activeTag && activeTag.index === index,
											[styles.disabled]: disabled,
										})}
										onRemove={() => {
											onBlur?.();
											if (!disabled) {
												handleTagDelete(index);
											}
										}}
									/>
								)}
							</div>
						)
				)}
			</div>
		);
	};

	return (
		<div className={cx(styles.container, className)} ref={dropdownRef}>
			{label && (
				<Label required={required} optional={optional}>
					{label}
				</Label>
			)}

			<div tabIndex={0} ref={inputRef}>
				<Dropdown
					name={name}
					id={name}
					align="left"
					width={size}
					tabIndex={-1}
					iconCategory="utility"
					iconName={isLoading ? 'sync' : 'down'}
					iconPosition="right"
					className={styles.dropdownContainer}
					containerClassName={styles.dropdownContainer}
					menuStyle={menuStyle}
					menuPosition="overflowBoundaryElement"
					disabled={disabled || isLoading}
					onBlur={() => onBlur && onBlur()}
					defaultValue={defaultValue}
					onOpen={() => {
						onOpen && onOpen();
					}}
					triggerClassName={cx(styles.select, {
						[styles.error]: !!error,
						[styles.empty]: !value,
						[styles.loading]: isLoading,
					})}
					label={
						checkedItems && checkedItems.length > 0 ? (
							<TagInput
								tags={tags}
								error={error}
								pillHeight={pillHeight}
								selectedPills={checkedItems}
								renderTag={renderTag}
								tagClassName={tagClassName}
								hasError={hasError}
								errorClassName={errorClassName}
								activeTag={activeTag}
								disabled={disabled}
								pillRef={pillRef}
								onBlur={onBlur}
							/>
						) : (
							placeText
						)
					}
					onSelect={(option: SelectOption) => {
						onBlur?.();
						let filteredValues: any = option;

						if (stateCheckedItems && stateSetCheckItems) {
							const findExistingOption = stateCheckedItems.filter(
								(c) => c === option.value
							);
							filteredValues = findExistingOption.length
								? stateCheckedItems.filter((c) => c !== option.value)
								: [...stateCheckedItems, option.value];
							stateSetCheckItems(filteredValues);
							setCheckedItems(filteredValues);
						}
						// onChange && onChange(isReturnAllOnChange ? filteredValues : option);
					}}
					options={formattedOptions}
					checkmark
					multiple={true}
					value={stateCheckedItems}
					icons={[
						{
							path: 'utility/search',
							isLeft: true,
							className: styles.inputIcon,
						},
					]}
				>
					{/* {hasBadgeCount && referenceCheckedItems?.length && (
						<DropdownTrigger>
							<Button>
								<div
									className={styles.btn}
									style={{
										marginLeft: '5px',
										backgroundColor: '#0176c0',
										color: 'white',
										height: '20px',
									}}
								>
									{referenceCheckedItems?.length}
								</div>

							</Button>
						</DropdownTrigger>
					)} */}
				</Dropdown>
			</div>

			{error && displayErrorMessage && (
				<div
					className={cx({
						'slds-has-error': !!error,
					})}
				>
					<div className={cx(styles.helper, 'slds-form-element__help')}>
						{error}
					</div>
				</div>
			)}
		</div>
	);
};

const DropdownCheckboxWithPill: React.FC<
	SelectFieldProps & { control?: Control<any> }
> = ({ control, name, defaultValue, disabled, checkedItems, ...rest }) => {
	if (control && name) {
		const { onChange } = rest;
		return (
			<Controller
				control={control}
				name={name}
				render={({ field, fieldState: { error } }) => {
					const { getOptionValue = ({ value }) => value } = rest;
					return (
						<>
							<PlainSelectField
								{...rest}
								{...field}
								onChange={(v) => {
									field.onChange(getOptionValue(v));
									onChange && onChange(getOptionValue(v));
								}}
								defaultValue={defaultValue}
								onBlur={field.onBlur}
								error={error?.message}
								disabled={disabled}
								inputRef={field.ref}
								value={checkedItems}
							/>
						</>
					);
				}}
				defaultValue={defaultValue}
			/>
		);
	}
	return (
		<PlainSelectField
			name={name}
			defaultValue={defaultValue}
			disabled={disabled}
			{...rest}
		/>
	);
};

export default DropdownCheckboxWithPill;
