import styles from './WalletReplenishment.module.css';
import { FaCheck, FaTimes } from 'react-icons/fa';
import Button from 'components/Buttons/Button';
import emptyFolder from '../../../../assets/icons/ic-folder1.svg';
import imgIcon from '../../../../assets/icons/ic-img1.svg';
import pptIcon from '../../../../assets/icons/ic-ppt.svg';
import xlsxIcon from '../../../../assets/icons/ic-ppt1.svg';
import txtIcon from '../../../../assets/icons/ic-txt.svg';
import pdfIcon from '../../../../assets/icons/ic-pdf1.svg';
import { useToggle } from 'utils/hooks';
import FilePreviewModal from 'components/UploadAttachments/FilePreviewModal';
import { useState } from 'react';
import client from 'helpers/ApiClient';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { useSingleSelection } from 'utils/hooks';

const ExpandedRow = (props) => {
	const {
		data: { remarks, id, attachments },
		onApprove,
		onDecline,
		status,
		isAllowedApproval,
	} = props;
	const [previewFile, setPreviewFile] = useState({
		url: `https://dev.api.bip.bayad.com/v2/replenishment/${id}/attachment/view`,
	});
	const {
		value: isPreviewModalOpen,
		valueOn: openPreviewModal,
		valueOff: closePreviewModal,
	} = useToggle();
	const handleClosePreview = () => {
		closePreviewModal();
	};

	const approveAndDecline = () => {
		if (status === 'Declined' ? 'Declined' : 'Approved' && isAllowedApproval) {
			return null;
		} else if (!isAllowedApproval && status === 'Pending') {
			return (
				<>
					<div>
						<Button
							className={styles.decline}
							onClick={() => onDecline(props.data)}
						>
							<FaTimes color="#db312c" size="1.2em" />
							Decline
						</Button>
					</div>
					<div>
						<Button
							className={styles.approve}
							onClick={() => onApprove(props.data)}
						>
							<FaCheck color="#6fcf6c" size="1.1em" />
							Approve
						</Button>
					</div>
				</>
			);
		}
	};
	const attachmentDatas: Array<string> = JSON.parse(attachments);
	const attachmentsChecker = () => {
		return (
			<div>
				{attachmentDatas?.map((fileName: string) => (
					<>
						<Button
							key={fileName}
							id="loader"
							onClick={() => {
								handleOpenFile(fileName);
							}}
							type="button"
							className={styles.attachmentButton}
						>
							{imageParser(fileName)}
							{fileName.slice(37)}
						</Button>
					</>
				))}
			</div>
		);
	};
	const handleOpenFile = async (fileKey: string) => {
		const url = await client
			.get(`/v2/replenishment/${id}/attachment/view`, {
				params: { key: fileKey },
			})
			.then(
				({
					data: {
						data: { download_url },
					},
				}) => {
					return download_url;
				}
			)
			.catch(() => {
				return { error: true };
			});

		if (!url) return;
		if (
			[
				'.pdf',
				'.png',
				'.jpg',
				'.jpeg',
				'.doc',
				'.docx',
				'.xlsx',
				'.pptx',
				'.txt',
			].some((type) => fileKey.includes(type))
		) {
			setPreviewFile({
				url,
				fileKey,
				fileName: fileKey,
			});
			openPreviewModal();
		}
	};
	const imageParser = (attachmentDatas) => {
		if (attachmentDatas && attachmentDatas.includes('doc')) {
			return <img src={txtIcon} alt="Image" className={styles.txtIcon} />;
		} else if (attachmentDatas && attachmentDatas.includes('pdf')) {
			return <img src={pdfIcon} alt="Image" className={styles.pdfIcon} />;
		} else if (attachmentDatas && attachmentDatas.includes('docx')) {
			return <img src={txtIcon} alt="Image" className={styles.txtIcon} />;
		} else if (attachmentDatas && attachmentDatas.includes('jpg')) {
			return <img src={imgIcon} alt="Image" className={styles.jpgIcon} />;
		} else if (attachmentDatas && attachmentDatas.includes('jpeg')) {
			return <img src={imgIcon} alt="Image" className={styles.jpegIcon} />;
		} else if (attachmentDatas && attachmentDatas.includes('png')) {
			return <img src={imgIcon} alt="Image" className={styles.pngIcon} />;
		} else if (attachmentDatas && attachmentDatas.includes('pptx')) {
			return <img src={pptIcon} alt="Image" className={styles.pptxIcon} />;
		} else if (attachmentDatas && attachmentDatas.includes('txt')) {
			return <img src={txtIcon} alt="Image" className={styles.txtIcon} />;
		} else if (attachmentDatas && attachmentDatas.includes('xlsx')) {
			return <img src={xlsxIcon} alt="Image" className={styles.txtIcon} />;
		}
	};
	const noAttachment = () => {
		if (attachmentDatas?.length === 0) {
			return (
				<>
					<div>
						<img
							src={emptyFolder}
							alt="Empty"
							className={styles.emptyattachment}
						/>
						<br />
					</div>
					<br />
					<div className={styles.noattachment}>No document attached.</div>
					<br />
				</>
			);
		}
	};
	const attachmentCounter = () => {
		if (
			attachmentDatas?.length === 0 ||
			attachmentDatas === null ||
			attachmentDatas?.length === 1
		) {
			return <div>There is {attachmentDatas?.length} document attached.</div>;
		} else if (attachmentDatas?.length > 1) {
			return <div>There are {attachmentDatas?.length} documents attached.</div>;
		}
	};

	return (
		<div className={styles.expanded}>
			<div className={styles.actionbutton}>{approveAndDecline()}</div>
			<br />
			<b>Remarks</b>
			<br />
			<br />
			{remarks}
			<br />
			<br />
			<b>Attachments:</b>
			<br />
			<div className={styles.attachmentCount}>
				{attachmentCounter(attachmentDatas)}
			</div>
			<br />
			<br />
			<div className={styles.attachment}>
				{attachmentsChecker()}
				<FilePreviewModal
					open={isPreviewModalOpen}
					onClose={handleClosePreview}
					file={previewFile}
					downloadUrl={`/v2/replenishment/${id}/attachment/download`}
					handleRetry={() => handleOpenFile(previewFile?.fileKey)}
					isTrimFileName="Replenishment"
				/>
			</div>
			{noAttachment()}
		</div>
	);
};

export default ExpandedRow;
