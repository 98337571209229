import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
	Modal,
	IconSettings,
	Button,
	Textarea,
	DataTable,
	DataTableColumn,
	DataTableCell,
} from '@salesforce/design-system-react/module/components';
import { closeModal } from '../../redux/modules/viewtransaction';
import InputWithIcon from '../Inputs/InputWithIcon/InputWithIcon';
import DownloadButton from '../Buttons/DownloadButton/DownloadButton';
import cx from 'classnames';
import styles from './ViewTransactionDetails.module.css';
import PrimaryButton from '../Buttons/PrimaryButton';

import {
	viewVoidActionCreator as handleVoidTransaction,
	openLoader,
} from '../../redux/modules/voidTransaction';

const columns = {
	api: [
		<DataTableColumn
			label="Transaction No."
			key="transaction_id"
			property="transaction_id"
		/>,
		<DataTableColumn label="Biller No." key="biller_id" property="biller_id" />,
		<DataTableColumn label="Status" key="status" property="status" />,
		<DataTableColumn label="Data" key="data" property="data" />,
		<DataTableColumn
			label="Created At"
			key="created_at"
			property="created_at"
		/>,
		<DataTableColumn
			label="Updated At"
			key="updated_at"
			property="updated_at"
		/>,
	],
	// [u] Waiting for BE update -> update property
	audit: [
		<DataTableColumn
			label="Date and Time"
			key="datetime"
			property="datetime"
		/>,
		<DataTableColumn label="Logged by" key="logged_by" property="logged_by" />,
		<DataTableColumn label="Action" key="action" property="action" />,
		<DataTableColumn
			label="Old Values"
			key="old_values"
			property="old_values"
		/>,
		<DataTableColumn
			label="New Values"
			key="new_values"
			property="new_values"
		/>,
		<DataTableColumn label="Source IP" key="source_ip" property="source_ip" />,
		<DataTableColumn label="Remarks" key="remarks" property="remarks" />,
	],
};

type Props = {
	closeModal: () => void;
	showModal: () => void;
	details: any;
	logs: any;
	openLoader: () => void;
	handleVoidTransaction: (details) => void;
};

type TabValue = {
	value: string | number;
};

type TabsProps = {
	label: string;
} & TabValue;

const getBadgeClass = (status = '') => {
	if (status) {
		switch (status.toLowerCase()) {
			case 'pending':
				return `slds-theme_warning ${styles.pill_pending}`;
			case 'rejected':
				return `slds-theme_error ${styles.pill_rejected}`;
			case 'approved':
				return `slds-theme_success ${styles.pill_approved}`;
			case 'expired':
				return `slds-badge_inverse ${styles.pill_expired}`;

			default:
				return `slds-badge_inverse ${styles.pill_expired}`;
		}
	}

	return 'slds-badge_inverse';
};

const ViewTransactionDetails: React.FC<Props> = ({
	closeModal,
	showModal,
	details,
	logs,
	openLoader,
	handleVoidTransaction,
}) => {
	const tabs: TabsProps[] = [
		{
			label: 'API Logs',
			value: '1',
		},
		{
			label: 'Audit Logs',
			value: '2',
		},
	];

	const verifyVoidRequestStatus = (status) => {
		if (status && status?.toLowerCase() === 'reject') {
			return 'Rejected';
		} else {
			const statusLowerCase = status?.toLowerCase();
			if (statusLowerCase) {
				return (
					statusLowerCase?.charAt(0)?.toUpperCase() + statusLowerCase?.slice(1)
				);
			} else {
				return null;
			}
		}
	};

	return (
		<Modal isOpen={showModal} onRequestClose={() => closeModal()} size="small">
			<div className={cx('slds-card', styles.container)}>
				<div className="slds-grid slds-grid_align-end slds-m-bottom_x-small slds-m-top_x-small">
					<div className="slds-col slds-size_9-of-12">
						<h1>View Transaction Details</h1>
					</div>
					<div className="slds-col slds-size_3-of-12 ">
						<DownloadButton className="slds-float_right" />
					</div>
				</div>
				<br></br>
				{details?.void_request_id && (
					<div className={styles.vrText}>
						<div className="slds-grid slds-grid_align-end slds-m-bottom_x-small">
							<div className="slds-col slds-size_2-of-12">
								<div className="slds-text-body_regular slds-m-top_xx-small">
									Void Request ID:
								</div>
							</div>
							<div className="slds-col slds-size_3-of-12">
								<div
									className={`slds-text-body_regular slds-float_right ${styles.vrid}`}
								>
									<span
										onClick={() => {
											openLoader();
											handleVoidTransaction(details);
										}}
									>
										{details?.void_ref_number}
									</span>
								</div>
							</div>
						</div>

						<div className="slds-grid slds-grid_align-end">
							<div className="slds-col slds-size_3-of-12">
								<div className="slds-text-body_regular slds-m-top_xx-small">
									Void Request Status:{' '}
								</div>
							</div>
							<div className="slds-col slds-size_2-of-12">
								<div className="slds-text-body_regular">
									<span
										className={`
											slds-badge
											slds-float_right
											${styles.statusText}
											${getBadgeClass(verifyVoidRequestStatus(details?.void_request_status))}
										`}
									>
										{verifyVoidRequestStatus(details?.void_request_status)}
									</span>
								</div>
							</div>
						</div>
						<br></br>
					</div>
				)}
				<div className="slds-grid slds-gutters slds-m-bottom_small">
					<div className="slds-col slds-size_1-of-2">
						<InputWithIcon
							label="Transaction Date and Time"
							id="created_at"
							value={details && details.created_at}
							disabled
						/>
					</div>

					<div className="slds-col slds-size_1-of-2">
						<InputWithIcon
							id="tpaid"
							label="TPAID"
							value={details && details.tpaid}
							disabled
						/>
					</div>
				</div>

				<div className="slds-grid slds-gutters slds-m-bottom_small">
					<div className="slds-col slds-size_1-of-2">
						<InputWithIcon
							id="trn"
							label="Transaction Reference No."
							value={details && details.trn}
							disabled
						/>
					</div>

					<div className="slds-col slds-size_1-of-2">
						<InputWithIcon
							id="crn"
							label="Client Reference No."
							value={details && details.crn}
							disabled
						/>
					</div>
				</div>

				<div className="slds-grid slds-gutters slds-m-bottom_small">
					<div className="slds-col slds-size_1-of-2">
						<InputWithIcon
							id="channel_name"
							label="Channel Name"
							value={details && details.channel_name}
							disabled
						/>
					</div>

					<div className="slds-col slds-size_1-of-2">
						<InputWithIcon
							id="biller_name"
							label="Biller Name"
							value={details && details.biller_name}
							disabled
						/>
					</div>
				</div>

				<div className="slds-grid slds-gutters slds-m-bottom_small">
					<div className="slds-col slds-size_1-of-2">
						<InputWithIcon
							id="arn"
							label="Account No."
							value={details && details.arn}
							disabled
						/>
					</div>

					<div className="slds-col slds-size_1-of-2">
						<InputWithIcon
							id="amount"
							label="Amount"
							value={details && details.amount}
							disabled
						/>
					</div>
				</div>

				<div className="slds-grid slds-gutters slds-m-bottom_small">
					<div className="slds-col slds-size_1-of-2">
						<InputWithIcon
							id="status"
							label="Status"
							value={details && details.status}
							disabled
						/>
					</div>
				</div>

				<label className="slds-form-element__label" htmlFor="error_details">
					Error Details
				</label>
				<Textarea
					id="error_details"
					classNameContainer={styles.textArea}
					disabled
				/>
			</div>
			<div className={cx('slds-card', styles.container)}>
				<Tabs tabs={tabs}>
					<TabPanel value="1">
						<DataTable items={logs.api} className={styles.datatable} noRowHover>
							{columns.api}
						</DataTable>
					</TabPanel>
					<TabPanel value="2">
						<DataTable
							items={logs.audit}
							className={styles.datatable}
							noRowHover
						>
							{columns.audit}
						</DataTable>
					</TabPanel>
				</Tabs>
			</div>
		</Modal>
	);
};

const TabPanel: React.FC<TabValue> = ({ children }) => {
	return <>{children}</>;
};

type Tabs = {
	tabs: TabsProps[];
};

const Tabs: React.FC<Tabs> = ({ tabs, children }) => {
	const [activeTab, setActiveTab] = useState(tabs[0].value);

	const changeActiveTab = (tab: TabsProps['value']) => {
		setActiveTab(tab);
	};

	const tabClass = (tab: TabsProps['value']) => {
		return tab === activeTab ? cx(styles.cTab, styles.active) : styles.cTab;
	};

	const displayPanel = () => {
		const activePanel = Array.isArray(children)
			? children.find((element: any) => element.props.value === activeTab)
			: children;

		return activePanel;
	};

	return (
		<>
			<div className={styles.tabContainer}>
				{tabs.map((e: TabsProps) => (
					<div
						key={e.value}
						className={tabClass(e.value)}
						onClick={() => changeActiveTab(e.value)}
					>
						<span>{e.label}</span>
					</div>
				))}
			</div>
			<div style={{ overflow: 'auto' }}>{displayPanel()}</div>
		</>
	);
};

export default connect(
	(state: any) => ({
		showModal: state.viewtransaction.showModal,
		details: state.viewtransaction.data.details,
		voidDetails: state.viewtransaction.data.voidDetails,
		logs: state.viewtransaction.data.logs,
	}),
	{
		closeModal,
		openLoader,
		handleVoidTransaction,
	}
)(ViewTransactionDetails);
