import { Button } from '@salesforce/design-system-react/module/components';
import React, {
	ReactNode,
	useCallback,
	useRef,
	useState,
	useMemo,
} from 'react';
import { restrictSpecialChars } from 'utils/common';
import partnerDetailsStyles from './PartnerDetails.module.css';
import successModalStyles from '../Modal/SuccessModal.module.css';
import errorModalStyles from '../Modal/ErrorModal.module.css';
import client from '../../helpers/ApiClient';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import PanelPlaceholder from '../PartnerList/PartnerTabPanelPlaceholder';
import { useToggle } from '../../utils/hooks';
import PartnerForm from 'components/PartnerForm/PartnerForm';
import { usePartnerQuery } from 'utils/queries/partner';
import { ReactComponent as EditIcon } from 'assets/icons/ic-edit.svg';
import { TabStatus } from '../PartnerForm/PartnerForm';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import {
	setCurrentBillerTab,
	setEditing,
	updateProductStatus,
	updateValidForm,
} from 'redux/modules/products';
import { updateIsDisabled } from 'redux/modules/form';
import { isEmpty } from 'lodash';
import { default_value } from 'containers/ProductManagement/PartnerProfile/PartnerProfile';
import { ReducerStateType } from 'redux/modules/reducers';
import PartnerStatusSelect from 'components/PartnerStatusSelect/PartnerStatusSelect';
import { findPartnerTab, partnerModalTabs } from 'constants/partner_modal_tabs';
import { mapServiceFeeSettings } from 'components/PartnerForm/Tabs/ServiceFee/ServiceFeeSettings';
import { mapPrimaryInfo } from 'components/PartnerModal/PartnerModal';
import { replaceDeepKeys, formatObjectKeys } from 'utils/common';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { DEFAULT_SENDER_EMAIL } from 'components/PartnerForm/Tabs/Reports';
import { decrypt } from 'utils/codeGenerator';
import moment from 'moment';
import { FixMeLater } from 'types';
import { showAccessDeniedModal } from 'redux/modules/access';
import { useHasUserPermission } from 'utils/permissions';
import ApiBatchResponseModal from 'components/Modal/ApiBatchResponseModal';
import { convertDate } from 'utils/date';
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters,
} from 'react-query';

type Props = {
	selectedBiller: any;
	disabled?: boolean;
	onClose?: () => void;
	products: any[];
	setEditing: (v?: boolean) => void;
	updateProductStatus: (value: { id: number; status: string }) => void;
	isFormValid?: boolean;
	isFormEdited?: boolean;
	isDisabled?: boolean;
	isTabStateLoading?: boolean;
};

export const ExcludeAutoSave: string[] = ['DRAFT', 'EDIT'];

const styles = {
	...partnerDetailsStyles,
	...successModalStyles,
	...errorModalStyles,
};

export const mapViewPartner = (
	data: FixMeLater,
	isRootMap?: boolean
): FixMeLater => {
	// Primary Information
	const {
		code,
		product_code,
		product_code_type,
		logo_url,
		product_primary_info,
		product_addresses = [],
		product_affiliates = [],
		product_contact_details = [],
		product_officer,
	} = isRootMap ? data : data?.primaryInfo;

	// Contract Details
	const {
		product_contract,
		product_cash_collection,
		product_terms_duration,
		product_check_collection,
		product_dpo_detail,
		product_dsa_bond,
		product_accreditation: {
			id = '',
			product_id = '',
			created_at = '',
			updated_at = '',
			...accRest
		} = {},
		product_other_penalties = [],
	} = isRootMap ? data : data.contractDetails;

	// formatter for time with seconds
	const convertToHourMinutes = (value: string) => {
		return moment(value, 'HH:mm:ss', true).isValid()
			? moment(value, 'HH:mm:ss', true).format('HH:mm')
			: moment(value, 'HH:mm', true).isValid()
			? value
			: undefined;
	};

	if (product_contract && product_contract.upload_time) {
		product_contract.upload_time = convertToHourMinutes(
			product_contract.upload_time
		);
	}

	// Business Rules
	const { product_business_rule } = isRootMap ? data : data.businessRules;

	// Service Fee Settings
	const { product_service_fee, product_service_fee_tiers } = isRootMap
		? data
		: data.serviceFee;

	const tiersId = product_service_fee_tiers
		? product_service_fee_tiers.map((c) => c.Id || c.id)
		: [];

	// Reports
	const reportsData: Array<any> = isRootMap
		? data?.reports
		: data?.reports?.reports;

	const reports = reportsData
		? reportsData.map((r) => {
				r.file_name_format = r.filename_format;
				return r;
		  })
		: [];

	const fetchReport = (type: string) =>
		reports.find((r) => r.transmission_type === type) ?? {};

	const formatTimestamps = (type: string) => {
		const reportEvents = fetchReport(type)?.report_events ?? [];
		return reportEvents
			?.map((r, i: number) => {
				const fetchEventTime = (type: string) =>
					reportEvents?.find(
						(e) =>
							e?.covered_schedule === r?.covered_schedule &&
							e?.covered_time_from === r?.covered_time_from &&
							e?.covered_time_to === r?.covered_time_to &&
							e?.event_type === type &&
							e?.pair_id === r?.pair_id
					);
				const returnValues = {
					pairId: fetchEventTime('GENERATE' && 'UPLOAD')?.pair_id || null,
					generateId: fetchEventTime('GENERATE')?.id || null,
					uploadId: fetchEventTime('UPLOAD')?.id || null,
					generationTime: fetchEventTime('GENERATE')?.event_time,
					timeOfUpload: fetchEventTime('UPLOAD')?.event_time,
					coveredSchedule: r?.covered_schedule,
					coveredTime: {
						from: r?.covered_time_from,
						to: r?.covered_time_to,
					},
				};

				return i % 2 === 0 ? returnValues : null;
			})
			.filter((r) => !!r);
	};

	const formatCsrReports = (type: string) =>
		fetchReport(type)?.report_columns?.map((c) => ({
			id: c.report_field_id,
			ordinal: c.id,
		})) ?? [];

	const smtp =
		decrypt(
			fetchReport('SMTP')?.config, //value
			process.env.REACT_APP_APP_KEY, //decrypt keu
			true, //replaceKey
			true //convertToObject
		) || {};

	// Return Mapped Values
	const renewalFrequencyTypeObj: Record<string, string> = {};

	if (product_terms_duration?.renewal_frequency) {
		const renewalFreq = product_terms_duration?.renewal_frequency;
		if (renewalFreq > 1) {
			renewalFrequencyTypeObj.renewal_frequency_type = 'EVERY_N_YEARS';
			renewalFrequencyTypeObj.renewal_frequency = renewalFreq;
		} else if (renewalFreq === 1) {
			renewalFrequencyTypeObj.renewal_frequency_type = 'ANNUALLY';
		}
	}

	return isEmpty(data)
		? default_value
		: structuredClone({
				primary_info: {
					code, // merchant id
					product_code_type: product_code_type || 'AUTO',
					product_code: product_code,
					logo_url,
					logo: {
						objectKey: null,
					},
					product_primary_info,
					product_affiliates: product_affiliates?.map((c) => {
						c.Id === c?.id;
						return c;
					}),
					product_addresses,
					product_contact_details: product_contact_details.map((c) => {
						c.area_handled = c?.city_id;
						return c;
					}),
					product_officer,
				},
				product_contract: {
					product_contract: {
						...product_contract,
						other_penalties: product_other_penalties?.map((c) => {
							c.Id = c?.id;
							return c;
						}),
					},
					product_cash_collection,
					product_terms_duration: {
						...product_terms_duration,
						...renewalFrequencyTypeObj,
					},
					product_check_collection,
					product_dpo_detail: {
						location_base: product_dpo_detail?.country_id
							? product_dpo_detail?.country_id == 175
								? 'LOCAL'
								: 'INTERNATIONAL'
							: 'LOCAL',
						...product_dpo_detail,
					},
					product_dsa_bond,
					product_accreditation: { ...accRest, product_id, id },
				},
				service_fee_settings: {
					...product_service_fee,
					frequency_type: product_service_fee?.frequency_type,
					frequency_month: product_service_fee?.frequency_month,
					with_non_shareable_amount: product_service_fee?.with_non_shareable,
					billing_type: (() => {
						if (product_service_fee?.billing_type === 'BOTH')
							return { SUBSIDIZED: true, PASS_ON: true, id: tiersId };
						else
							return {
								SUBSIDIZED: product_service_fee?.billing_type === 'SUBSIDIZED',
								PASS_ON: product_service_fee?.billing_type === 'PASS_ON',
							};
					})(),
					regular:
						product_service_fee?.scheme_type === 'REGULAR' &&
						product_service_fee_tiers[0],

					percentage:
						product_service_fee?.scheme_type === 'PERCENTAGE' &&
						product_service_fee_tiers[0],
					tiers:
						product_service_fee?.scheme_type === 'TIER' &&
						product_service_fee_tiers,

					special:
						product_service_fee?.scheme_type === 'SPECIAL' &&
						product_service_fee_tiers,

					tier_count:
						product_service_fee?.scheme_type === 'TIER' &&
						product_service_fee_tiers?.length,
					id: tiersId,
				},
				business_rules: {
					product_business_rule: {
						...product_business_rule,
						accepted_check_types: product_business_rule?.accepted_check_types
							? product_business_rule?.accepted_check_types.split(',')
							: [],
						payment_mode: product_business_rule?.payment_mode
							? product_business_rule?.payment_mode.split(',')
							: [],
						payment_accepted: product_business_rule?.payment_accepted
							? product_business_rule?.payment_accepted.split(',')
							: [],
						required_documents: product_business_rule?.required_documents
							? product_business_rule?.required_documents.split(',')
							: [],
						product_id,
					},
				},
				reports: {
					sftp: {
						csrConfiguration: {
							...formatObjectKeys(fetchReport('SFTP'), 'CAMEL'),
							timestamps: formatTimestamps('SFTP'),
							columns: formatCsrReports('SFTP'),
						},
						uploadConfiguration: {
							...formatObjectKeys(fetchReport('SFTP_UC'), 'CAMEL'),
							timestamps: formatTimestamps('SFTP_UC'),
							columns: formatCsrReports('SFTP_UC'),
						},
						baseInfo: {
							...decrypt(
								fetchReport('SFTP')?.config,
								process.env.REACT_APP_APP_KEY,
								true,
								true
							),
						},
					},
					smtp: {
						csrConfiguration: {
							...formatObjectKeys(fetchReport('SMTP'), 'CAMEL'),
							timestamps: formatTimestamps('SMTP'),
							columns: formatCsrReports('SMTP'),
						},
						uploadConfiguration: {
							...formatObjectKeys(fetchReport('SMTP_UC'), 'CAMEL'),
							timestamps: formatTimestamps('SMTP_UC'),
							columns: formatCsrReports('SMTP_UC'),
						},

						senderEmail: smtp?.sender || DEFAULT_SENDER_EMAIL,
						receiverEmail: smtp?.receivers?.split(','),
					},
				},
		  });
};

const PartnerDetails: React.FC<Props> = ({
	selectedBiller: selectedBillerPerCategory,
	products,
	onClose,
	setEditing,
	updateProductStatus,
	isFormValid,
	isFormEdited,
	isDisabled,
	isTabStateLoading = false,
}) => {
	const [loaderMessage, setLoaderMessage] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	const [errorInstruction, setErrorInstruction] = useState<ReactNode>('');
	const [errorStatusRemarks, setErrorStatusRemarks] = useState({
		hasError: false,
		status: '',
	});
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [confirmationHeader, setConfirmationHeader] = useState<
		ReactNode | string
	>('');
	const [confirmationMessage, setConfirmationMessage] = useState<ReactNode>('');
	const [onConfirmationClose, setOnConfirmationClose] = useState({
		action: () => {},
	});
	const [confirmBtnOnClick, setConfirmBtnOnClick] = useState({
		action: () => {},
	});
	const [isRemarksEmpty, setIsRemarksEmpty] = useState<boolean>(true);
	const handleChangeOfRemarks = (event) =>
		setIsRemarksEmpty(
			event.target.value.match(/^\s+$/) || event.target.value === ''
		);
	const [apiResponses, setApiResponses] = useState<any>([]);
	// eslint-disable-next-line prefer-const
	let statusRemarksField = React.createRef<HTMLTextAreaElement>();

	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);

	const hasUserPermission = useHasUserPermission('products');

	const selectedBiller = useMemo(
		() => selectedBillerPerCategory[productCategory],
		[productCategory, selectedBillerPerCategory]
	);

	const {
		value: isLoading,
		valueOn: showLoader,
		valueOff: hideLoader,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();

	const {
		value: isEditSuccessModalOpen,
		valueOn: showEditSuccessModal,
		valueOff: hideEditSuccessModal,
	} = useToggle();

	// permission handling for editing partner details
	const hasEditPermission = useCallback(
		(scope: string) => {
			return hasUserPermission(productCategory, `edit.${scope}`);
		},
		[hasUserPermission, productCategory]
	);

	const hasViewPermission = useCallback(
		(scope: string) => hasUserPermission(productCategory, `view.${scope}`),
		[hasUserPermission, productCategory]
	);

	const { hasEditStatusPermission, hasPartnerListPermission } = useMemo(() => {
		return {
			hasEditStatusPermission: hasUserPermission(
				productCategory,
				'edit.status'
			),
			hasPartnerListPermission: hasUserPermission(productCategory, 'list'),
		};
	}, [hasUserPermission, productCategory]);

	const productType: any = useSelector<ReducerStateType>(
		(state) => state.sidebar.itemId
	);

	const productId = selectedBiller ? selectedBiller.id : null;

	const currentBillerTab = useSelector<ReducerStateType, string>(
		(state) => state.products.currentBillerTab
	);

	const currentFormData: any = useSelector<ReducerStateType>((state) => {
		return {
			productType: products.find((p) => p.id == productType)?.code,
			code: state.products.primaryInfo.code,
			primaryInfo: mapPrimaryInfo(state.products.primaryInfo),
			contractDetails: replaceDeepKeys(
				state.products.contractDetails,
				/product_|_obj/g,
				'',
				'CAMEL'
			),
			serviceFeeSettings: formatObjectKeys(
				mapServiceFeeSettings(state.products.serviceFeeSettings),
				'CAMEL'
			),
			businessRules: formatObjectKeys(state.products.businessRules, 'CAMEL'),
			reports: formatObjectKeys(state.products.reports, 'CAMEL'),
		};
	});

	const dispatch = useDispatch();

	const initTabStatus = {
		primaryInformation: { disabled: false, finished: false },
		contractDetails: { disabled: false, finished: false },
		serviceFeeSettings: { disabled: false, finished: false },
		businessRules: { disabled: false, finished: false },
		reports: { disabled: false, finished: false },
	};

	const [tabStatus, setTabStatus] = useState<TabStatus | undefined>(
		initTabStatus
	);

	const {
		data: primaryInfo = {},
		isLoading: isLoadingPrimary,
		refetch: fetchPrimary,
		isError: isErrorPrimary,
		error: errorPrimary,
		isFetching: isFetchingPrimary,
	} = usePartnerQuery(
		'primaryInfo',
		{
			productType: productType,
			productId: productId,
		},
		{
			enabled: !!selectedBiller && hasViewPermission('primary-info'),
			cacheTime: 0,
			refetchOnReconnect: false,
		}
	);

	const {
		data: contractDetails = {},
		isLoading: isLoadingContract,
		refetch: fetchContract,
		isError: isErrorContract,
		error: errorContract,
		isFetching: isFetchingContract,
	} = usePartnerQuery(
		'contractDetails',
		{
			productType: productType,
			productId: productId,
		},
		{
			enabled: !!selectedBiller && hasViewPermission('contract-details'),
			refetchOnReconnect: false,
		}
	);

	const {
		data: businessRules = {},
		isLoading: isLoadingBusiness,
		refetch: fetchBusiness,
		isError: isErrorBusiness,
		error: errorBusiness,
		isFetching: isFetchingBusiness,
	} = usePartnerQuery(
		'businessRules',
		{
			productType: productType,
			productId: productId,
		},
		{
			enabled: !!selectedBiller && hasViewPermission('business-rules'),
			refetchOnReconnect: false,
		}
	);
	const {
		data: serviceFee = {},
		isLoading: isLoadingService,
		refetch: fetchService,
		isError: isErrorService,
		error: errorService,
		isFetching: isFetchingService,
	} = usePartnerQuery(
		'serviceFeeSettings',
		{
			productType: productType,
			productId: productId,
		},
		{
			enabled: !!selectedBiller && hasViewPermission('service-fee'),
			refetchOnReconnect: false,
		}
	);

	const {
		data: reports = {},
		isLoading: isLoadingReports,
		refetch: fetchReports,
		isError: isErrorReports,
		error: errorReports,
		isFetching: isFetchingReports,
	} = usePartnerQuery(
		'reports',
		{
			productType: productType,
			productId: productId,
		},
		{
			enabled: !!selectedBiller && hasViewPermission('reports'),
			refetchOnReconnect: false,
		}
	);
	const isPartnerDetailsLoading: boolean =
		isLoadingPrimary ||
		isLoadingContract ||
		isLoadingBusiness ||
		isLoadingService ||
		isLoadingReports;

	const isPartnerDetailsFetching: boolean =
		isFetchingPrimary ||
		isFetchingContract ||
		isFetchingBusiness ||
		isFetchingService ||
		isFetchingReports;

	const formattedValues = mapViewPartner({
		primaryInfo,
		contractDetails,
		businessRules,
		serviceFee,
		reports,
	});

	useEffect(() => {
		const refetch: Array<
			(
				options?: (RefetchOptions & RefetchQueryFilters) | undefined
			) => Promise<QueryObserverResult<string, Error>>
		> = [];

		const networkError = 'Network Error';
		if (isErrorPrimary && errorPrimary?.message === networkError) {
			refetch.push(fetchPrimary);
		}
		if (isErrorContract && errorContract?.message === networkError) {
			refetch.push(fetchContract);
		}
		if (isErrorBusiness && errorBusiness?.message === networkError) {
			refetch.push(fetchBusiness);
		}
		if (isErrorService && errorService?.message === networkError) {
			refetch.push(fetchService);
		}

		if (isErrorReports && errorReports?.message === networkError) {
			refetch.push(fetchReports);
		}

		if (!isPartnerDetailsFetching && refetch.length > 0) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred with loading the data.',
				'Please try again',
				async () => {
					await Promise.all(refetch.map((v) => v()));
				}
			);
		}
	}, [
		isErrorPrimary,
		errorPrimary?.message,
		isErrorContract,
		errorContract?.message,
		isErrorBusiness,
		errorBusiness?.message,
		isErrorService,
		errorService?.message,
		isErrorReports,
		errorReports?.message,
		isPartnerDetailsFetching,
	]);

	const refetchPartnerDetails = async (savedPartnerDetails) => {
		for (const s of savedPartnerDetails) {
			if (s.status === 'Success') {
				const { label, formTab } = s;
				switch (label) {
					case 'Primary Information':
						await fetchPrimary();
						dispatch(
							updateValidForm({ formTab, isDirty: false, isRefetched: true })
						);
						break;
					case 'Contract Details':
						await fetchContract();
						dispatch(
							updateValidForm({ formTab, isDirty: false, isRefetched: true })
						);
						break;
					case 'Service Fee Settings':
						await fetchService();
						dispatch(
							updateValidForm({ formTab, isDirty: false, isRefetched: true })
						);
						break;
					case 'Business Rules':
						await fetchBusiness();
						dispatch(
							updateValidForm({ formTab, isDirty: false, isRefetched: true })
						);
						break;
					case 'Reports':
						await fetchReports();
						dispatch(
							updateValidForm({ formTab, isDirty: false, isRefetched: true })
						);
						break;
				}
			}
		}
	};

	useEffect(() => {
		if (!isPartnerDetailsLoading) {
			setTabStatus(initTabStatus);
			dispatch(setCurrentBillerTab(partnerModalTabs[0].name));
		}
	}, [isPartnerDetailsLoading]);

	const showErrorMessage = (
		header: string | React.ReactNode,
		message: string | React.ReactNode,
		instruction?: string | React.ReactNode,
		onRetryBtnClick?: () => void
	) => {
		setErrorHeader(header);
		setErrorMessage(message);
		setErrorInstruction(instruction || 'Please try again.');
		showErrorModal();
		setRetryBtnOnClick({
			action: () => {
				hideErrorModal();
				onRetryBtnClick && onRetryBtnClick();
			},
		});
	};

	const showSuccessMessage = (
		message: string | React.ReactNode,
		onDoneBtnClick?: () => void
	) => {
		setSuccessMessage(message);
		showSuccessModal();
		setDoneBtnOnClick({
			action: () => {
				hideSuccessModal();
				onDoneBtnClick && onDoneBtnClick();
			},
		});
	};

	const showConfirmationMessage = (
		header: string | React.ReactNode,
		message: string | React.ReactNode,
		onConfirmBtnClick?: () => void,
		onClose?: () => void
	) => {
		setConfirmationHeader(header);
		setConfirmationMessage(message);
		showConfirmationModal();
		setConfirmBtnOnClick({
			action: () => {
				onConfirmBtnClick && onConfirmBtnClick();
			},
		});
		setOnConfirmationClose({
			action: () => {
				hideConfirmationModal();
				onClose && onClose();
			},
		});
	};

	const save = async () => {
		setLoaderMessage('Please wait while changes are being saved.');
		showLoader();
		const method = 'put';
		const url = `/v2/products/${productType}/${productId}`;
		const form = currentFormData;

		const {
			primaryInfo,
			contractDetails,
			serviceFeeSettings,
			businessRules,
			reports,
		} = form;

		// update only details if has edit permission
		// will also consider view permission
		const details: {
			scope: string;

			data: Record<string, any>;
		}[] = [
			{
				scope: 'primary-info',
				data: { primaryInfo },
			},
			{
				scope: 'contract-details',
				data: { contractDetails },
			},
			{
				scope: 'service-fee',
				data: { serviceFeeSettings },
			},
			{
				scope: 'business-rules',
				data: { businessRules },
			},
			{
				scope: 'reports',
				data: { reports },
			},
		];

		// TODO: refactor; convert schema to camelcase
		const axiosCalls = details
			.filter((x) => {
				return (
					hasEditPermission(x.scope) &&
					hasViewPermission(x.scope) && // check permission per scope
					x.data[Object.keys(x.data)[0]].isDirty
				); // check if form per tab has changes
			})
			.map((x) =>
				client({
					method,
					url: `${url}/${x.scope}`,
					data: formatObjectKeys(x.data, 'CAMEL'),
				})
			);

		const _responses: any = [];

		Promise.allSettled(axiosCalls)
			.then((responses) => {
				responses.forEach((v: FixMeLater) => {
					const partnerTab = findPartnerTab(
						v.value.config.url?.split('/').pop() || ''
					);
					if (v.status == 'fulfilled') {
						_responses.push({
							label: partnerTab?.label,
							formTab: partnerTab?.formTab,
							status: 'Success',
						});

						return;
					}
					if (
						!(v.status === 'rejected' && v.reason.message === 'Network Error')
					) {
						setIsSubmitted(false);
						_responses.push({
							label: partnerTab?.label,
							formTab: partnerTab?.formTab,
							status: 'Failed',
						});
					}
				});
			})
			.finally(async () => {
				if (_responses.length && axiosCalls.length) {
					setApiResponses(_responses);
					showEditSuccessModal();
					setIsSubmitted(true);

					await refetchPartnerDetails(_responses);
				} else if (_responses.length === 0 && axiosCalls.length === 0) {
					setApiResponses(_responses);
					showEditSuccessModal();
					setIsSubmitted(true);

					await refetchPartnerDetails(_responses);
				} else {
					setIsSubmitted(false);
					showErrorMessage(
						'Timeout Error!',
						'A problem occured while saving your changes.',
						null,
						save
					);
				}
				dispatch(setEditing(false));
				hideConfirmationModal();
				hideLoader();
			});
	};

	const saveRef = useRef(save);

	useEffect(() => {
		saveRef.current = save;
	}, [currentFormData]);

	// set to disabled when changing partner
	useEffect(() => {
		dispatch(updateIsDisabled(true));
		setIsSubmitted(false);
	}, [selectedBiller]);

	const updateStatusConfirmationModal = (selectedStatus) => {
		showConfirmationMessage(
			<h2 className={styles.approveHeading}>Update Partner Status</h2>,
			<div className={styles.updateStatus}>
				<div className={styles.updateStatusBody1}>
					Are you sure you want to update partner status?
					<br></br>
					Kindly note that this will be the partner's status in production
					environment.
				</div>
				<div className={styles.updateStatusBody2}>
					<div
						className={
							errorStatusRemarks.hasError
								? 'slds-form-element slds-has-error'
								: 'slds-form-element'
						}
					>
						<label className="slds-form-element__label">
							<abbr className="slds-required" title="required">
								*
							</abbr>
							Remarks
						</label>
						<div className="slds-form-element__control">
							<textarea
								className={'slds-textarea' + ' ' + styles.updateStatusRemarks}
								maxLength={255}
								ref={statusRemarksField}
								onInput={restrictSpecialChars}
								onChange={handleChangeOfRemarks}
							></textarea>
							{errorStatusRemarks.hasError && (
								<div className="slds-form-element__help">
									<div>Input Remarks</div>
								</div>
							)}
						</div>
					</div>
					<em className={styles.updateStatusSubLabel}>
						*Maximum of 255 characters only.
					</em>
				</div>
			</div>,
			() => {
				status(selectedStatus);
			},
			() => {
				setErrorStatusRemarks({ hasError: false, status: '' });
				dispatch(updateIsDisabled(true));
			}
		);
	};

	useEffect(() => {
		if (errorStatusRemarks.hasError)
			updateStatusConfirmationModal(errorStatusRemarks.status);
	}, [errorStatusRemarks]);

	const status = async (value: string) => {
		let remarks = statusRemarksField.current?.value;
		remarks = remarks ?? '';
		if (remarks.trim() === '') {
			setErrorStatusRemarks({ hasError: true, status: value });
			return;
		}
		setErrorStatusRemarks({ hasError: false, status: '' });

		hideConfirmationModal();
		setLoaderMessage('Please wait while the status is being updated.');
		showLoader();
		const method = 'put';
		const url = `/v2/products/${productType}/${productId}/update-status`;
		const param = {
			remarks,
			status: value,
		};

		const newValue = value
			.toLowerCase()
			.replace('_', ' ')
			.split(' ')
			.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');

		const successMessage = (
			<div>
				You successfully updated the partner status to
				<div className={styles.successModalSubtextBold}>{newValue}</div>
			</div>
		);

		client[method](url, param)
			.then(() => {
				updateProductStatus({
					id: selectedBiller.id,
					status: value,
				});

				showSuccessMessage(successMessage, () => {
					onClose && onClose();
				});
			})
			.catch(() => {
				showErrorMessage(
					'Timeout Error!',
					'A problem occurred while trying to update the partner status.',
					null,
					() => {
						status(value);
					}
				);
			})
			.finally(() => {
				hideConfirmationModal();
				hideLoader();
			});
	};

	const confirmExitEditMode = () =>
		showConfirmationMessage(
			'Exit Edit Mode',
			<>
				<div>Are you sure you want to Exit without saving?</div>
				<>Any unsaved changes will be lost</>
			</>,

			() => {
				hideConfirmationModal();
				dispatch(updateIsDisabled(true));
			}
		);

	const updatedBy = primaryInfo?.meta?.updated_by;
	const updatedAt = convertDate(primaryInfo?.meta?.updated_at, 'ISO');
	const submittedAt = convertDate(primaryInfo?.meta?.submitted_at, 'ISO');
	const activatedAt = convertDate(primaryInfo?.meta?.activated_at, 'ISO');
	const isPartnerDetailsOrTabStateLoading =
		isPartnerDetailsLoading || isTabStateLoading;

	return selectedBiller ? (
		<>
			{isPartnerDetailsOrTabStateLoading && hasPartnerListPermission && (
				<FullPageLoader
					open={isPartnerDetailsOrTabStateLoading && hasPartnerListPermission}
					message={'Please wait while partner details is being loaded...'}
				/>
			)}

			{isSuccessModalOpen && (
				<SuccessModal
					open={isSuccessModalOpen}
					onClose={hideSuccessModal}
					larger={true}
				>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successBodyWithPadding}>
								{successMessage}
							</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								doneBtnOnClick.action();
							}}
						>
							Okay
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalOpen && (
				<ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>{errorHeader}</div>
						<div className={styles.errorBody}>{errorMessage}</div>
						<div className={styles.errorFooter}>{errorInstruction}</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
			{isConfirmationModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmationModalOpen}
					isDisabled={isLoading}
					isPrimaryBtnDisabled={
						//disable confirm button in partner status if remarks is empty
						confirmationHeader === 'Update Partner Status' && isRemarksEmpty
					}
					heading={confirmationHeader}
					message={confirmationMessage}
					onClose={() => {
						onConfirmationClose.action();
					}}
					onCancelBtnClick={() => {
						onConfirmationClose.action();
					}}
					onConfirmBtnClick={() => {
						confirmBtnOnClick.action();
					}}
				/>
			)}
			{isEditSuccessModalOpen && (
				<ApiBatchResponseModal
					open={isEditSuccessModalOpen}
					onClose={() => {
						hideEditSuccessModal();
					}}
					data={apiResponses}
				/>
			)}

			{selectedBiller && (
				<div className={styles.container}>
					{isLoading && (
						<FullPageLoader open={isLoading} message={loaderMessage} />
					)}
					<div className={styles.header}>
						{hasPartnerListPermission && (
							<div className={styles.leftPanel}>
								<div className={styles.title}>
									{selectedBiller ? selectedBiller.name : 'Partner Name'}
								</div>
								<div className={styles.status}>
									<div className={styles.statusLabel}>Partner Status:</div>
									<div className={styles.statusField}>
										<PartnerStatusSelect
											value={selectedBiller.status}
											disabled={!hasEditStatusPermission}
											onSelect={(val) => {
												if (val != selectedBiller.status) {
													if (!hasEditStatusPermission) {
														dispatch(showAccessDeniedModal());
														return;
													}
													updateStatusConfirmationModal(val);
												}
											}}
											excludeOption={'DRAFT'}
										/>
									</div>
								</div>
							</div>
						)}
						{hasPartnerListPermission && (
							<div className={styles.rightPanel}>
								<div className={styles.edit}>
									<Button
										className={styles.linkBtn + ' ' + styles.edit}
										onClick={() => {
											if (isDisabled && !hasEditPermission(currentBillerTab)) {
												dispatch(showAccessDeniedModal());
												return;
											}

											if (!isDisabled && isFormEdited) {
												confirmExitEditMode();
											} else if (
												(!isDisabled && !isFormEdited) ||
												isSubmitted
											) {
												showConfirmationMessage(
													'Exit Edit Mode',
													<>
														<div>Are you sure you want to exit Edit Mode?</div>
													</>,
													() => {
														hideConfirmationModal();
														dispatch(updateIsDisabled(true));
														setIsSubmitted(false);
													}
												);
											} else {
												dispatch(updateIsDisabled(false));
												saveRef.current = save;
											}
										}}
									>
										<EditIcon />
										{isDisabled ? ' Edit Partner Details' : 'Exit Edit Mode'}
									</Button>
								</div>

								<div className={styles.dates}>
									<em className={styles.dateText}>
										{updatedAt && 'Last updated at ' + updatedAt}
										{updatedBy && updatedBy !== '-' && ' by ' + updatedBy}
									</em>
									<em className={styles.dateText}>
										{submittedAt && 'Date created: ' + submittedAt}
									</em>
									<em className={styles.dateText}>
										{activatedAt && 'Date activated: ' + activatedAt}
									</em>
								</div>
							</div>
						)}
					</div>
					<div className={styles.body}>
						{!isPartnerDetailsLoading && hasPartnerListPermission && (
							<PartnerForm
								disabled={isDisabled}
								data={formattedValues}
								action={isDisabled ? 'VIEW' : 'EDIT'}
								tabStatus={tabStatus}
								isPartnerDetailsForm
								fromAutoSave={false}
								setIsDisabled={() => ''}
							/>
						)}
					</div>
					<hr className={styles.hr} />
					<div className={styles.footer}>
						<div className={styles.leftPanel}>
							{!isDisabled && currentBillerTab != 'primary-info' && (
								<OutlineButton
									className={styles.btn}
									onClick={() => {
										const idx = partnerModalTabs.findIndex(
											(tab) => tab.name == currentBillerTab
										);

										const billerTab = partnerModalTabs[idx - 1].name;

										if (
											hasViewPermission(billerTab) &&
											hasEditPermission(billerTab)
										) {
											dispatch(setCurrentBillerTab(billerTab));
											return;
										}
										dispatch(showAccessDeniedModal());
									}}
								>
									Back
								</OutlineButton>
							)}
						</div>
						<div className={styles.rightPanel}>
							{!isDisabled && currentBillerTab != 'reports' && (
								<PrimaryButton
									className={styles.btn}
									onClick={() => {
										const idx = partnerModalTabs.findIndex(
											(tab) => tab.name == currentBillerTab
										);

										const billerTab = partnerModalTabs[idx + 1].name;
										if (
											hasViewPermission(billerTab) &&
											hasEditPermission(billerTab)
										) {
											dispatch(setCurrentBillerTab(billerTab));
											return;
										}
										dispatch(showAccessDeniedModal());
									}}
								>
									Next
								</PrimaryButton>
							)}
							{!isDisabled && (
								<PrimaryButton
									className={styles.btn}
									onClick={() => {
										dispatch(setEditing(true));
										showConfirmationMessage(
											'Save Changes',
											'Are you sure you want to save the changes made?',
											() => {
												saveRef.current();
											}
										);
									}}
									disabled={!isFormValid || !isFormEdited}
								>
									Save
								</PrimaryButton>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	) : (
		<PanelPlaceholder />
	);
};

export default connect(
	(state: Record<string, FixMeLater>) => ({
		selectedBiller: state.products.selectedBiller,
		products: state.sidebar.products,
		isDisabled: state.form.status.isDisabled,
		isFormValid:
			state.products.primaryInfo?.isValid &&
			state.products.contractDetails?.isValid &&
			state.products.serviceFeeSettings?.isValid &&
			state.products.businessRules?.isValid &&
			state.products.reports?.isValid,
		isFormEdited:
			state.products.primaryInfo?.isDirty ||
			state.products.contractDetails?.isDirty ||
			state.products.serviceFeeSettings?.isDirty ||
			state.products.businessRules?.isDirty ||
			state.products.reports?.isDirty,
		isTabStateLoading: !(
			state.products.primaryInfo?.isLoaded &&
			state.products.contractDetails?.isLoaded &&
			state.products.serviceFeeSettings?.isLoaded &&
			state.products.businessRules?.isLoaded &&
			state.products.reports?.isLoaded
		),
		isLeavingPartnerDetails: state.products.reports?.isLeavingPartnerDetails,
	}),
	{ setEditing, updateProductStatus }
)(PartnerDetails);
