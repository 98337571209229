import { Modal } from '@salesforce/design-system-react/module/components';
import Grid from 'components/Grid/Grid';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField, { UploadField } from 'components/Inputs/TextField/TextField';
import { Spinner } from '@salesforce/design-system-react/module/components';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { WalletType } from 'utils/lookup';
import styles from './CreateWalletAdjustment.module.css';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { yupResolver } from '@hookform/resolvers/yup';
import createAdjustmentForm from 'utils/formSchemas/createAdjustmentForm/createAdjustmentForm';
import RadioGroup from 'components/Inputs/RadioGroup/RadioGroup';
import TransactionNoFilter from '../../Filter/TransactionNoFilter';
import { ON_CREATE_BATCH_WAL_REP } from 'graphql/queries';
import { SectionRow } from 'components/Section/Section';
import { useSubscription } from '@apollo/client';
import Table from 'components/CWSTable/Table';
import UploadSuccessModal, {
	UploadSuccessModalActions,
	UploadSuccessModalBody,
} from 'components/Modal/UploadSuccessModal';
import client from 'helpers/ApiClient';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import UploadSuccessErrorModal, {
	UploadSuccessErrorModalActions,
	UploadSuccessErrorModalBody,
	UploadSuccessErrorText,
} from 'components/Modal/UploadSuccessErrorModal';
import { useToggle } from 'utils/hooks';
import AmountField from 'components/Inputs/TextField/AmountField';
import UploadAttachments from 'components/UploadAttachments/UploadAttachments';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { cancelRequest, TimeoutSliceState } from 'redux/modules/timeout';
import { ReducerStateType } from 'redux/modules/reducers';
import axios from 'axios';
import { ReactComponent as Icon } from 'assets/icons/ic-scanning.svg';
import { scanningErrorInfo } from 'components/WalletReplenishmentModal/WalletReplenishmentModal';
import { formatCash } from 'utils/common';

const Row: React.FC<{ gutters?: boolean }> = ({ gutters, children }) => (
	<Grid container gutters={gutters} className={styles.vspaced}>
		{children}
	</Grid>
);
type WalletAdjustmentFormData = any;

type Props = {
	open: boolean;
	handleClose: () => void;
	handleSuccess: () => void;
	handleSuccessClose: () => void;
	successSubmit: () => void;
	reloadTable: () => void;
	isUpload?: boolean;
	handleSubmit: any;
};
export const generalErrorInfo = {
	text: 'Failed Adjustment Request',
	subtext: 'Something went wrong with your request. Would you like to retry?',
	type: 'GENERAL_ERROR',
};
const CreateWalletAdjustment: React.FC<Props> = ({
	open,
	handleClose,
	handleSuccess,
	handleSuccessClose,
	reloadTable,
}) => {
	const {
		control,
		setValue,
		formState: { errors, isValid },
		handleSubmit,
		reset,
		watch,
		clearErrors,
		setError,
	} = useForm<WalletAdjustmentFormData>({
		mode: 'all',
		resolver: yupResolver(createAdjustmentForm),
	});

	const [partnerNames, setPartnerNames] = useState([]);
	const [walletIds, setWalletIds] = useState<any[]>([]);
	const [fileNamePresigned, setFileNamePresigned] = useState<string | null>(
		null
	);
	const cancelToken: any = axios.CancelToken.source();
	const [ackId, setAckId] = useState<string | null>(null);
	const [errorDupIds, setErrorDupIds] = useState<Array<any>>([]);
	const [productWallets, setProductWallets] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState({
		productName: false,
		walletId: false,
		submit: false,
	});

	const [errorModalInfo, setErrorModalInfo] = useState<{
		text: string;
		subtext: string;
		type: string;
	}>({ text: '', subtext: '', type: '' });

	const [fileList, setFileList] = useState([]);

	const resetUploadData = () => {
		setFileNamePresigned(null);
		setAckId(null);
		cancelToken.cancel();
	};

	const { value: isAttachmentError, valueOff: attachmentErrorFalse } =
		useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: openSuccessModal,
		valueOff: closeSuccessModal,
	} = useToggle();

	const options = {
		variables: { ack_id: ackId },
		shouldResubscribe: true,
		context: { clientName: 'adjustment' },
	};

	const { data, error } = useSubscription(ON_CREATE_BATCH_WAL_REP, options);

	const {
		value: isSuccessWithError,
		valueOn: showIsSuccessWithError,
		valueOff: hideIsSuccessWithError,
	} = useToggle();
	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isCloseDisabled,
		valueOn: disableClose,
		valueOff: enableClose,
	} = useToggle(false);

	const {
		value: isUploading,
		valueOn: showUploading,
		valueOff: hideUploading,
	} = useToggle();

	const {
		value: isWarningDuplicate,
		valueOn: showIsWarningDuplicate,
		valueOff: hideIsWarningDuplicate,
	} = useToggle();
	const closeSuccessWithError = () => {
		hideIsSuccessWithError();
		handleSuccessClose();
	};
	const {
		value: isSuccessUpload,
		valueOn: showIsSuccessUpload,
		valueOff: hideIsSuccessUpload,
	} = useToggle();
	const [batchTimeout, setBatchTimeout] = useState(setTimeout(() => {}));

	const dispatch = useDispatch();
	const { isInProgress } = useSelector<ReducerStateType>(
		(state) => state.timeout
	) as TimeoutSliceState;

	const [isUploadAttachmentReady, setIsUploadAttachmentReady] = useState(true);

	useEffect(() => {
		if (data && data.onCreateBatchRequest.progress === 'DONE') {
			handleClose();
			hideUploading();

			if (
				data.onCreateBatchRequest.error_records_count === 0 &&
				data.onCreateBatchRequest.total_records > 0
			) {
				showIsSuccessUpload();
				return;
			}
			if (data.onCreateBatchRequest.error_details) showIsSuccessWithError();
		}
	}, [data, error]);

	useEffect(() => {
		if (!open) {
			clearTimeout(batchTimeout);
			setBatchTimeout(setTimeout(() => {}));
		}
		reset();
		setWalletIds([]);
		setProductWallets([]);
		setPartnerNames([]);
		setFileList([]);
		if (isInProgress) dispatch(cancelRequest());
	}, [open]);

	useEffect(() => {
		(async () => {
			setIsLoading({
				...isLoading,
				productName: true,
			});

			const _walletType = watch('walletType');

			if (_walletType) {
				let res;
				if (_walletType == 'cws') {
					res = await client.get(`/v1/wallets/product-wallets`);
					setProductWallets(res.data.result);
					setPartnerNames(
						res.data.result.map((_pN) => ({ label: _pN.name, value: _pN.name }))
					);
				} else {
					res = await client.get(
						`/v1/cws/util/accounts?walletType=${_walletType}`
					);
					setPartnerNames(
						res.data.result.map((_pN) => ({ label: _pN.name, value: _pN.id }))
					);
				}

				setWalletIds([]);
				setValue('walletId', '');

				setIsLoading({
					...isLoading,
					productName: false,
				});
			}
		})();
	}, [watch('walletType')]);

	useEffect(() => {
		(async () => {
			setIsLoading({
				...isLoading,
				walletId: true,
			});

			const _partnerId = watch('partnerName');
			const _walletType = watch('walletType');

			if (_walletType !== 'cws') {
				if (_partnerId) {
					const res = await client.get(
						`/v1/wallets/filter?channelId=${_partnerId}`
					);
					setWalletIds(
						res.data.result.map((_w) => ({
							label: _w.wallet_identifier,
							value: _w.wallet_identifier,
						}))
					);

					if (res.data.result.length === 1) {
						setValue('walletId', res.data.result[0].wallet_identifier);
					} else {
						setValue('walletId', '');
					}
				}
			} else {
				if (_partnerId) {
					if (productWallets) {
						const _defaultWalletId = productWallets
							.filter((wallet) => wallet.name == _partnerId)
							.map((_w) => ({
								label: _w.wallet_identifier,
								value: _w.wallet_identifier,
							}));

						setWalletIds(_defaultWalletId);

						setValue('walletId', _defaultWalletId[0]?.value);
					}
				}
			}

			setIsLoading({
				...isLoading,
				walletId: false,
			});
		})();
	}, [watch('partnerName')]);

	useEffect(() => {
		setValue('transactionNo', null);
	}, [watch('walletId')]);

	useEffect(() => {
		if (
			watch('transactionNo')?.amount != null &&
			watch('transactionNo')?.amount !== undefined
		) {
			const _amount = TextFieldFormatter.amountFormat(
				'' + watch('transactionNo')?.amount
			);
			setValue('amountForAdjustment', _amount, {
				shouldValidate: true,
			});
		} else {
			setValue('amountForAdjustment', '');
		}
	}, [watch('transactionNo')]);

	const adjustmentRadioType = watch('adjustmentRadioType');
	const onSubmit = async (values, validateDup = 1) => {
		if (adjustmentRadioType === '0') {
			showUploading();
			hideErrorModal();
			const body = {
				wallet_id: values?.walletId,
				transaction_type: values?.adjustmentTypeField,
				amount: values.amountForAdjustment,
				remarks: values?.remarks ? values?.remarks : '',
				transaction_reference_number: values.transactionNo?.value
					? values.transactionNo?.value
					: '',
				attachments: fileList,
				file: values?.adjustmentFile,
				file_name: values?.adjustmentFile,
			};
			setIsLoading({
				...isLoading,
				submit: true,
			});

			client
				.post(`/v1/wallet-adjustments`, body)
				.then(() => {
					handleSuccess();
					openSuccessModal();
				})
				.catch((err) => {
					showErrorModal();
					if (
						axios.isAxiosError(err) &&
						err.response?.status === 400 &&
						err?.response?.data?.errors?.['SCAN_IN_PROGRESS']?.length > 0
					) {
						setErrorModalInfo(scanningErrorInfo);
						return;
					}
					setErrorModalInfo(generalErrorInfo);
				})
				.finally(() => {
					setIsLoading({
						...isLoading,
						submit: false,
					});
				});
		}
		if (adjustmentRadioType === '1' && values.adjustmentFile) {
			showUploading();
			let upload;

			if (!fileNamePresigned && validateDup) {
				upload = await client
					.get(
						`/v2/wallet-adjustments/batch-upload/url?fileName=${values.adjustmentFile.name}&contentType=${values.adjustmentFile.type}`,
						{ cancelToken: cancelToken.token }
					)
					.then(async (response) => {
						const presignedData = response.data.presigned_keys;
						if (response.data.presigned_keys.statusCode >= 400) {
							const errorBody = JSON.parse(response.data.presigned_keys.body);
							switch (errorBody.errorCode) {
								case 'GENERIC_ERROR':
									setError(
										'adjustmentFile',
										{
											type: 'manual',
											message: errorBody.message,
										},
										{ shouldFocus: true }
									);
									break;
							}
							return null;
						}

						const data = new FormData();
						Object.entries(presignedData.fields).forEach(
							([key, value]: any) => {
								data.append(key, value);
							}
						);
						data.append('File', values.adjustmentFile);
						return await axios
							.post(presignedData.url, data, {
								headers: {
									'x-bayad-platform-id': process.env.REACT_APP_APP_KEY,
								},
								cancelToken: cancelToken.token,
								...presignedData.fields,
							})
							.then((uploadRes) => {
								return { presignRes: response, uploadRes };
							})
							.catch((e) => {
								showErrorModal();
								setErrorModalInfo(generalErrorInfo);
								return e;
							});
					})
					.catch((e) => {
						if ({ ...e.response }.status == 403) {
							hideUploading();
							return;
						}
						e.response;
					});

				if (isErrorModalOpen || !upload) {
					hideUploading();
					return;
				}

				if (
					{ ...upload.presignRes }.status === undefined &&
					!{ ...upload.presignRes }.data?.file_name
				) {
					showErrorModal();
					setErrorModalInfo(generalErrorInfo);
				}

				if ({ ...upload.uploadRes }.status !== 204) {
					setError(
						'adjustmentFile',
						{
							type: 'manual',
							message: 'Failed to upload.',
						},
						{ shouldFocus: true }
					);
					return;
				}

				setFileNamePresigned(upload.presignRes.data.file_name);

				setBatchTimeout(
					setTimeout(() => {
						batchValidateDup(validateDup, upload);
					}, 28000)
				);
			} else {
				batchValidateDup(validateDup, upload);
			}
		}
	};
	const batchValidateDup = (validateDup, upload) => {
		disableClose();
		client
			.post(
				`/v2/wallet-adjustments/batch-upload${
					validateDup ? '?validateDup=true' : ''
				}`,
				{
					file_name: fileNamePresigned || upload.presignRes.data.file_name,
					validateDup,
					cancelToken: cancelToken.token,
				}
			)
			.then((res) => {
				setAckId(res.data.ack_id);
			})
			.catch((e) => {
				enableClose();
				hideUploading();
				console.log('Error Upload: ', { ...e });
				const response = { ...e.response };
				const reponseData = { ...response }.data;
				switch ({ ...reponseData }.errorCode) {
					case 'GENERIC_ERROR':
						setError(
							'adjustmentFile',
							{
								type: 'manual',
								message: e.response.data.message,
							},
							{ shouldFocus: true }
						);
						break;
					case 'SCAN_ERROR':
						setError(
							'adjustmentFile',
							{
								type: 'manual',
								message: 'Scanning in progress...',
							},
							{ shouldFocus: true }
						);
						break;
					case 'DUPLICATE_ERROR':
						setErrorDupIds({ ...reponseData }.errors.duplicate_wallets);
						showIsWarningDuplicate();
						break;
					case 'MAX_RECORDS_ERROR':
						setError(
							'adjustmentFile',
							{
								type: 'manual',
								message:
									'Maximum allowed of users exceeded. Only 200 users are allowed.',
							},
							{ shouldFocus: true }
						);
						break;
					case 'EMPTY_FILE_ERROR':
						setError(
							'adjustmentFile',
							{
								type: 'manual',
								message: 'The file is empty.',
							},
							{ shouldFocus: true }
						);
						break;
					case 'FILE_TYPE_ERROR':
						setError(
							'adjustmentFile',
							{
								type: 'manual',
								message: 'Invalid file type.',
							},
							{ shouldFocus: true }
						);
						break;
					default:
						showErrorModal();
						setErrorModalInfo(generalErrorInfo);
				}
			}).finally(() => {
				enableClose();
			});
	};

	useEffect(() => {
		if (fileList.length > 0) {
			attachmentErrorFalse();
		}
	}, [fileList]);

	const closeSuccess = () => {
		closeSuccessModal();
		handleSuccessClose();
	};

	return (
		<>
			<Modal
				disableClose={isCloseDisabled}
				isOpen={open}
				onRequestClose={handleClose}
				headerClassName={styles.headerContainer}
				contentClassName={styles.modal}
				className={styles.createAdjustmentModal}
				footer={
					<div className={styles.footer}>
						<OutlineButton
							onClick={handleClose}
							className={styles.btn}
							disabled={isLoading.submit || isCloseDisabled}
						>
							Cancel
						</OutlineButton>

						<PrimaryButton
							onClick={handleSubmit((v) => onSubmit(v))}
							className={styles.btn}
							disabled={
								!isValid ||
								isLoading.submit ||
								(!isUploadAttachmentReady && adjustmentRadioType === '0')
							}
						>
							{adjustmentRadioType === '1' ? (
								isUploading ? (
									<div>
										Uploading{' '}
										<Spinner
											size="x-small"
											variant="base"
											assistiveText={{ label: 'Uploading...' }}
										/>
									</div>
								) : (
									'Upload'
								)
							) : (
								'Submit'
							)}
						</PrimaryButton>
					</div>
				}
				size="small"
			>
				<div className={cx('slds-card', styles.container)}>
					<div>
						<h1 className={styles.headerH1}>Add New Adjustment</h1>
						<div className={styles.topRadioChoices}>
							<RadioGroup
								label=""
								options={[
									{
										label: 'Single',
										value: '0',
									},
									{
										label: 'Batch',
										value: '1',
									},
								]}
								name="adjustmentRadioType"
								control={control}
								isVertical={true}
								defaultValue={'0'}
							/>
						</div>
					</div>
					<br />
					<>
						{adjustmentRadioType == '1' ? (
							<div
								className={cx(
									'slds-col slds-size_12-of-12',
									styles.walletInput
								)}
							>
								<SectionRow className={styles.walletRepUploadFieldContainer}>
									<UploadField
										skipFileUpload
										showErrorMessageFromBatchUpload
										link="/v2/wallet-adjustments/batch-upload/url"
										label="Upload File"
										name="adjustmentFile"
										helperText="You can batch import a max of 200 wallet adjustments requests per csv file using this feature."
										control={control}
										onChange={watch}
										setValue={setValue}
										clearErrors={clearErrors}
										fileFormats={['csv']}
									/>
								</SectionRow>
							</div>
						) : (
							<Grid container vertical>
								<Row gutters>
									<Grid column size={1} of={2}>
										<SelectField
											label="Wallet Type"
											name="walletType"
											control={control}
											placeholder="Select Wallet Type"
											options={WalletType}
											required
										/>
									</Grid>
								</Row>

								<Grid container vertical>
									<Row>
										<Grid column size={1} of={1}>
											<SelectField
												label="Partner Name"
												control={control}
												placeholder="Select Partner Name"
												name="partnerName"
												options={[...partnerNames]}
												isLoading={isLoading.productName}
												required
											/>
										</Grid>
									</Row>
									<Row gutters>
										<Grid column size={1} of={2}>
											<SelectField
												label="Wallet ID"
												name="walletId"
												control={control}
												placeholder="Select Wallet ID"
												options={[...walletIds]}
												isLoading={isLoading.walletId}
												required
											/>
										</Grid>
										<Grid column size={1} of={2}>
											<SelectField
												label="Adjustment Type"
												name="adjustmentTypeField"
												control={control}
												placeholder="Select Adjustment Type"
												options={[
													{
														label: 'Wallet Adjustment - Credit',
														value: 'WALLET_ADJUSTMENT_CREDIT',
													},
													{
														label: 'Wallet Adjustment - Debit',
														value: 'WALLET_ADJUSTMENT_DEBIT',
													},
												]}
												required
											/>
										</Grid>
									</Row>
									<Row gutters>
										<Grid column size={1} of={2}>
											<div>
												<TransactionNoFilter
													displayName="Transaction Reference No."
													name="transactionNo"
													control={control}
													walletId={watch('walletId')}
													errors={errors}
												/>
												{errors.transactionNo && (
													<>
														<p
															style={{
																color: '#ea001e',
																fontSize: 'var(--input-label-font-size)',
															}}
														>
															{errors.transactionNo.message}
														</p>
													</>
												)}
											</div>
										</Grid>
										<Grid column size={1} of={2}>
											<AmountField
												name="amountForAdjustment"
												control={control}
												label="Amount for Adjustment"
												placeholder="0.00"
												formatValue={TextFieldFormatter.amountFormat}
												readOnly={watch('transactionNo') != null}
												required
											/>
										</Grid>
									</Row>
									{watch('transactionNo') != null && (
										<Row gutters>
											<Grid column size={1} of={2}>
												<SelectField
													label="Product Type"
													name="productType"
													control={control}
													options={[]}
													disabled
												/>
											</Grid>
										</Row>
									)}
									<Row gutters>
										<Grid
											className={styles.uploadAttachmentAdj}
											column
											size={1}
											of={1}
										>
											<UploadAttachments
												changeFileList={(val) => setFileList(val)}
												secondaryLabel="*For faster approval, kindly attach here proof or supporting documents
									for you request."
												displayError={isAttachmentError}
												uploadUrl="/v1/wallet-adjustments/url"
												setIsUploadAttachmentReady={setIsUploadAttachmentReady}
											/>
										</Grid>
									</Row>
									<Row gutters>
										<Grid column size={1} of={1}>
											<TextField
												label="Remarks"
												name="remarks"
												control={control}
												placeholder="Remarks"
												multiLine
												rows={5}
											/>
										</Grid>
									</Row>
								</Grid>
							</Grid>
						)}
					</>
				</div>
			</Modal>
			<SuccessModal open={isSuccessModalOpen} onClose={closeSuccessModal}>
				<SuccessModalBody>
					<SuccessText>
						<div className={styles.successHeader}>Thank you!</div>
						<div className={styles.subtext}>
							Your adjustment request has been received.
							<br />
							We will update you through email on the status of your request.
						</div>
					</SuccessText>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.addressErrorCloseBtn}
						onClick={closeSuccess}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
			{isSuccessWithError && (
				<UploadSuccessErrorModal
					open={isSuccessWithError}
					onClose={closeSuccessWithError}
					larger={true}
				>
					<UploadSuccessErrorModalBody>
						<UploadSuccessErrorText>
							<div className={styles.uploadSuccessErrorBody}>
								<div className={styles.uploadSuccessErrorText}>
									{data.onCreateBatchRequest.success_records_count} out of{' '}
									{data.onCreateBatchRequest.total_records} adjustments uploaded
									successfully!
								</div>
								<div
									className={styles.uploadSuccessErrorSubText}
									style={{ opacity: '0.9' }}
								>
									The following errors were found:
								</div>
								<br />
								<div className={styles.tErrorUpload}>
									<Table
										columns={[
											{
												Header: 'Row',
												id: 'row',
												Cell: ({ value }) => value,
												width: '30%',
											},
											{
												Header: 'Error Description 	',
												id: 'error',
												Cell: ({ value }) => value,
												width: '70%',
											},
										]}
										data={Object.entries(
											JSON.parse(data.onCreateBatchRequest.error_details)
										).map(([key, value]: any) => {
											return { row: key, error: value.join(', ') };
										})}
										count={10}
										pageSize={10}
										onPageChange={() => {}}
										onSort={() => {}}
										page={10}
										showPagination={false}
										preHeader={null}
										isNotEvenRowColor={undefined}
									/>
								</div>
							</div>
						</UploadSuccessErrorText>
					</UploadSuccessErrorModalBody>
					<UploadSuccessErrorModalActions>
						<PrimaryButton
							className={styles.addressUploadErrorCloseBtn}
							onClick={() => {
								resetUploadData();
								closeSuccessWithError();
							}}
						>
							Close
						</PrimaryButton>
					</UploadSuccessErrorModalActions>
				</UploadSuccessErrorModal>
			)}
			{isWarningDuplicate && (
				<UploadSuccessModal
					open={!isSuccessWithError}
					onClose={hideIsSuccessWithError}
					larger={true}
				>
					<UploadSuccessModalBody>
						<SuccessText>
							<div
								className={styles.successHeader}
								style={{ textAlign: 'center' }}
							>
								Add New Adjustment
							</div>
							<hr
								style={{
									borderTop: '1px solid gray',
									opacity: '0.7',
									marginTop: '10px',
									marginBottom: '10px',
									width: '100%',
								}}
							/>
							<div
								className={styles.successText}
								style={{
									opacity: '0.8',
									textAlign: 'center',
									padding: '0.8vw 1.6vw 0.1vw 1.6vw',
								}}
							>
								You have a duplicate request under the listed wallet ID/s. Would
								you like to proceed with the existing upload or re-upload a new
								file?
							</div>
							<br />

							<div className={styles.tableErrorUpload}>
								<Table
									columns={[
										{
											Header: 'Wallet ID',
											id: 'walletIds',
											Cell: ({ value }) => value,
											width: '100%',
										},
									]}
									data={errorDupIds.map((id) => ({ walletIds: id }))}
									count={10}
									pageSize={10}
									onPageChange={() => {}}
									onSort={() => {}}
									page={10}
									showPagination={false}
									preHeader={null}
									isNotEvenRowColor={undefined}
								/>
							</div>
						</SuccessText>
						<hr
							style={{
								borderTop: '1px solid gray',
								opacity: '0.7',
								marginTop: '25px',
								marginBottom: '-20px',
								width: '100%',
								padding: '1vw',
							}}
						/>
					</UploadSuccessModalBody>
					<UploadSuccessModalActions>
						<div
							style={{
								marginTop: '0',
								paddingBottom: '15px',
								paddingRight: '10px',
							}}
						>
							<PrimaryButton
								className={styles.outlineSuccessBtn}
								onClick={() => {
									setError(
										'replenishmentFile',
										{
											type: 'manual',
											message: 'Duplicate wallet found!',
										},
										{ shouldFocus: true }
									);
									resetUploadData();
									hideIsWarningDuplicate();
									hideUploading();
								}}
							>
								Re-Upload
							</PrimaryButton>
							<PrimaryButton
								className={styles.addressErrorCloseBtn}
								onClick={() => {
									handleSubmit((v) => onSubmit(v, 0))();
									hideIsWarningDuplicate();
								}}
							>
								Proceed
							</PrimaryButton>
						</div>
					</UploadSuccessModalActions>
				</UploadSuccessModal>
			)}
			{isSuccessUpload && (
				<SuccessModal
					open={isSuccessUpload}
					onClose={hideIsSuccessUpload}
					larger={true}
				>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>
								Success!
								<br />
								You've uploaded{' '}
								{data.onCreateBatchRequest.success_records_count} adjustments
							</div>
							<div className={styles.subtext}>
								Your request is subject for approval. We will notify you via
								email when your adjustment request is approved.
							</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.addressErrorCloseBtn}
							onClick={() => {
								reloadTable();
								hideIsSuccessUpload();
								resetUploadData();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			<ErrorModal
				open={isErrorModalOpen}
				Icon={
					errorModalInfo.type === scanningErrorInfo.type ? (
						<Icon className={styles.scanningIcon} />
					) : null
				}
				onClose={hideErrorModal}
			>
				<ErrorModalBody>
					<ErrorText>{errorModalInfo.text}</ErrorText>
					<ErrorSubText>
						<span
							style={{
								...(errorModalInfo.type === scanningErrorInfo.type && {}),
							}}
						>
							{errorModalInfo.subtext}
						</span>
					</ErrorSubText>
				</ErrorModalBody>
				<ErrorModalActions>
					{errorModalInfo.type === scanningErrorInfo.type ? (
						<PrimaryButton
							className={styles.walletAdjustmentErrorCloseBtn}
							onClick={hideErrorModal}
						>
							Close
						</PrimaryButton>
					) : (
						<>
							<OutlineButton
								className={styles.walletAdjustmentErrorCloseBtn}
								onClick={hideErrorModal}
							>
								Cancel
							</OutlineButton>
							<PrimaryButton
								className={styles.walletAdjustmentErrorCloseBtn}
								onClick={handleSubmit((v) => onSubmit(v))}
							>
								Retry
							</PrimaryButton>
						</>
					)}
				</ErrorModalActions>
			</ErrorModal>
		</>
	);
};

export default CreateWalletAdjustment;
