import { ReactNode } from 'react';
import cx from 'classnames';
import { Modal } from '@salesforce/design-system-react/module/components';

import Grid from 'components/Grid/Grid';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import { ReactComponent as Icon } from 'assets/icons/ic-alert.svg';

import styles from './WarningModal.module.css';

const WarningIcon: React.FC = () => <Icon className={styles.warningIcon} />;

type WarningModalProps = {
	isOpen?: boolean;
	onClose?: () => void;
	Icon?: React.FC;
	message?: string | ReactNode;
	className?: string;
};

const WarningModal: React.FC<WarningModalProps> = ({
	isOpen,
	onClose,
	Icon = WarningIcon,
	message = '',
	className = '',
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			headerClassName={styles.warningModalHeader}
			size="small"
			containerClassName={styles.warningModalContainer}
			contentClassName={cx(styles.warningModal, className && className)}
			ariaHideApp={false}
		>
			<Grid
				vertical
				verticalAlign="center"
				className={styles.warningModalContent}
			>
				{Icon && (
					<Grid column className={styles.warningModalIconContainer}>
						<Icon />
					</Grid>
				)}
				<Grid column className={styles.warningModalBody}>
					<div>{message}</div>
				</Grid>
				<Grid column className={styles.warningModalActions}>
					<PrimaryButton
						fullWidth
						onClick={() => onClose && onClose()}
						className={styles.warningModalBtn}
					>
						Okay
					</PrimaryButton>
				</Grid>
			</Grid>
		</Modal>
	);
};

export default WarningModal;
