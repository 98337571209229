import emailIcon from '../../assets/icons/ic-email.svg';
import styles from './LeftPane2.module.css';
import React, { Component } from 'react';
import IconResolver from '../../helpers/IconResolver';
import cx from 'classnames';

class LeftPane2 extends Component {
	render() {
		return (
			<div className='slds-p-around_large'>
				<div>
					<a onClick={this.props.onReturnClick}>
						<span className='slds-current-color'>
							<IconResolver
								className={cx(
									'slds-icon_xx-small',
									'slds-p-bottom_xx-small'
								)}
								path={'utility/chevronleft'}
							/>
						</span>
						<span>Back</span>
					</a>
				</div>
				<div className='slds-align_absolute-center slds-m-around_xx-large'>
					<img src={emailIcon} alt='Email Icon' />
				</div>
				<div style={{ textAlign: 'center' }}>
					<div style={{ marginBottom: '20px' }}>
						<span
							className={styles.fontWavehaus}
							style={{ fontSize: '18px', color: '#0076C0' }}
						>
							Contact Us
						</span>
					</div>
					<div>
						<span
							className={styles.fontPoppins}
							style={{ fontSize: '14px', display: 'block' }}
						>
							For inquiries and concerns, <br /> you may contact
							us at
						</span>
					</div>
				</div>
				<div>
					<div
						className={styles.fontPoppins}
						style={{
							textAlign: 'left',
							padding: '60px 20px 0 20px',
						}}
					>
						<span className={styles.contact}>
							Email:{' '}
							<a href='mailto:itservicedesk@bayad.com'>
								itservicedesk@bayad.com
							</a>
						</span>
						<span className={styles.contact}>
							IT Service Desk Hotline: +632 8672-5752
						</span>
						<span className={styles.contact}>
							Mobile Number: +639190819865
						</span>
					</div>
				</div>
			</div>
		);
	}
}

export default LeftPane2;
