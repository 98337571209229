import cx from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import {
	Control,
	Controller,
	useController,
	useWatch,
	UseFormSetValue,
} from 'react-hook-form';
import Checkbox from 'components/Checkbox/Checkbox';
import Label from '../Label/Label';
import styles from './CheckboxGroup.module.css';
import { FixMeLater } from 'types';

export type CheckboxSubOption = {
	label: string;
	value: string | number;
	disabled?: boolean;
};

export type CheckboxOption = {
	label: string;
	value: string;
	icon?: ReactNode;
	disabled?: boolean;
	subLabel?: string;
	subName?: string;
	subDisabled?: boolean;
	subOptions?: CheckboxSubOption[];
	subOnChange?: (val: Array<Record<string, unknown>>) => void;
};

type Props = {
	parentName: string;
	showLabel?: boolean;
	label?: string;
	subLabel?: string;
	onChange?: (val: Array<string>) => void;
	disabled?: boolean;
	subDisabled?: boolean;
	name: string;
	options: CheckboxOption[];
	required?: boolean;
	orientation?: 'horizontal' | 'vertical';
	variant?: 'base' | 'button' | 'gray';
	selectAllLabel?: string;
	displayAll?: boolean;
	fitHorizontally?: boolean;
	setValue?: UseFormSetValue<FixMeLater>;
};

const CheckboxGroup: React.FC<Props & { control?: Control<FixMeLater> }> = ({
	options,
	disabled,
	name = '',
	parentName = '',
	control,
	label,
	subLabel,
	onChange,
	required,
	orientation = 'horizontal',
	variant = 'base',
	showLabel,
	displayAll,
	fitHorizontally,
	setValue,
	selectAllLabel,
	subDisabled,
}) => {
	const selectAllCheckbox = useRef<HTMLInputElement>(null);
	const [values, setValues] = useState<string[]>([]);
	const [subValues, setSubValues] = useState<
		Array<{ subName?: string; options?: string[] }>
	>([]);
	const handleCheckboxOnChange = (value: string) => {
		let collection: string[] = [];
		if (values.includes(value)) {
			collection = values.filter((val) => val != value);
			setValues(collection);
		} else {
			collection = Array.isArray(values) ? values.slice() : [values];
			collection.push(value);
			setValues(collection);
		}
		if (collection.length == options.length) {
			if (selectAllCheckbox.current != null) {
				selectAllCheckbox.current.checked = true;
			}
		}
		setValue?.(name, collection, { shouldDirty: true, shouldValidate: true });
		onChange?.(collection);
	};

	const { field } = useController({ name, control });
	const contract = useWatch({ name: parentName, control });

	const findSubData = (subName?: string, options = subValues): FixMeLater =>
		options.find((s) => s.subName == subName) || { subName: '', options: [] };

	// set on load values
	useEffect(() => {
		let collection = [];
		if (field.value) {
			if (field.value.includes(',')) {
				collection = field.value.split(',');
				setValues(collection);
			} else {
				collection = field.value;
				setValues(field.value);
			}
		}
		onChange?.(collection);

		if (options.length && contract) {
			const multipleSubs = options
				.map((o) => {
					if (o?.subName) {
						const subNameSplit: string = o.subName.split('.')[1];
						const subValue: FixMeLater = contract[subNameSplit] || '';
						const remapValue: Array<string> = (() => {
							if (typeof subValue === 'number') return [`${subValue}`];
							if (subValue && typeof subValue === 'string')
								return subValue?.split(',');
							if (Array.isArray(subValues)) return subValue;
							return [];
						})();
						if (remapValue.length) {
							return {
								subName: o.subName,
								options: remapValue,
							};
						}
					}
					return null;
				})
				.filter((v) => !!v);
			multipleSubs && setSubValues(multipleSubs as never);
		}
	}, [field.value]);

	const handleSubCheckboxOnChange = (
		value: string | number,
		subName: string | undefined,
		subOnChange?: CheckboxOption['subOnChange']
	) => {
		let collection: Array<Record<string, unknown>> = [];
		const subVal = findSubData(subName);
		if (subVal.options.includes(value)) {
			const removeOption = subVal?.options.filter((val) => val != value);
			collection = subValues.filter((s) => s.subName != subName);
			const formatValue = { subName, options: removeOption };
			collection.push(formatValue);
			setSubValues(collection);
		} else {
			const formatValue = { subName, options: [...subVal.options, value] };
			collection = [...subValues.filter((s) => s.subName != subName)];
			collection.push(formatValue);
			setSubValues(collection);
		}
		if (setValue && subName) {
			setValue(subName, findSubData(subName, collection).options ?? null, {
				shouldDirty: true,
				shouldValidate: true,
			});
		}
		subOnChange?.(collection);
	};
	return control && name ? (
		<>
			<Controller
				name={name}
				control={control}
				rules={{ required: required }}
				render={(props) => {
					const {
						fieldState: { error },
					} = props;
					return (
						<>
							<div
								className={cx(styles.checkboxGroup, { [styles.error]: error })}
							>
								{showLabel && label && (
									<legend>
										<Label className={styles.label} required={required}>
											{label}
										</Label>
										{subLabel && (
											<em className={styles.subLabel}>{subLabel}</em>
										)}
									</legend>
								)}
								{displayAll && !disabled && (
									<Checkbox
										label=""
										disabled={disabled}
										labels={{ label: selectAllLabel || 'Select All ' + label }}
										ref={selectAllCheckbox}
										value={'all'}
										className={cx(styles.checkbox, {
											[styles.checked]: true,
										})}
										checked={values.length == options.length || undefined}
										onChange={(val) => {
											let collections: string[] = [];
											if (val == true) {
												collections = options.map((i) => i.value);
												setValues(collections);
											} else {
												setValues(collections);
											}
											setValue?.(name, collections, {
												shouldDirty: true,
												shouldValidate: true,
											});
											onChange && onChange(collections);
										}}
									/>
								)}
								<ul
									className={cx(
										styles.optionsContainer,
										variant == 'base' ? styles.base : styles.btn,
										variant == 'gray' ? styles.gray : styles.grayBtn,
										orientation == 'vertical'
											? styles.vertical
											: styles.horizontal,
										fitHorizontally && orientation == 'horizontal'
											? styles.fit
											: null
									)}
								>
									{options?.map((option, i) =>
										variant == 'base' ? (
											<li key={i}>
												<Checkbox
													label=""
													disabled={option.disabled || disabled}
													name={name}
													labels={{ label: option.label }}
													value={option.value}
													checked={values.includes(option.value) || undefined}
													className={cx(styles.checkbox, {
														[styles.checked]: values.includes(option.value),
													})}
													onChange={() => handleCheckboxOnChange(option.value)}
												/>
											</li>
										) : variant == 'gray' ? (
											<li key={i}>
												<Checkbox
													label=""
													disabled={option.disabled || disabled}
													name={name}
													labels={{ label: option.label }}
													value={option.value}
													checked={values.includes(option.value) || undefined}
													className={cx(styles.checkbox, styles.disabled, {
														[styles.checked]: values.includes(option.value),
													})}
													onChange={(val) => {
														handleCheckboxOnChange(option.value);
													}}
												/>
											</li>
										) : (
											<li key={i}>
												<div
													className={cx(styles.checkboxBtn, {
														[styles.checked]: values.includes(option.value),
													})}
												>
													<Checkbox
														label=""
														disabled={option.disabled || disabled}
														name={name}
														value={option.value}
														checked={values.includes(option.value) || undefined}
														className={cx(styles.checkbox, {
															[styles.checked]: values.includes(option.value),
														})}
														onChange={() =>
															handleCheckboxOnChange(option.value)
														}
													/>
													<div
														onClick={(e) => {
															if (disabled) return;
															handleCheckboxOnChange(option.value);
															e.preventDefault();
														}}
														className={styles.checkboxBtnIconContainer}
													>
														{option.icon}
													</div>
													<div
														onClick={(e) => {
															if (disabled) return;
															handleCheckboxOnChange(option.value);
															e.preventDefault();
														}}
														className={styles.checkboxBtnLabelContainer}
													>
														<Label
															className={styles.checkboxBtnLabel}
															required={required}
														>
															{option.label}
														</Label>
													</div>
													{option.subLabel && (
														<div className={styles.checkboxBtnSubLabel}>
															{option.subLabel}
														</div>
													)}
													<ul
														className={styles.checkboxBtnOptionsContainerBlock}
													>
														{option.subOptions?.map((subOption, i) => (
															<li key={i}>
																<Checkbox
																	label=""
																	disabled={
																		subDisabled ||
																		option.subDisabled ||
																		subOption.disabled ||
																		disabled ||
																		undefined
																	}
																	labels={{ label: subOption.label }}
																	value={subOption.value}
																	showErrorMessage={false}
																	checked={
																		findSubData(
																			option.subName
																		).options.includes(subOption.value) ??
																		undefined
																	}
																	className={cx(styles.checkbox, {
																		[styles.checked]:
																			findSubData(
																				option.subName
																			).options.includes(subOption.value) ??
																			undefined,
																	})}
																	onChange={() => {
																		handleSubCheckboxOnChange(
																			subOption.value,
																			option.subName,
																			option.subOnChange
																		);
																	}}
																/>
															</li>
														))}
													</ul>
												</div>
											</li>
										)
									)}
								</ul>
							</div>

							{error && (
								<div className="slds-has-error">
									<div className={cx(styles.helper, 'slds-form-element__help')}>
										{error?.message}
									</div>
								</div>
							)}
						</>
					);
				}}
			/>
		</>
	) : (
		<>
			<div className={cx(styles.checkboxGroup)}>
				{showLabel && label && (
					<legend>
						<Label className={styles.label} required={required}>
							{label}
						</Label>
						{subLabel && <em className={styles.subLabel}>{subLabel}</em>}
					</legend>
				)}
				{displayAll && (
					<Checkbox
						label=""
						disabled={disabled}
						labels={{ label: selectAllLabel || 'Select All ' + label }}
						ref={selectAllCheckbox}
						value={'all'}
						className={cx(styles.checkbox, {
							[styles.checked]: true,
						})}
						checked={values.length == options.length || undefined}
						onChange={(val?: boolean) => {
							if (val == true) {
								setValues(options.map((i) => i.value));
							} else {
								setValues([]);
							}
							if (setValue) {
								setValue(name, values, {
									shouldDirty: true,
									shouldValidate: true,
								});
							}
							onChange && onChange(values);
						}}
					/>
				)}
				<ul
					className={cx(
						styles.optionsContainer,
						variant == 'base' ? styles.base : styles.btn,
						orientation == 'vertical' ? styles.vertical : styles.horizontal,
						fitHorizontally && orientation == 'horizontal' ? styles.fit : null
					)}
				>
					{options?.map((option, i) =>
						variant == 'base' ? (
							<li key={i}>
								<Checkbox
									label=""
									disabled={option.disabled || disabled}
									labels={{ label: option.label }}
									value={option.value}
									checked={values.includes(option.value) || undefined}
									className={cx(styles.checkbox, {
										[styles.checked]: values.includes(option.value),
									})}
									onChange={() => handleCheckboxOnChange(option.value)}
								/>
							</li>
						) : (
							<li key={i}>
								<div
									className={cx(styles.checkboxBtn, {
										[styles.checked]: values.includes(option.value),
									})}
								>
									<Checkbox
										label=""
										disabled={option.disabled || disabled}
										value={option.value}
										checked={values.includes(option.value) || undefined}
										className={cx(styles.checkbox, {
											[styles.checked]: values.includes(option.value),
										})}
										onChange={() => handleCheckboxOnChange(option.value)}
									/>
									<div
										onClick={(e) => {
											if (disabled) return;
											handleCheckboxOnChange(option.value);
											e.preventDefault();
										}}
										className={styles.checkboxBtnIconContainer}
									>
										{option.icon}
									</div>
									<div
										onClick={(e) => {
											if (disabled) return;
											handleCheckboxOnChange(option.value);
											e.preventDefault();
										}}
										className={styles.checkboxBtnLabelContainer}
									>
										<Label
											className={styles.checkboxBtnLabel}
											required={required}
										>
											{option.label}
										</Label>
									</div>
									{option.subLabel && (
										<div className={styles.checkboxBtnSubLabel}>
											{option.subLabel}
										</div>
									)}
									<ul className={styles.checkboxBtnOptionsContainerBlock}>
										{option.subOptions?.map((subOption, i) => (
											<li key={i}>
												<Checkbox
													label=""
													disabled={
														subDisabled ||
														option.subDisabled ||
														subOption.disabled ||
														disabled ||
														undefined
													}
													labels={{ label: subOption.label }}
													value={subOption.value}
													showErrorMessage={false}
													checked={
														findSubData(option.subName).options.includes(
															subOption.value
														) ?? undefined
													}
													className={cx(styles.checkbox, {
														[styles.checked]:
															findSubData(option.subName).options.includes(
																subOption.value
															) ?? undefined,
													})}
													onChange={() =>
														handleSubCheckboxOnChange(
															subOption.value,
															option.subName,
															option.subOnChange
														)
													}
												/>
											</li>
										))}
									</ul>
								</div>
							</li>
						)
					)}
				</ul>
			</div>
		</>
	);
};

export default CheckboxGroup;
