import { FixMeLater } from 'types';
import moment from 'moment';

export function validateDateFrom(
	dateFrom?: string,
	dateTo?: string
): string | null {
	if (dateFrom && new Date(dateFrom) > new Date()) {
		return "You've selected invalid date";
	}

	if (dateTo) {
		return dateFrom && new Date(dateFrom) > new Date(dateTo)
			? "You've selected invalid date"
			: null;
	}
	return null;
}

export function validateLandlineNumber(num: string, index?: number): boolean {
	if (index && index >= 25) {
		return false;
	}
	return new RegExp(/^\d{7,11}$/ || /^(02)\d{8,9}$/).test(num);
}

export function validateLandlineNumberChannelMgt(
	num: string | null | undefined,
	index?: number
): boolean {
	if (!num || num.trim() === '' || (index && index >= 25)) {
		return false;
	}
	return /^(?:\d{7,11}|02\d{8,9})$/.test(num);
}

export function validateContactNumber(num: string): boolean {
	return validateMobile(num) || validateLandlineNumber(num);
}

export function validateDateTo(
	dateFrom?: string,
	dateTo?: string
): string | null {
	if (dateFrom) {
		return dateTo && new Date(dateTo) < new Date(dateFrom)
			? "You've selected invalid date"
			: null;
	}
	return null;
}

export function validateAlphaNumericWithSpecialChars(
	value?: string
): string | null {
	if (value) {
		return value.replace(/[a-zA-Z0-9-_]+/g, '').length !== 0
			? 'Invalid value'
			: null;
	}

	return null;
}

export function validateDecimal(value?: string): string | null {
	if (value) {
		const alphaAndSym = value.replace(/[0-9.]+/g, '');
		if (alphaAndSym.length === 0) {
			const [main, dec] = value.split('.');
			if (main.length < 7) {
				if (!dec || dec.length < 3) {
					return null;
				}
			}
		}
		return 'Invalid value';
	}
	return null;
}

export function isTransactionIsDisabled(
	allFields?: Record<string, FixMeLater>,
	dropdown?: FixMeLater
): boolean {
	if (allFields) {
		for (const key of Object.keys(allFields)) {
			if (allFields[key] != null && allFields[key] !== '') {
				return false;
			}
		}

		if (dropdown.status >= 0 && dropdown.status !== null) {
			return false;
		}

		if (dropdown.apiVersion) {
			return false;
		}
	}
	return true;
}
/*
 * @info [https://emailregex.com/]source for valid email addreses base on the official RFC 5322
 * /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
 **/
export function validateEmail(email: string): boolean {
	return new RegExp(
		// /^(?!.*[-.]{2})[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9]+)*@(?!-)(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
		/^(?!.*[-.]{2})[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9]+)*\+?[a-zA-Z0-9_]*@(?!-)(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
	).test(email);
}
export function validateMobile(num: string, index?: number): boolean {
	if (index && index >= 19) {
		return false;
	}

	if (num.match(/^09\d{9}$/)) {
		num = '+63' + num.slice(1);
	}

	return new RegExp(/^(\+?639)\d{9}$/).test(num);
}

export function validateNumberLengthBetween(
	num: string,
	countFrom: number,
	countTo: number
): boolean {
	return new RegExp(`^\\d{${countFrom},${countTo}}$`).test(num);
}

export function validateDateRange(
	dateFrom?: string,
	dateTo?: string
): string | null {
	if (dateTo && dateFrom) {
		const fromIsgreater = new Date(dateFrom) > new Date(dateTo);
		const toIsgreater = new Date(dateTo) < new Date(dateFrom);

		return dateFrom && (fromIsgreater || toIsgreater)
			? "You've selected invalid date"
			: null;
	}
	return null;
}

type DateType = string | null;

export function validateDateRangeFrom(
	dateFrom?: DateType,
	dateTo?: DateType
): DateType {
	if (dateFrom) {
		if (dateFrom > dateTo) {
			return "You've selected invalid date";
		}
		if (!dateTo && dateFrom > moment().format('YYYY-MM-DD')) {
			return "You've selected invalid date";
		}
		return null;
	}
	return null;
}

export const getDateValue = (date: DateType): any =>
	date !== undefined ? moment(date) : null;
export const setDateValue = (date: DateType): DateType =>
	date !== 'Invalid date' ? date : null;
