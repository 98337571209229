import React, { useEffect, useState } from 'react';

import Table from 'components/CWSTable/Table';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';

import client from 'helpers/ApiClient';
import { useErrorModal, useLoader, useTablePagination } from 'utils/hooks';

import { TableTabProps, FilterOptions, Response, Data } from '../types';
import styles from '../TransactionMgt.module.css';

const TableTab: React.FC<TableTabProps> = ({ columns, apiRoute }) => {
	const [data, setData] = useState<Data>();

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const fetchTableData = async (params?: FilterOptions) => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const result: Response = await client.get(apiRoute, { params });

			setData(result?.data);
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => fetchTableData(params)
			);
		} finally {
			hideLoading();
		}
	};

	const tablePaginationProps = useTablePagination(fetchTableData, data?.meta);

	useEffect(() => {
		fetchTableData();
	}, []);

	return (
		<>
			<Table
				columns={columns}
				data={data?.data}
				isNotEvenRowColor
				preHeader={null}
				modalIsOpen={false}
				className={styles.table}
				scrollable={true}
				{...tablePaginationProps}
			/>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideErrorModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
		</>
	);
};

export default TableTab;
