import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { Tooltip } from '@salesforce/design-system-react';
import Switch from './Switch';
import styles from './Switch.module.css';

type SwitchProps = {
	name: string;
	checked: boolean;
	onChange: (data: boolean) => void;
	toolTipContent: string;
	className?: string;
	isPointerDisabled?: boolean;
};

const SwitchWithTooltip: React.FC<SwitchProps> = ({
	name,
	checked,
	onChange,
	toolTipContent,
	className,
	isPointerDisabled = false,
}) => {
	const [tooltipAlignment, setTooltipAlignment] = useState('bottom');
	const ref = useRef<any>(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries?.forEach((entry) => {
					if (entry?.isIntersecting) {
						const elementPosition = entry?.boundingClientRect;

						if (elementPosition?.top > 450) setTooltipAlignment('top');
						else setTooltipAlignment('bottom');
					}
				});
			},
			{ threshold: 0.5 }
		);

		observer.observe(ref?.current);

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<Tooltip
			content={toolTipContent}
			align={tooltipAlignment}
			dialogClassName={cx(className, styles.tooltip)}
			toolTipContent={toolTipContent}
		>
			<a ref={ref}>
				<Switch
					isPointerDisabled={isPointerDisabled}
					name={name}
					checked={checked}
					onChange={onChange}
				/>
			</a>
		</Tooltip>
	);
};

export default SwitchWithTooltip;