import { InputIcon } from '@salesforce/design-system-react';
import AmountField from 'components/Inputs/TextField/AmountField';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import {
	Control,
	useWatch,
	UseFormGetValues,
	UseFormSetValue,
} from 'react-hook-form';

import { ReactComponent as PercentIcon } from 'assets/icons/ic-percent.svg';
import NumberFormatter from 'components/Format/NumberFormatter';
import { strToNumber } from 'utils/common';
import { useController } from 'react-hook-form';
import { FixMeLater } from 'types';

type ConditionalObj = {
	id: string;
	headerName: string;
};

export type CondtionalColumnReturnType = {
	subsidized: ConditionalObj;
	passOn: ConditionalObj;
	total: ConditionalObj;
	nonShareable: {
		id: ConditionalObj['id'];
		headerName: ConditionalObj['headerName'];
		value: string;
		isPercentage: boolean;
	};
};

export const useConditionalColumn = ({
	control,
}): CondtionalColumnReturnType => {
	const nonShareableRateType = useWatch({
		control,
		name: 'non_shareable_rate_type',
	});
	const schemeType = useWatch({
		control,
		name: 'scheme_type',
	});
	const tierRateType = useWatch({
		control,
		name: 'tier_rate_type',
	});
	const specialRateType = useWatch({
		control,
		name: 'special_rate_type',
	});

	const isPercentage =
		nonShareableRateType === 'PERCENTAGE' ||
		schemeType === 'PERCENTAGE' ||
		tierRateType === 'PERCENTAGE' ||
		specialRateType === 'PERCENTAGE';

	const conditionalReturnValues: CondtionalColumnReturnType = {
		subsidized: {
			id: `${isPercentage ? 'subsidized_rate' : 'subsidized_fee'}`,
			headerName: `Subsidized ${isPercentage ? 'Rate' : 'Fee'}`,
		},
		passOn: {
			id: `${isPercentage ? 'pass_on_rate' : 'pass_on_fee'}`,
			headerName: `Pass-on ${isPercentage ? 'Rate' : 'Fee'}`,
		},
		total: {
			id: `${isPercentage ? 'total_percentage_rate' : 'total_fixed_rate'}`,
			headerName: `Total ${isPercentage ? 'Percentage Rate' : 'Fixed Rate'}`,
		},
		nonShareable: {
			id: `${isPercentage ? 'non_share_percentage' : 'non_share_fixed_rate'}`,
			headerName: `Non-Shareable ${isPercentage ? 'Percentage' : 'Fixed Rate'}`,
			value: nonShareableRateType,
			isPercentage,
		},
	};

	return { ...conditionalReturnValues };
};

export const TotalRateFee: React.FC<{
	idName: string;
	name: string;
	control: Control<any>;
	subsidizedName: string;
	passOnName: string;
	isPercentage?: boolean;
	disabled?: boolean;
	action?: string;
}> = ({
	idName,
	name,
	subsidizedName,
	passOnName,
	control,
	isPercentage,
	disabled,
	action,
}) => {
	// const subsidized = useWatch({ control, name: `${name}.${subsidizedName}` });
	// const passOn = useWatch({ control, name: `${name}.${passOnName}` });

	const { field } = useController({ name: `${name}.${idName}`, control });

	// useEffect(() => {
	// 	if (!disabled && action !== 'VIEW') {
	// 		console.log('total sf changed');
	// 		field && field.onChange(strToNumber(subsidized) + strToNumber(passOn));
	// 	}
	// }, [subsidized, passOn]);

	const fommaterProps: any = {
		value: field.value,
		decimalPlaces: 2,
		currency: isPercentage ? '%' : undefined,
		currencyLocation: 'end',
		disabled,
		hasThousandsSeparator: true,
	};

	return (
		<>
			<NumberFormatter {...fommaterProps} />
		</>
	);
};

type ServiceFeeFieldType = {
	control: Control<any>;
	name: string;
	placeholder?: string;
	nonShareableColumn: CondtionalColumnReturnType['nonShareable'];
	disabled?: boolean;
	onChange?: (v?: FixMeLater) => FixMeLater;
	getValues?: UseFormGetValues<any>;
	setValue?: UseFormSetValue<any>;
	conditionalColumn?: CondtionalColumnReturnType;
	isAmountField?: boolean;
	isPercentage?: boolean;
	isFieldTable?: boolean;
	defaultValue?: string;
};

export const ServiceFeeField: React.FC<ServiceFeeFieldType> = ({
	name,
	control,
	placeholder,
	nonShareableColumn,
	getValues,
	setValue,
	conditionalColumn,
	isAmountField = false,
	isPercentage = false,
	isFieldTable = false,
	defaultValue,
	...props
}) => {
	const fommaterProps: any = {
		value: 0,
		decimalPlaces: 2,
		currency: isPercentage ? '%' : undefined,
		currencyLocation: 'end',
	};

	return isAmountField ? (
		<AmountField
			label=""
			isFieldTable={isFieldTable}
			placeholder={placeholder}
			control={control}
			name={name}
			fullWidth
			defaultValue={defaultValue}
			formatValue={TextFieldFormatter.amountFormat}
			isNoDashAllowed
			iconRight={
				nonShareableColumn.isPercentage && (
					<InputIcon
						assistiveText={{
							icon: 'none',
						}}
						name="percent"
						category="utility"
					>
						<PercentIcon />
					</InputIcon>
				)
			}
			onChange={({ target: { value: v = NaN } }) => {
				const isColAndFunctionsRequiredExists =
					conditionalColumn && getValues && setValue;

				// don't compute if field is non-shareable
				if (name.includes('non_share') || !isColAndFunctionsRequiredExists)
					return;

				const schemeName = (() => {
					// get the scheme name
					if (name && ['regular, percentage'].includes(name))
						return name.split('.')[0];

					// getting the scheme name (tier/special) with array index
					const fieldNameSplit = name.split('.');
					fieldNameSplit.pop(); // removes the last index
					return fieldNameSplit.join('.');
				})();

				// compute against counterpart field
				const fieldAgainstAdd = name.includes('pass_on')
					? 'subsidized'
					: 'passOn';

				const passOnOrSubsidizedValue =
					getValues?.(
						`${schemeName}.${conditionalColumn[fieldAgainstAdd].id}`
					) ?? 0;
				const total =
					strToNumber(v) + strToNumber(passOnOrSubsidizedValue) ?? 0;
				setValue?.(`${schemeName}.${conditionalColumn.total.id}`, total);
			}}
			{...props}
		/>
	) : (
		<NumberFormatter {...fommaterProps} />
	);
};
