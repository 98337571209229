import cx from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import ServiceFeeErrorModal, {
	ServiceFeeErrorModalActions,
	ServiceFeeErrorModalBody,
} from 'components/Modal/ServiceFeeErrorModal';
import customModalStyles from 'containers/ChannelManagement/Channel/ChannelProducts/ChannelProductsModal/ChannelProductModal.module.css';
import { BASE_PATH } from 'containers/ChannelManagement/Channel/ChannelProducts/const';
import { ProductScrollableListProps } from 'containers/ChannelManagement/Channel/ChannelProducts/types';
import HTTP from 'helpers/ApiClient';
import React, {
	ChangeEvent,
	ReactFragment,
	useEffect,
	useMemo,
	useState,
	ReactNode,
} from 'react';
import { useToggle } from 'utils/hooks';
import { useHasUserPermission } from 'utils/permissions';
import { useDispatch } from 'react-redux';
import { showAccessDeniedModal } from 'redux/modules/access';
import ChannelProductModal from '../../ChannelProductsModal/ChannelProductModal';
import scrollableListStyles from './ProductScrollableList.module.css';
import ToggleButton from './ToggleButton';
import SuccessModal from 'containers/ChannelManagement/Branch/BranchForm/SuccessModal/SuccessModal';
import { FixMeLater } from 'types';

const ProductScrollableList: React.FC<ProductScrollableListProps> = ({
	name,
	channel,
	activePage,
}) => {
	const shouldRenderProducts = Boolean(channel?.products?.length);
	const [isRemarksEmpty, setIsRemarksEmpty] = useState<boolean>(true);
	const [showEnableModal, setshowEnableModal] = useState(false);
	const [showDisableModal, setshowDisableModal] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [isEnable, setIsEnable] = useState(true);
	const [productIndex, setProductIndex] = useState(-1);
	const [remarks, setRemarks] = useState('');
	const [activeProduct, setActiveProduct] = useState();
	const [errorRemarks, setErrorRemarks] = useState({
		hasError: false,
		status: '',
	});
	const [products, setProducts] = useState<any[]>([]);
	const [enableOptions, setEnableOptions] = useState<string>('');
	const [optionsError, setOptionsError] = useState<ReactFragment>();
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	useEffect(() => {
		setProducts(channel.products);
	}, [channel.products]);

	const hasUserPermission = useHasUserPermission('channels');

	const handleToggleOnChange = (
		index: number,
		e: ChangeEvent<HTMLInputElement>
	) => {
		const updatedProducts = [...products];
		updatedProducts[index].enabled = e.target.checked;
		setProducts(updatedProducts);
	};

	const styles = {
		...scrollableListStyles,
		...customModalStyles,
	};

	const { hasEditPermission } = useMemo(() => {
		return {
			hasEditPermission: hasUserPermission('edit'),
		};
	}, [hasUserPermission]);

	const syntheticEvent = (status: boolean) => {
		return {
			target: {
				checked: status,
			},
		} as ChangeEvent<HTMLInputElement>;
	};

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const handleChangeOfRemarks = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setIsRemarksEmpty(
			/^\s+$/.test(event.target.value) || event.target.value === ''
		);
		setRemarks(event.target.value);
	};

	const dispatch = useDispatch();

	const handleToggleOnClick = (index: number) => {
		if (!products[index].enabled) {
			if (!hasUserPermission('enable.product')) {
				dispatch(showAccessDeniedModal());
				return;
			} else {
				setProductIndex(index);
				setshowEnableModal(true);
				// setIsEnable(true);
			}
		} else {
			if (!hasUserPermission('disable.product')) {
				dispatch(showAccessDeniedModal());
				return;
			} else {
				setProductIndex(index);
				setshowDisableModal(true);
				// handleToggleOnChange(index, syntheticEvent(products[index].enabled));
				// setIsEnable(!isEnable);
			}
		}
	};
	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const enableDisable = async (productType: number, isEnable: boolean) => {
		const action = isEnable ? 'enable' : 'disable';
		showLoadingMessage(`Please wait while we mark the product as ${action}`);
		const params = { remarks: remarks }; // { remarks: remarks, option: enableOptions}

		await HTTP.put(
			BASE_PATH + `${channel.data.id}/products/types/${productType}/${action}`,
			params
		)
			.then(() => {
				setActiveProduct(products[productIndex]);
				showSuccessModal();
				handleToggleOnChange(productIndex, syntheticEvent(isEnable));
			})
			.catch((error) => {
				showErrorMessage(error?.response?.data?.message);
				setActiveProduct(products[productIndex]); // for retry purpose
				handleToggleOnChange(productIndex, syntheticEvent(!isEnable));
			})
			.finally(() => {
				hideLoading();
			});
	};

	const showErrorMessage = (message: any, onDoneBtnClick?: () => void) => {
		setErrorMessage(message);
		showServiceFeeError();
		// setDoneBtnOnClick({
		// 	action: () => {
		// 		hideSuccessModal();
		// 		onDoneBtnClick && onDoneBtnClick();
		// 	},
		// });
	};
	const handleConfirmBtnClick = () => {
		if (showEnableModal) {
			// if (enableOptions !== '') {
			checkRemarks(remarks);
			enableDisable(products[productIndex].id, true);
			hideModal();
			setshowEnableModal(!showEnableModal);
			setOptionsError(undefined);
			setIsEnable(true);
			// } else {
			// 	setOptionsError(
			// 		<span className={styles.radioOptionsLabel}>Please select option</span>
			// 	);
			// }
		} else {
			checkRemarks(remarks);
			enableDisable(products[productIndex].id, false);
			hideModal();
			setshowDisableModal(!showDisableModal);
			setIsEnable(false);
		}
	};
	const checkRemarks = (remarks: string) => {
		if (remarks.length === 0) {
			setErrorRemarks({
				hasError: true,
				status: 'Please input remarks.',
			});
			return;
		}
	};
	const handleCancelBtnClick = () => {
		hideModal();
		// handleToggleOnChange(productIndex, syntheticEvent(!activeProduct?.enabled));
		showEnableModal ? setshowEnableModal(false) : setshowDisableModal(false);
		setProductIndex(-1);
	};

	const hideModal = () => {
		setRemarks('');
		setIsRemarksEmpty(true);
		setEnableOptions('');
		setOptionsError(undefined);
		setErrorRemarks({ hasError: false, status: '' });
	};

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isErrorRetryShowing,
		valueOn: showErrorRetry,
		valueOff: hideErrorRetry,
	} = useToggle();

	const {
		value: isServiceFeeError,
		valueOn: showServiceFeeError,
		valueOff: hideServiceFeeError,
	} = useToggle();

	const handleClickRetry = () => {};

	const nextPage = (action: boolean) => {
		if (action) {
			// activePage(false, products[productIndex]);
			setActiveProduct(undefined);
			setProductIndex(-1);
			hideSuccessModal();
		} else {
			setProductIndex(-1);
			hideSuccessModal();
		}
	};

	const handleEnableOption = (value) => {
		setEnableOptions(value);
		setOptionsError(undefined);
	};

	const handleNameClick = (productType) => {
		activePage(false, productType);
	};

	return shouldRenderProducts ? (
		<div className={styles.scrollableContainer}>
			{products &&
				products.map((type, index) => (
					<div key={index} className={styles.productType}>
						<div className={styles.displayRow}>
							<div className={styles.displayColumn}>
								<a
									className={styles.productTypeLink}
									onClick={() => handleNameClick(type)}
								>
									{type.name}
								</a>
							</div>
							<div className={styles.displayColumn}>
								<div className={cx('slds-clearfix', styles.toggleButton)}>
									<div className="slds-float_right">
										<ToggleButton
											isDisabled={
												channel.data.status !== 'DEACTIVATED' &&
												hasEditPermission
											}
											isChecked={products[index].enabled}
											id={index}
											onClickBtn={handleToggleOnClick}
											onChangeBtn={() => null}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			{showEnableModal && (
				<ChannelProductModal
					isOpen={true}
					onClose={handleCancelBtnClick}
					isDisabled={isLoading}
					heading={<h2 className={styles.customHeading}>Enable Product?</h2>}
					message={
						<h2 className={styles.bodyHeader}>
							Are you sure you want to enable{' '}
							<b>{products[productIndex].name}</b>?
						</h2>
					}
					isPrimaryBtnDisabled={isRemarksEmpty}
					cancelBtnLabel="Cancel"
					confirmBtnLabel="Confirm"
					onCancelBtnClick={handleCancelBtnClick}
					onConfirmBtnClick={handleConfirmBtnClick}
					showRemarks
					error={errorRemarks}
					handleChangeOfRemarks={handleChangeOfRemarks}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
					// getOption={handleEnableOption}
					// retainLabel="Channels"
					// cascadeLabel="Channel Partners, Branches, and TPAIDs"
					// errorOptions={optionsError}
					// hasRadioGroup
				/>
			)}

			{showDisableModal && (
				<ChannelProductModal
					isOpen={true}
					onClose={handleCancelBtnClick}
					isDisabled={isLoading}
					heading={<h2 className={styles.customHeading}>Disable Product?</h2>}
					message={
						<h2 className={styles.bodyHeader}>
							Are you sure you want to disable{' '}
							<b>{products[productIndex].name}</b>?
						</h2>
					}
					isPrimaryBtnDisabled={isRemarksEmpty}
					cancelBtnLabel="Cancel"
					confirmBtnLabel="Confirm"
					onCancelBtnClick={handleCancelBtnClick}
					onConfirmBtnClick={handleConfirmBtnClick}
					showRemarks
					error={errorRemarks}
					handleChangeOfRemarks={handleChangeOfRemarks}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
				/>
			)}
			<>
				{isLoading && (
					<FullPageLoader open={isLoading} message={loadingMessage} />
				)}
				<SuccessModal
					open={isSuccessModalOpen}
					onClose={hideSuccessModal}
					successHeader={
						<div>Successfully {isEnable ? 'Enabled!' : 'Disabled!'}</div>
					}
					successMessage={
						<div>
							<b>{activeProduct && (activeProduct as FixMeLater)?.name}</b> is
							now {isEnable ? 'enabled.' : 'disabled.'}
						</div>
					}
					onOkay={() => {
						nextPage(isEnable);
					}}
					okayLabel="Done"
				></SuccessModal>
				<ErrorModal open={isErrorRetryShowing} onClose={hideErrorRetry}>
					<ErrorModalBody className={styles.errorBody}>
						<div className={styles.errorHeader}>
							<b>Timeout Error!</b>
						</div>
						<div className={styles.errorText}>
							<div>A problem occured while saving the data.</div>
							<div>Please try again.</div>
						</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							className={styles.contactDetailsErrorCloseBtn}
							onClick={() => {
								hideErrorRetry();
								handleClickRetry();
							}}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
				<ServiceFeeErrorModal
					open={isServiceFeeError}
					onClose={hideServiceFeeError}
				>
					<ServiceFeeErrorModalBody className={styles.errorBody}>
						<div
							className={styles.errorHeader}
							style={{
								fontSize: '18px',
								maxWidth: '210px',
								marginBottom: '43px',
							}}
						>
							{errorMessage}
						</div>
					</ServiceFeeErrorModalBody>
					<ServiceFeeErrorModalActions>
						<PrimaryButton
							className={styles.contactDetailsErrorCloseBtn}
							onClick={() => {
								hideServiceFeeError();
							}}
						>
							Okay
						</PrimaryButton>
					</ServiceFeeErrorModalActions>
				</ServiceFeeErrorModal>
			</>
		</div>
	) : null;
};
export default ProductScrollableList;
