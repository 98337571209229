import React, { useEffect, useState } from 'react';
import styles from 'containers/ChannelManagement/Terminal/Tab/style.module.css';
import ConfirmationModal from 'containers/ChannelManagement/Terminal/Modal/ConfirmationModal';
import { useToggle } from 'utils/hooks';
import { ReactComponent as EditIcon } from 'assets/icons/ic-edit.svg';
import { useTerminal } from 'containers/ChannelManagement/Terminal/hooks';
import { Button } from '@salesforce/design-system-react/module/components';
import { TerminalDetailsProps } from 'containers/ChannelManagement/Terminal/types';
import StatusUpdate from 'containers/ChannelManagement/Channel/ChannelStatusSelect/StatusUpdate';
import { FormProvider, useForm } from 'react-hook-form';
import { terminalDetails as terminalSchema } from 'containers/ChannelManagement/Terminal/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { TerminalDetails as TerminalType } from 'containers/ChannelManagement/Terminal/types';
import TerminalForm2 from 'containers/ChannelManagement/Terminal/Form/TerminalForm';
import AccessDeniedModal from 'containers/ChannelManagement/Permission/AccessDeniedModal';
import { useList } from 'containers/ChannelManagement/List/hooks';
import moment from 'moment';
import { useTerminalPermission } from 'containers/ChannelManagement/Permission/hooks';
import { showAccessDeniedModal } from 'redux/modules/access';
import { useDispatch } from 'react-redux';
import { setLoader } from 'redux/modules/channelList';

const TerminalDetails: React.FC<TerminalDetailsProps> = ({
	refetchTerminal,
	terminalId,
	isDisabled,
	setIsDisabled,
	setIsDirty,
	isTerminalError,
}) => {
	const dispatch = useDispatch();

	const {
		hasViewTerminalPermission,
		hasEditStatusTerminalPermission,
		hasEditTerminalPermission,
	} = useTerminalPermission();

	const {
		value: isConfirmModalShowing,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

	const {
		terminalListQuery: { refetch: refetchTerminalList, isFetching },
		loader,
	} = useList();

	const { terminalDetails, terminalFormValues } = useTerminal();

	const TerminalDetailsUseFormMethods = useForm<TerminalType>({
		mode: 'all',
		resolver: yupResolver(terminalSchema),
		defaultValues: terminalFormValues,
	});

	const handleExitMode = () => {
		setIsDisabled?.(!isDisabled);
		hideConfirmModal();
	};

	const confirmModalBodyText = (
		<>
			{!TerminalDetailsUseFormMethods.formState.isDirty ? (
				<>
					<br />
					<br />
					Are you sure you want to exit edit mode?
				</>
			) : (
				<>
					<br />
					Are you sure you want to exit without saving?
					<br />
					Any unsaved work will be lost.
				</>
			)}
		</>
	);
	useEffect(() => {
		setIsDisabled?.(false);
	}, [terminalDetails?.id]);
	useEffect(() => {
		setIsDirty?.(TerminalDetailsUseFormMethods.formState.isDirty);
	}, [TerminalDetailsUseFormMethods.formState.isDirty]);
	return (
		<>
			{hasViewTerminalPermission && (
				<div className={styles.container}>
					<div className={styles.header}>
						<div className={styles.leftPanel}>
							<div className={styles.title}>
								{terminalDetails?.tpaId ? terminalDetails?.tpaId : 'TPAID'}
							</div>
							<div className={styles.status}>
								<div className={styles.statusLabel}>Terminal Status:</div>
								<div
									className={styles.statusField}
									onClick={() => {
										if (!hasEditStatusTerminalPermission) {
											dispatch(showAccessDeniedModal());
										}
									}}
								>
									<StatusUpdate
										value={terminalFormValues?.data?.status || ''}
										id={terminalId}
										idType="TPAID"
										onClose={(success) => {}}
										refetch={() => {
											refetchTerminalList();
											refetchTerminal?.();
										}}
									/>
								</div>
							</div>
						</div>
						<div className={styles.rightPanel}>
							<div className={styles.edit}>
								<Button
									className={styles.linkBtn + ' ' + styles.edit}
									onClick={() => {
										hasEditTerminalPermission
											? !isDisabled
												? setIsDisabled?.(!isDisabled)
												: showConfirmModal()
											: dispatch(showAccessDeniedModal());
									}}
								>
									<EditIcon />
									{!isDisabled ? ' Edit Terminal Details' : 'Exit Edit Mode'}
								</Button>
							</div>
							<div className={styles.dates}>
								<em className={styles.dateText}>
									{'Last updated at ' +
										moment
											.utc(terminalFormValues?.data?.updatedAt)
											.format('MM/DD/YYYY hh:mm:ss A')}
									{' by ' + terminalFormValues?.data?.updatedBy}
								</em>
							</div>
						</div>
					</div>
					<div className={styles.body}>
						<FormProvider {...TerminalDetailsUseFormMethods}>
							{!loader.isLoading && (
								<TerminalForm2
									disabled={!isDisabled}
									refetchTerminal={refetchTerminal}
									mode="EDIT"
								/>
							)}
						</FormProvider>
					</div>
				</div>
			)}
			{isConfirmModalShowing && (
				<ConfirmationModal
					open={isConfirmModalShowing}
					onClose={hideConfirmModal}
					heading="Exit Edit Mode"
					body={confirmModalBodyText}
					cancelButton="Cancel"
					submitButton="Confirm"
					handleSubmit={handleExitMode}
				/>
			)}
		</>
	);
};

export default TerminalDetails;
