import {
	Input,
	Textarea,
} from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import { isANumber } from 'components/Format/NumberFormatter';
import Grid from 'components/Grid/Grid';
import _ from 'lodash';
import React, { ReactNode, useMemo, useRef, useState } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import Label from '../Label/Label';
import textFieldStyles from './TextField.module.css';
import successModalStyles from 'components/Modal/SuccessModal.module.css';
import errorModalStyles from 'components/Modal/ErrorModal.module.css';
import { useToggle } from 'utils/hooks';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import { convertFromBytes } from 'utils/utils';
import HTTP from '../../../helpers/ApiClient';
import axios from 'axios';
import { ReactComponent as RetryIcon } from 'assets/icons/ic-refresh.svg';

const styles = {
	...textFieldStyles,
	...successModalStyles,
	...errorModalStyles,
};

type Props = {
	label: string;
	placeholder?: string;
	error?: string;
	className?: string;
	labelClassName?: string;
	formatValue?: (value: any) => any;
	required?: boolean;
	fullWidth?: boolean;
	optional?: boolean;
	readOnly?: boolean;
	disabled?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value?: any;
	defaultValue?: any;
	name?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange?: (value: string) => void;
	multiLine?: boolean;
	inputRef?: any;
	id?: any;
	resizable?: boolean;
	onBlur?: (e) => void;
	onKeyPress?: (e) => void;
	onFocus?: () => any;
	rows?: number;
	autoFocus?: boolean;
	iconLeft?: any;
	iconRight?: any;
	type?: string;
	fixedTextLeft?: string;
	fixedTextRight?: string;
	maxLength?: number;
	maxValue?: number;
	hasSpinner?: boolean;
	numericCharactersOnly?: boolean;
	isRetry?: boolean;
	onHandleRetry?: () => any;
	isFieldTable?: boolean;
};

export const ErrorText = ({ error }): any => {
	error ? (
		<div className="slds-has-error">
			<div className={cx(styles.helper, 'slds-form-element__help')}>
				{error?.message}
			</div>
		</div>
	) : (
		<></>
	);
};
export const TextFieldFormatter = {
	commaSeparated: (v: any) => {
		try {
			const sanitized = v.replaceAll(',', '');
			if (!isANumber(sanitized)) return v;
			else if (-sanitized)
				return sanitized
					.split('')
					.reverse()
					.join('')
					.replace(/(\d{3}\B)/g, '$1,')
					.split('')
					.reverse()
					.join('');
		} catch (error) {
			return v;
		}
	},
	decimalPlaces:
		(decimalPlaces = 2) =>
		(v: any) => {
			const allNum = v.replaceAll(',', '').replace(/[^\d.-]/g, '');

			const res = new Intl.NumberFormat('en-US', {
				minimumFractionDigits: decimalPlaces,
				maximumFractionDigits: decimalPlaces,
			}).format(allNum);
			return res;
		},
};

const PlainTextField: React.FC<Props> = ({
	label,
	placeholder,
	error,
	className = '',
	labelClassName = '',
	required,
	fullWidth = true,
	optional = false,
	readOnly = false,
	disabled = false,
	value,
	name,
	onChange,
	multiLine,
	formatValue,
	defaultValue,
	inputRef = null,
	id,
	onBlur,
	onKeyPress,
	onFocus,
	resizable = false,
	rows = 1,
	numericCharactersOnly,
	isFieldTable,
	...rest
}) => {
	const inputProps = {
		name,
		inputRef,
		placeholder: placeholder !== undefined ? placeholder : label,
		errorText: error,
		className: cx(
			styles.input,
			{
				[styles.error]: !!error,
				[styles.multiLine]: multiLine,
				[styles.tableInput]: isFieldTable,
			},
			className
		),
		value,
		readOnly,
		onChange: (event: any, target?: { value?: any }) => {
			const v = multiLine ? event.target.value : target?.value;
			onChange && onChange(v || '');
		},
		id,
		onBlur,
		...rest,
	};

	const formattedValue = useMemo(() => {
		if (formatValue) {
			let _val;
			try {
				_val = formatValue(value);
				// onChange && onChange(_val);
				return _val;
			} catch (error) {
				return value;
			}
		}

		return value;
	}, [value]);

	const _allowedKeyCodes = [189, 190, 8, 37, 39, 9];
	const _allowedShiftKeyCodes = [37, 39];
	const _allowedControlKeyCodes = [86, 88, 67, 9];

	return (
		<div className={cx({ [styles.fullWidth]: fullWidth })}>
			{label && (
				<Label
					required={required}
					optional={optional}
					className={labelClassName}
				>
					{label}
				</Label>
			)}
			{multiLine ? (
				<>
					<textarea
						{...inputProps}
						className={cx(
							inputProps.className,
							'slds-input',
							!resizable && styles.textareaNonResizable
						)}
						defaultValue={defaultValue}
						disabled={disabled}
						value={formattedValue}
						rows={
							(formattedValue?.split('\n').length > rows
								? formattedValue?.split('\n').length
								: rows) || 1
						}
						onFocus={(e) =>
							e.currentTarget.setSelectionRange(
								e.currentTarget.value.length,
								e.currentTarget.value.length
							)
						}
					/>
					{error && (
						<div
							className={cx({
								'slds-has-error': !!inputProps.errorText,
							})}
						>
							<div className={cx(styles.helper, 'slds-form-element__help')}>
								{error}
							</div>
						</div>
					)}
				</>
			) : (
				<Input
					{...inputProps}
					defaultValue={defaultValue}
					value={formattedValue}
					disabled={disabled}
					id={id}
					onBlur={(e) => onBlur && onBlur(e)}
					onKeyPress={() => onKeyPress && onKeyPress(event)}
					onFocus={(e) => onFocus && onFocus()}
					onChange={(e) => {
						onChange && onChange(e);
					}}
					onKeyDown={(e) => {
						if (
							numericCharactersOnly &&
							!(
								(e.shiftKey &&
									_allowedShiftKeyCodes.find((v) => v == e.keyCode) !=
										undefined) ||
								(e.ctrlKey &&
									_allowedControlKeyCodes.find((v) => v == e.keyCode) !=
										undefined)
							)
						) {
							if (
								(!(e.keyCode >= 48 && e.keyCode <= 57) &&
									_allowedKeyCodes.find((v) => v == e.keyCode) == undefined) ||
								e.shiftKey ||
								e.ctrlKey
							) {
								e.preventDefault();
							}
						}
					}}
					fixedTextLeft={rest.fixedTextLeft}
					fixedTextRight={rest.fixedTextRight}
				/>
			)}
		</div>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TextFieldProps = Props & {
	control?: Control<any>;
	shouldUnregister?: boolean;
	isOverrideValueProps?: boolean;
	isPercent?: boolean;
};
const TextField: React.FC<TextFieldProps> = ({
	control,
	name,
	defaultValue,
	disabled,
	isPercent,
	shouldUnregister = false,
	...rest
}) => {
	if (control && name) {
		const { value, isOverrideValueProps, isRetry } = rest;
		const { onChange, onBlur, onHandleRetry } = rest;
		return (
			<div className={styles.textFieldContainer}>
				<Controller
					name={name}
					shouldUnregister={shouldUnregister}
					render={(props: any) => {
						const {
							field,
							fieldState: { error },
						} = props;
						const _value = isOverrideValueProps
							? value
							: defaultValue || field.value;
						return (
							<>
								<PlainTextField
									{...rest}
									{...field}
									error={isRetry ? 'Upload Failed' : error?.message}
									onChange={(v) => {
										onChange?.(v);
										field.onChange(v);
									}}
									value={
										isPercent
											? _value
												? `${_value}%`
												: null
											: _value
											? _value
											: null
									}
									disabled={disabled}
									inputRef={field.ref}
									onBlur={(e) => {
										onBlur?.(e);
										field.onBlur();
									}}
									className={isRetry && styles.retryInput}
								/>
								{isRetry && (
									<RetryIcon
										className={styles.retryIcon}
										onClick={onHandleRetry}
									/>
								)}
							</>
						);
					}}
					control={control}
					defaultValue={defaultValue}
				/>
			</div>
		);
	}

	return <PlainTextField name={name} disabled={disabled} {...rest} />;
};

export const UploadField: React.FC<{
	name: string;
	helperText?: string;
	control: Control<any>;
	onChange?: (n) => void;
	setValue: (n, v) => void;
	clearErrors: (n) => void;
	buttonText?: string;
	required?: boolean;
	label?: string;
	fileFormats?: string[];
	disabled?: boolean;
	fileSizeLimitInBytes?: number;
	link?: any;
}> = ({
	name,
	helperText,
	control,
	onChange,
	setValue,
	clearErrors,
	buttonText,
	label,
	link,
	required,
	fileFormats = [],
	disabled,
	fileSizeLimitInBytes = 26214400,
}) => {
	const inputFileRef: any = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	const [errorInstruction, setErrorInstruction] = useState<ReactNode>('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const formats = fileFormats.map((format) => '.' + format).join();
	const fieldTypeFile = () => {
		clearErrors(name);
		setValue(name, undefined);
		setValue('fileName', undefined);
		inputFileRef.current.value = '';
		inputFileRef.current.click();
	};
	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();
	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const showErrorMessage = (
		header: any,
		message: any,
		instruction?: any,
		onRetryBtnClick?: () => void
	) => {
		setErrorHeader(header);
		setErrorMessage(message);
		setErrorInstruction(instruction || 'Please try again.');
		showErrorModal();
		setRetryBtnOnClick({
			action: () => {
				hideErrorModal();
				onRetryBtnClick && onRetryBtnClick();
			},
		});
	};

	const showSuccessMessage = (message: any, onDoneBtnClick?: () => void) => {
		setSuccessMessage(message);
		showSuccessModal();
		setDoneBtnOnClick({
			action: () => {
				hideSuccessModal();
				onDoneBtnClick && onDoneBtnClick();
			},
		});
	};
	return (
		<Controller
			name={name}
			control={control}
			render={(props: any) => {
				const {
					field,
					fieldState: { error },
				} = props;
				return (
					<>
						<input type="hidden" name={name} />
						<Grid column size={1} of={1}>
							<TextField
								label={label || ''}
								name={name + '_fileName'}
								className={styles.uploadField}
								control={control}
								placeholder="No file selected"
								readOnly
								required={required}
								value={onChange && onChange(name + '_fileName')}
								disabled={disabled}
								hasSpinner={isLoading}
							/>
							<span style={{ fontStyle: 'italic', opacity: 0.6 }}>
								{helperText}
							</span>
						</Grid>
						<Grid column className={styles.alignCenter} size={1} of={4}>
							<input
								disabled={disabled}
								type="file"
								ref={inputFileRef}
								hidden
								onChange={({ target: { files = [] } }) => {
									const file = (files ? files[0] : null) as File;
									if (!file) {
										return;
									}
									if (file.size > fileSizeLimitInBytes) {
										showErrorMessage(
											'File Size Error!',
											'The maximum file size is accepted is ' +
												convertFromBytes(fileSizeLimitInBytes, 'MB') +
												'MB.',
											'Please select a different file.'
										);
										return;
									}
									if (
										fileFormats.length > 0 &&
										!fileFormats.includes(file.type.replace(/(.*)\//g, ''))
									) {
										showErrorMessage(
											'File Type Error!',
											'Invalid file type selected.',
											'Please select a different file.'
										);
										return;
									}
									setIsLoading(true);
									const url = link;
									HTTP.get(url, {
										params: {
											fileName: file?.name,
											contentType: file?.type,
										},
									})
										.then((response) => {
											const documentData = response?.data;
											const url = documentData.presigned_keys?.url;
											const formData = new FormData();
											Object.entries(
												documentData.presigned_keys?.fields
											).forEach(([key, value]: any) => {
												formData.append(key, value);
											});
											formData.append('file', file);
											const UNINTERCEPTED_HTTP = axios.create({
												baseURL: process.env.REACT_APP_API_ENDPOINT,
												headers: {
													'Content-Type': 'application/json',
													'x-bayad-platform-id': process.env.REACT_APP_APP_KEY,
												},
											});
											UNINTERCEPTED_HTTP.post(url, formData)
												.then((response) => {
													const url = '/v2/uploads/status';
													HTTP.get(url, {
														params: {
															key: documentData.file_name,
															type: 'product_document',
														},
													})
														.then(({ data }) => {
															setValue(name, documentData.file_name);
															setValue(name + '_fileName', file.name);
															field.onChange(documentData?.file_name);
															onChange && onChange(documentData?.file_name);
															showSuccessMessage('File successfully uploaded');
														})
														.catch((error) => {
															console.log(error);
															showErrorMessage(
																'Timeout Error!',
																'A problem occurred while trying to upload the file'
															);
														})
														.finally(() => {
															setIsLoading(false);
														});
												})
												.catch((error) => {
													console.log(error);
													showErrorMessage(
														'Timeout Error!',
														'A problem occurred while trying to upload the file'
													);
													setIsLoading(false);
												});
										})
										.catch((error) => {
											console.log(error);
											showErrorMessage(
												'Timeout Error!',
												'A problem occurred while trying to upload the file'
											);
											setIsLoading(false);
										});
								}}
								accept={formats || undefined}
							/>
							<OutlineButton
								className={styles.uploadButton}
								fullWidth
								onClick={() => fieldTypeFile()}
								disabled={disabled}
							>
								{buttonText ? buttonText : 'Browse'}
							</OutlineButton>
						</Grid>
						{isSuccessModalOpen && (
							<SuccessModal
								open={isSuccessModalOpen}
								onClose={hideSuccessModal}
							>
								<SuccessModalBody>
									<SuccessText>
										<div className={styles.successHeader}>Success!</div>
										<div className={styles.successBody}>{successMessage}</div>
									</SuccessText>
								</SuccessModalBody>
								<SuccessModalActions>
									<PrimaryButton
										className={styles.successModalBtn}
										onClick={() => {
											doneBtnOnClick.action();
										}}
									>
										Done
									</PrimaryButton>
								</SuccessModalActions>
							</SuccessModal>
						)}
						{isErrorModalOpen && (
							<ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
								<ErrorModalBody>
									<div className={styles.errorHeader}>{errorHeader}</div>
									<div className={styles.errorBody}>{errorMessage}</div>
									<div className={styles.errorFooter}>{errorInstruction}</div>
								</ErrorModalBody>
								<ErrorModalActions>
									<PrimaryButton
										fullWidth
										onClick={() => {
											retryBtnOnClick.action();
										}}
										className={styles.errorModalBtn}
									>
										Retry
									</PrimaryButton>
								</ErrorModalActions>
							</ErrorModal>
						)}
					</>
				);
			}}
		/>
	);
};

export default TextField;
