import Grid from 'components/Grid/Grid';
import { UseFormSetValue, useForm } from 'react-hook-form';
import styles from './FileUploadTable.module.css';
import OutlineButton from 'components/Buttons/OutlineButton';
import { useToggle } from 'utils/hooks';
import { ReactNode, useEffect, useReducer, useRef, useState } from 'react';
import { convertFromBytes, convertToBytes } from 'utils/utils';
import {
	Button,
	DynamicIcon,
} from '@salesforce/design-system-react/module/components';
import HTTP, { client } from '../../helpers/ApiClient';
import axios from 'axios';
import { ReactComponent as CompleteIcon } from 'assets/icons/ic-complete.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ic-error.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/ic-refresh.svg';
import _, { isEmpty } from 'lodash';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ErrorModal, {
	ErrorModalBody,
	ErrorModalActions,
} from 'components/Modal/ErrorModal';
import SuccessModal, {
	SuccessModalBody,
	SuccessText,
	SuccessModalActions,
} from 'components/Modal/SuccessModal';

import { downloadFile, sleep } from 'utils/common';
import { FixMeLater } from 'types';
import { ReactComponent as DownloadIcon } from 'assets/icons/ic-download4.svg';
import { useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import ResponseModal from 'components/Modal/ResponseModal';
import csvIcon from 'assets/icons/ic-csv.svg';
import jpgIcon from 'assets/icons/ic-jpg.svg';
import pngIcon from 'assets/icons/ic-png.svg';
import pdfIcon from 'assets/icons/ic-pdf.svg';
import docsIcon from 'assets/icons/ic-docx.svg';
import FilePreviewModal from 'components/UploadAttachments/FilePreviewModal';

type FileUploadTableItem = {
	label: string;
	name: string;
	file?: string;
	filename?: string;
	fileKey?: string;
	objectKey?: string;
	status?: 'Uploading' | 'Uploaded' | 'Upload Failed' | 'Not Yet Uploaded' | '';
	required?: boolean;
};

const DefaultFileUploadTableItem = {
	filename: '',
	file: '',
	objectKey: '',
	status: '',
	fileKey: '',
};

type FileUploadTableProps = {
	data: FileUploadTableItem[];
	maxFileSize: string;
	maxFileSizeUnit: 'KB' | 'MB' | 'GB';
	fileFormats: string[];
	onChange: (val: any, i: number) => void;
	disabled?: boolean;
	name: string;
	mainSetValue: UseFormSetValue<any>;
	onLoadData?: FixMeLater;
	id?: number;
	inquireType?: string;
	channel?: boolean;
	isChannelDetails?: boolean;
	isDraft?: boolean;
};

export const fileIconExtComponents = {
	csv: csvIcon,
	jpg: jpgIcon,
	jpeg: jpgIcon,
	png: pngIcon,
	docx: docsIcon,
	doc: docsIcon,
	pdf: pdfIcon,
};

const FileUploadTable: React.FC<FileUploadTableProps> = ({
	disabled,
	data,
	onChange,
	maxFileSize = '10',
	maxFileSizeUnit = 'MB',
	fileFormats = ['doc', 'docx', 'pdf'],
	name,
	mainSetValue,
	onLoadData,
	inquireType,
	id,
	isDraft = false,
	channel = false,
	isChannelDetails = false,
}) => {
	const initialValues = { [name]: {} };
	data.forEach((i) => {
		_.set(initialValues[name], `${i.name}`, {
			...i,
			...DefaultFileUploadTableItem,
		} as FileUploadTableItem);
	});

	const {
		setValue,
		getValues,
		formState: { isValid, errors },
	} = useForm<any>({
		defaultValues: initialValues,
	});
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const fields = channel
		? isChannelDetails
			? data
			: getValues(name)
		: getValues(name);
	// eslint-disable-next-line @typescript-eslint/no-array-constructor
	const inputRefs = useRef(new Array());
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	const [errorInstruction, setErrorInstruction] = useState<ReactNode>('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [selectedFile, setSelectedFile] = useState({});
	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();
	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();
	const {
		value: isFileModalOpen,
		valueOn: showFileModal,
		valueOff: hideFileModal,
	} = useToggle();

	const [response, setResponse] = useState({
		isOpen: false,
		icon: null,
		bodyText: '',
		bodyHeader: 'File is being downloaded',
		respondButton: {
			name: 'Done',
			event: () => {
				setResponse({
					...response,
					isOpen: false,
				});
			},
		},
		isRespondButton2Shown: false,
		small: true,
	});

	const productTypeId = useSelector<ReducerStateType>(
		(state) => state.sidebar.itemId
	);

	// load default values
	useEffect(() => {
		if (!isEmpty(onLoadData)) {
			Object.keys(onLoadData).map((key) => {
				const item = (onLoadData[key] as FixMeLater) ?? '';
				if (!item) return;
				if (key === 'product_id' || key === 'id') {
					setValue(`${name}.${key}`, item);
				} else {
					const fileName = item && (item.length < 37 ? item : item?.slice(37));
					setValue(`${name}.${key}.filename`, fileName, {
						shouldValidate: true,
					});
					setValue(`${name}.${key}.fileKey`, item, { shouldValidate: true });
					setValue(`${name}.${key}.status`, 'Uploaded', {
						shouldValidate: true,
					});
				}
			});
		}
	}, [setValue, onLoadData]);

	const handleClearBtnOnClick = (item: FileUploadTableItem) => {
		const setCellValue = {
			file: '',
			filename: '',
			objectKey: '',
			status: '',
		};
		for (const [key, value] of Object.entries(setCellValue)) {
			setValue(`${name}.${item.name}.${key}`, value);
		}
		mainSetValue(
			channel
				? `contractDetails.channelAccreditation.${item}`
				: `${name}.${item}`,
			null
		);

		forceUpdate();
	};
	const handleUpload = (item: FileUploadTableItem, file: File) => {
		const url = `/v2/${channel ? 'channels' : 'products'}/document`;
		if (channel && isChannelDetails) {
			mainSetValue(
				`contractDetails.channelAccreditation.${item.name}`,
				{ filename: file?.name, status: 'Uploading' },
				{
					shouldDirty: false,
				}
			);
		} else {
			setValue(`${name}.${item.name}.status`, 'Uploading', {
				shouldDirty: true,
			});
		}
		HTTP.get(url, {
			params: {
				fileName: file?.name,
				contentType: file?.type,
			},
		})
			.then((response) => {
				const data = response.data;
				const baseData = channel ? data.presigned_key : data.presigned_keys;
				const url = baseData.url;
				const formData = new FormData();
				Object.entries(baseData.fields).forEach(([item, value]: any) => {
					formData.append(item, value);
				});
				formData.append('file', file);
				const UNINTERCEPTED_HTTP = axios.create({
					baseURL: process.env.REACT_APP_API_ENDPOINT,
					headers: {
						'Content-Type': 'application/json',
						'x-bayad-platform-id': process.env.REACT_APP_APP_KEY,
					},
				});
				UNINTERCEPTED_HTTP.post(url, formData)
					.then(async () => {
						await handleInquireUpload(data, item, file);
					})
					.catch((error) => {
						console.log(error);
						if (channel && isChannelDetails) {
							mainSetValue(
								`contractDetails.channelAccreditation.${item.name}`,
								{ filename: file?.name, status: 'Upload Failed' },
								{
									shouldDirty: false,
								}
							);
						} else {
							setValue(`${name}.${item.name}.status`, 'Upload Failed');
						}
						// showErrorMessage(
						// 	'Timeout Error!',
						// 	'A problem occurred while trying to upload the file'
						// );
					})
					.finally(() => {
						forceUpdate();
					});
			})
			.catch((error) => {
				console.log(error);
				if (channel && isChannelDetails) {
					mainSetValue(
						`contractDetails.channelAccreditation.${item.name}`,
						{ filename: file?.name, status: 'Upload Failed' },
						{
							shouldDirty: false,
						}
					);
				} else {
					setValue(`${name}.${item.name}.status`, 'Upload Failed', {
						shouldDirty: true,
					});
				}
				// showErrorMessage(
				// 	'Timeout Error!',
				// 	'A problem occurred while trying to upload the file'
				// );
			})
			.finally(() => {
				forceUpdate();
			});
	};

	const handleInquireUpload = (data, item, file, index = 0) => {
		const url = '/v2/uploads/status';
		const params = {
			key: data.file_name,
			type: inquireType,
			id: id ? id : null,
		};
		HTTP.get(url, { params })
			.then(async (response) => {
				const status = response.data.status;
				if (status === 'SCAN_CLEAN') {
					setValue(`${name}.${item.name}`, {
						name: item.name,
						label: item.label,
						objectKey: data.file_name,
						status: 'Uploaded',
						required: false,
						file: '',
						filename: file.name,
					});
					mainSetValue(
						channel
							? `contractDetails.channelAccreditation.${item.name}`
							: `${name}.${item.name}`,
						data.file_name,
						{
							shouldDirty: true,
						}
					);
				} else {
					await sleep(10000);
					//Check if index is less than to the try limit
					if (index < 5) {
						//add index and call the function again
						index += 1;
						handleInquireUpload(data, item, file, index);
					} else {
						//set fail if limit reached
						if (channel && isChannelDetails) {
							mainSetValue(
								`contractDetails.channelAccreditation.${item.name}`,
								{ filename: file?.name, status: 'Upload Failed' },
								{
									shouldDirty: false,
								}
							);
						} else {
							setValue(`${name}.${item.name}.status`, 'Upload Failed');
						}

						// showErrorMessage(
						// 	'Timeout Error!',
						// 	'A problem occurred while trying to upload the file',
						// 	null,
						// 	async () => {
						// 		//await handle;
						// 	}
						// );
					}
				}
			})
			.catch((error) => {
				console.log('Error Upload:', error);

				if (channel && isChannelDetails) {
					mainSetValue(
						`contractDetails.channelAccreditation.${item.name}`,
						{ filename: file?.name, status: 'Upload Failed' },
						{
							shouldDirty: false,
						}
					);
				} else {
					setValue(`${name}.${item.name}.status`, 'Upload Failed');
				}

				showErrorMessage(
					'Timeout Error!',
					'A problem occurred while trying to upload the file'
				);
			})
			.finally(() => {
				forceUpdate();
			});
	};

	const handleFile = (file: File, item: FileUploadTableItem, i: number) => {
		const currentFileSize = convertFromBytes(file.size, 'MB');
		const maximumFileSize = convertToBytes(+maxFileSize, 'MB');
		if (currentFileSize > +maxFileSize) {
			inputRefs.current[i].value = '';
			showErrorMessage(
				'Failed to Add Attachment/s',
				'You have reached max file size of accepted attachment.',
				' Only ' +
					maxFileSize +
					maxFileSizeUnit +
					' mb per ' +
					'attachment is allowed.'
			);
			return;
		}
		const setCellValue = {
			status: 'Not Yet Uploaded',
			filename: file.name,
		};
		for (const [key, value] of Object.entries(setCellValue)) {
			setValue(`${name}.${item.name}.${key}`, value);
		}
		forceUpdate();
		handleUpload(item, file);
	};

	const showErrorMessage = (
		header: any,
		message: any,
		instruction?: any,
		onRetryBtnClick?: () => void
	) => {
		setErrorHeader(header);
		setErrorMessage(message);
		setErrorInstruction(instruction || 'Please try again.');
		showErrorModal();
		setRetryBtnOnClick({
			action: () => {
				hideErrorModal();
				onRetryBtnClick && onRetryBtnClick();
			},
		});
	};

	const showSuccessMessage = (message: any, onDoneBtnClick?: () => void) => {
		setSuccessMessage(message);
		showSuccessModal();
		setDoneBtnOnClick({
			action: () => {
				hideSuccessModal();
				onDoneBtnClick && onDoneBtnClick();
			},
		});
	};

	return (
		<>
			<div className={styles.fileUploadTable}>
				<div className={styles.fileUploadTableLabelContainer}>
					{maxFileSize ? (
						<div className={styles.fileUploadTableLabelSubtext}>
							<em>*Maximum file size per attachment:</em>
							<span className={styles.fileUploadTableLabelSubtextValue}>
								{maxFileSize}
								{maxFileSizeUnit}
							</span>
						</div>
					) : null}
					<div className={styles.fileUploadTableLabelSubtext}>
						<em>*Accepted files types: </em>
						<span className={styles.fileUploadTableLabelSubtextValue}>
							{fileFormats.map((format, i) =>
								i != fileFormats.length - 1 ? (
									<>.{format}, </>
								) : (
									<>or .{format}</>
								)
							)}
						</span>
					</div>
				</div>
				<Grid container className={styles.fileUploadTableContainer}>
					<Grid column size={1} of={1} className={styles.fileUploadTableBody}>
						<Grid container className={styles.fileUploadTableHeader}>
							<Grid
								column
								size={1}
								of={4}
								className={
									styles.fileUploadTableHeaderItem + ' ' + styles.requirement
								}
							>
								Requirement
							</Grid>
							<Grid
								column
								size={1}
								of={4}
								className={
									styles.fileUploadTableHeaderItem + ' ' + styles.filename
								}
							>
								File Name
							</Grid>
							<Grid
								column
								size={1}
								of={4}
								className={
									styles.fileUploadTableHeaderItem + ' ' + styles.status
								}
							>
								Upload Status
							</Grid>
							<Grid
								column
								size={1}
								of={4}
								className={
									styles.fileUploadTableHeaderItem + ' ' + styles.action
								}
							>
								Action
							</Grid>
						</Grid>
						{fields &&
							Object.keys(fields).map((key, i) => {
								const item = (fields[key] as any) ?? {};
								const formats = fileFormats
									.map((format) => '.' + format)
									.join();

								if (key === 'product_id' || key === 'id') return;

								return (
									<div key={i} className={styles.fileUploadTableRow}>
										<input
											type="hidden"
											name={`${item.name}.name`}
											value={item.name}
										/>
										<input
											type="hidden"
											name={`${item.name}.label`}
											value={item.label}
										/>
										<input
											type="hidden"
											name={`${item.name}.objectKey`}
											value={item.objectKey}
										/>
										<input
											type="hidden"
											name={`${item.name}.filename`}
											value={item.filename}
										/>
										<input
											type="hidden"
											name={`${item.name}.status`}
											value={item.status}
										/>
										<input
											type="file"
											name={`${item.name}.file`}
											hidden
											ref={(input) =>
												inputRefs.current[i] | inputRefs.current.push(input)
											}
											accept={formats}
											onChange={(val) => {
												const file = (
													val.target.files ? val.target.files[0] : null
												) as File;
												if (!file) {
													inputRefs.current[i].value = '';
													return;
												}
												if (
													!fileFormats.includes(
														file.name.replace(/(.*)\./g, '')
													)
												) {
													showErrorMessage(
														'Failed to Add Attachment',
														'Allowed file types are:',
														<b>.doc, .docx, .pdf, .jpg, .jpeg, .png</b>
													);
													return;
												}
												const updatedItem: FileUploadTableItem = {
													...item,
													objectKey: '',
													fileKey: '',
													filename: '',
												};
												for (const [key, value] of Object.entries(
													updatedItem
												)) {
													setValue(`${name}.${item.name}.${key}`, value);
												}
												handleFile(file, updatedItem, i);
												onChange && onChange(val, i);
											}}
										/>
										<Grid container>
											<Grid
												column
												size={1}
												of={4}
												className={
													styles.fileUploadTableRowItem +
													' ' +
													styles.requirement
												}
											>
												{item.label}{' '}
												{item.status == 'Uploaded' && (
													<CompleteIcon className={styles.completeIcon} />
												)}
												{item.status == 'Upload Failed' && (
													<ErrorIcon className={styles.failedIcon} />
												)}
											</Grid>
											<Grid
												column
												size={1}
												of={4}
												className={
													styles.fileUploadTableRowItem + ' ' + styles.filename
												}
											>
												<a
													onClick={() => {
														client
															.get(
																channel
																	? `v2/channels/${id}/docs/view`
																	: `v2/products/${productTypeId}/${getValues(
																			'product_accreditation.product_id'
																	  )}/document/view/contract`,
																channel
																	? {
																			params: {
																				key: item.realFilename,
																			},
																	  }
																	: {
																			params: {
																				key: item.fileKey,
																				is_draft: isDraft,
																			},
																	  }
															)
															.then(({ data: { download_url, url } }) => {
																if (channel) {
																	setSelectedFile({
																		fileName: item.filename,
																		url: url,
																	});
																	showFileModal();
																}
																if (download_url) {
																	setSelectedFile({
																		fileName: item.filename,
																		url: download_url,
																	});
																	showFileModal();
																}
															});
													}}
												>
													{item.filename}
												</a>
											</Grid>
											<Grid
												column
												size={1}
												of={4}
												className={
													styles.fileUploadTableRowItem +
													' ' +
													styles.status +
													' ' +
													(item.status == 'Upload Failed' && styles.error)
												}
											>
												{item.status}
												{item.status == 'Uploading' && (
													<DynamicIcon
														variant="typing"
														className={styles.loadingIcon}
													/>
												)}
												{item.status == 'Upload Failed' && (
													<Button
														className={styles.btnIcon}
														onClick={() => {
															const file = inputRefs.current[i]
																.files[0] as File;
															handleFile(file, item, i);
														}}
														variant="icon"
													>
														<RefreshIcon className={styles.icon} />
													</Button>
												)}
											</Grid>
											<Grid
												column
												size={1}
												of={4}
												className={
													styles.fileUploadTableRowItem + ' ' + styles.action
												}
											>
												{disabled
													? item?.filename && (
															<DownloadIcon
																title="Download"
																onClick={() => {
																	const fileExt = item.filename
																		.split('.')
																		.pop();
																	client
																		.get(
																			channel
																				? `v2/channels/${id}/docs/download`
																				: `v2/${'products'}/${productTypeId}/${getValues(
																						'product_accreditation.product_id'
																				  )}/document/download/contract`,
																			channel
																				? {
																						params: {
																							key: item.realFilename,
																						},
																				  }
																				: {
																						params: {
																							key: item.fileKey,
																							is_draft: isDraft,
																						},
																				  }
																		)
																		.then(({ data: { download_url, url } }) => {
																			if (download_url) {
																				setResponse({
																					...response,
																					icon:
																						fileIconExtComponents[fileExt] ||
																						docsIcon,
																					isOpen: true,
																				});
																				downloadFile(
																					download_url,
																					item.filename
																				);
																			} else if (url) {
																				setResponse({
																					...response,
																					icon:
																						fileIconExtComponents[fileExt] ||
																						docsIcon,
																					isOpen: true,
																				});
																				downloadFile(url, item.filename);
																			}
																		});
																}}
															/>
													  )
													: (item?.status == '' ||
															item?.status == 'Uploaded' ||
															item?.status == 'Upload Failed' ||
															item?.status == 'Not Yet Uploaded') && (
															<OutlineButton
																disabled={disabled}
																className={styles.fileUploadTableRowAction}
																onClick={() => {
																	inputRefs.current[i].value = '';
																	inputRefs.current[i].click();
																}}
															>
																Upload
															</OutlineButton>
													  )}
											</Grid>
										</Grid>
									</div>
								);
							})}
					</Grid>
				</Grid>
			</div>
			{isFileModalOpen && (
				<FilePreviewModal
					open={isFileModalOpen}
					onClose={hideFileModal}
					file={selectedFile}
				/>
			)}
			{isSuccessModalOpen && (
				<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successBody}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			<ResponseModal {...response} />
			{isErrorModalOpen && (
				<ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>{errorHeader}</div>
						<div className={styles.errorBody}>{errorMessage}</div>
						<div className={styles.errorFooter}>{errorInstruction}</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Close
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
		</>
	);
};

export default FileUploadTable;
