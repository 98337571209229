import { IconSettings, Button as SFButton, } from "@salesforce/design-system-react";
import cx from 'classnames';
import Button from 'components/Buttons/Button';
import { FaCheck, FaTimes } from 'react-icons/fa';
import styles from './styles.module.css';
type Props = {
	value: any;
	action: any;
	showApproveButton?: boolean;
	showRejectButton?: boolean;
	showDetailsButton?: boolean;
};
/**
 * @param value pass here the status value of the table row. When value is `value.status` is FOR_REVIEW the actions shows Approve and reject Icon, else it will only show the view details action
 * @param action this emits what button has been clicked. (value,action) => {}  **value** as row value, **action** as `approve || reject || details`
 * @param showApproveButton defaults as true, pass value of `false` to hide the approve button.
 * @param showRejectButton defaults as true, pass value of `false` to hide the Reject button.
 * @param showDetailsButton defaults as true, pass value of `false` to hide the Details button.
 */
const Actions: React.FC<Props> = ({
	value,
	action,
	showApproveButton = true,
	showRejectButton = true,
	showDetailsButton = true,
}) => {
	const emitAction = (actionButton) => {
		action(actionButton, value);
	};
	return (
		<>
			<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
				<div className={cx(styles.actionCell, 'slds-grid')}>
					<div
						className={cx(
							'slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center',
							styles.actionBtnContainer
						)}
					>
						<SFButton
							assistiveText={{ icon: 'Icon Bare Small' }}
							className={cx(
								styles.crossIcon,
								showDetailsButton ? '' : 'slds-is-visually-empty'
							)}
							iconCategory="utility"
							iconName="picklist_type"
							iconSize="small"
							iconVariant="bare"
							iconClassName={cx(styles.iconBtn)}
							onClick={() => {
								emitAction('details');
							}}
							variant="icon"
						/>
						{value.status == 'FOR_REVIEW' ? (
							<>
								{true && (
									<Button
										className={cx(
											showApproveButton ? '' : 'slds-is-visually-empty'
										)}
										onClick={() => {
											emitAction('approve');
										}}
									>
										<FaCheck
											className={styles.iconBtn}
											color="#6fcf6c"
											size="1.3em"
										/>
									</Button>
								)}
								{true && (
									<Button
										className={cx(
											showRejectButton ? '' : 'slds-is-visually-empty'
										)}
										onClick={() => {
											emitAction('reject');
										}}
									>
										<FaTimes
											className={styles.iconBtn}
											color="#db312c"
											size="1.3em"
										/>
									</Button>
								)}
							</>
						) : null}
					</div>
				</div>
			</IconSettings>
		</>
	);
};

export default Actions