import cx from 'classnames';
import Table from 'components/CWSTable/Table';
import Checkbox from 'components/Checkbox/Checkbox';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { FormModeType } from 'containers/ChannelManagement/Channel/ChannelForm/types';
import ChannelModal from 'containers/ChannelManagement/Channel/ChannelModal/ChannelModal';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToggle } from 'utils/hooks';
import Actions from '../../Actions';
import ApprovalTabFilter, { getUniqueValues } from '../../ApprovalTabFilter';
import { transformResponseToChannelDetails as transformApproved } from '../../../Channel/ChannelDetails/utils';
import ErrorModal, { ErrorModalBody } from 'components/Modal/ErrorModal';
import { useDispatch, useSelector } from 'react-redux';
import { LoaderState, resetChannelFormValues } from 'redux/modules/channelList';
import { ReducerStateType } from 'redux/modules/reducers';
import Tooltip from '@salesforce/design-system-react/components/tooltip';
import {
	AccessDeniedBodyText,
	ApproveErrorModal,
	ApproveRejectModal,
	ApproveSuccessModal,
	PreHeader,
} from '../../ChannelApprovalModals';
import {
	UseApproveRejectChannel,
	UseGetChannelContract,
	UseGetChannelPrimaryInfo,
	UseGetChannelReports,
	UseGetChannelApprovalList,
	getDraftDetails,
	UseGetChannelFilters,
} from '../../ChannelApprovalQuery';
import {
	APPROVAL_LIST_FILTER,
	LOADING_MESSAGE,
	TABLE_COLUMNS,
	TABLE_FILTER,
} from '../../constants';
import styles from '../../styles.module.css';
import {
	ChannelFilter,
	ChannelTableRowData,
	ErrorMessage,
	OptionValues,
} from '../../types';
import { transformResponseToChannelDetails } from '../../utils';
import approval from 'redux/modules/approval';
import { FixMeLater } from 'types';
import { useChannelPermission } from 'containers/ChannelManagement/Permission/hooks';
import { showAccessDeniedModal } from 'redux/modules/access';

type Props = {
	name: string;
	selectedIndex: number;
};
const ChannelApprovalTab: React.FC<Props> = ({ name, selectedIndex }) => {
	const dispatch = useDispatch();

	const {
		hasListApprovalsChannelPermission,
		hasViewPrimaryInfoChannelPermission,
		hasApprovalApproveChannelPermission,
		hasApprovalRejectChannelPermission,
	} = useChannelPermission();

	const { watch, reset, control, getValues, setValue, resetField } =
		useFormContext<ChannelFilter>();
	const channelStatus = useRef<string>('');
	const [tableFilter, setTableFilter] = useState({ ...TABLE_FILTER });
	const [approvalListParam, setApprovalListParam] = useState<
		typeof APPROVAL_LIST_FILTER | typeof TABLE_FILTER
	>({ ...APPROVAL_LIST_FILTER, ...TABLE_FILTER });
	const [errorMessage, setErrorMessage] = useState<ErrorMessage>({
		header: '',
		message: '',
		type: '',
		isCommonError: true,
	});
	const [successMessage, setSuccessMessage] = useState<any>('');
	const [approvalRemarks, setApprovalRemarks] = useState<string>('');
	const [selectedRows, setSelectedRows] = useState<ChannelTableRowData[]>([]);
	const [selectedRow, setSelectedRow] = useState<ChannelTableRowData[]>([]);
	const [isAllRowsSelected, setAllRowsSelected] = useState<any>(false);
	const [tableData, setTableData] = useState<ChannelTableRowData[]>([]);
	const [responseTableFilter, setResponseTableFilter] = useState<any>();
	const [optionValues, setOptionValues] = useState<OptionValues>({});
	const [draftsApprovalData, setDraftsData] = useState<any>();
	const [approvalDetailId, setApprovalDetailId] = useState<any>();
	const [searchChanged, setSearchChanged] = useState<boolean>(false);
	const [hideParentCheckbox, setHideParentCheckbox] = useState<boolean>(false);
	const [checkBoxLength, setCheckBoxLength] = useState<number>(0);
	const loader: LoaderState = useSelector<ReducerStateType, LoaderState>(
		(state) => state.channels.loader
	);
	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();
	const {
		value: isChannelDetailsOpen,
		valueOn: showChannelDetailsModal,
		valueOff: hideChannelDetailsModal,
	} = useToggle();
	const {
		value: isApprove,
		valueOn: showApprove,
		valueOff: hideApprove,
	} = useToggle();
	const {
		value: isReject,
		valueOn: showReject,
		valueOff: hideReject,
	} = useToggle();
	const {
		value: isError,
		valueOn: showIsError,
		valueOff: hideIsError,
	} = useToggle();
	const {
		value: isSuccess,
		valueOn: showIsSuccess,
		valueOff: hideIsSuccess,
	} = useToggle();
	const {
		value: isBatchApprove,
		valueOn: showBatchApprove,
		valueOff: hideBatchApprove,
	} = useToggle();
	const {
		value: isBatchReject,
		valueOn: showBatchReject,
		valueOff: hideBatchReject,
	} = useToggle();
	const hideIsSuccessAndChannelDetails = () => {
		hideChannelDetailsModal();
		hideIsSuccess();
		approvalListQuery?.refetch();
	};

	useEffect(() => {
		if (!hasListApprovalsChannelPermission) {
			dispatch(showAccessDeniedModal());
		}
	}, []);

	let approvalListQuery;
	if (hasListApprovalsChannelPermission) {
		approvalListQuery = UseGetChannelApprovalList(approvalListParam);
	}

	const filterDebounce = useCallback(
		debounce(() => {
			const { channelTabFilter } = getValues();
			setApprovalListParam((prevValues) => ({
				...prevValues,
				...channelTabFilter,
				...tableFilter,
			}));
			approvalListQuery?.refetch();
		}, 500),
		[tableFilter]
	);

	useEffect(() => {
		resetField('channelTabFilter');
		reset();
	}, [selectedIndex]);

	useEffect(() => {
		const getUtilsChannelFilter = async () => {
			const { channelTabFilter } = getValues();
			const { 
					search,
					createdAt,
					submittedBy,
					updatedAt, 
					channelName, 
					productType,
					partner, 
					status,
				} = channelTabFilter;
			await UseGetChannelFilters({
						search,
						createdAt,
						submittedBy,
						updatedAt, 
						channelName, 
						productType,
						partner, 
						status,
					})
					.then((res) => {
						const optionsSubmittedBy = res.submittedByList.map((element) => ({
							label: element.submittedBy,
							value: element.submittedBy,
						}));
						const optionsChannelAccount = res.channelList.map((element) => ({
							label: element.channelAccountName,
							value: element.channelAccountName,
						}));
						setOptionValues((prev) => ({
							...prev,
							optionsChannelAccount,
							optionsSubmittedBy,
						}));
					})
					.catch((err) => {
						setErrorMessage({
							header: '',
							message: 'Something went wrong.',
							isCommonError: true,
						});
						showIsError();
					});
		};
		if (selectedIndex == 0) {
			filterDebounce();
			getUtilsChannelFilter();
		} else return;
	}, [tableFilter, selectedIndex]);

	useEffect(() => {
		if (watch('channelTabFilter.search')) {
			if (watch('channelTabFilter.search')?.length > 2) {
				filterDebounce();
				setTableFilter((prev) => ({ ...prev, page: 1 }));
			}
			setSearchChanged(true);
		}
		if (searchChanged && watch('channelTabFilter.search') == '') {
			filterDebounce();
			setTableFilter((prev) => ({ ...prev, page: 1 }));
		}
	}, [watch('channelTabFilter.search')]);

	useEffect(() => {
		if (selectedIndex == 0) {
			if (approvalListQuery?.isSuccess) {
				const { data, ...tableFilter } = approvalListQuery?.data;
				if (tableFilter.total) {
					setResponseTableFilter({ ...tableFilter });
				}
				setTableData(
					data.map((element) => {
						const checkedRows = selectedRows.find(
							(item) => item.id === element.id
						);

						if (checkedRows) {
							return { ...element, checked: checkedRows.checked };
						}

						return { ...element, checked: false };
					})
				);
				setHideParentCheckbox(
					data.findIndex((element) => element.status === 'FOR_REVIEW') < 0
				);
				setCheckBoxLength(
					data.filter((element) => element.status == 'FOR_REVIEW').length
				);
				hideLoading();
			}
			if (approvalListQuery?.isFetching) {
				setTableData([]);
				showLoading();
			}
			if (approvalListQuery?.isError) {
				showIsError();
				setErrorMessage({
					header: 'Network Error',
					message: '',
					isCommonError: true,
				});
				hideLoading();
			}
		}
	}, [
		approvalListQuery?.isLoading,
		approvalListQuery?.isSuccess,
		approvalListQuery?.isError,
		approvalListQuery?.isFetching,
	]);
	useEffect(() => {
		if (
			draftsApprovalData !== null &&
			draftsApprovalData !== '' &&
			draftsApprovalData !== undefined
		) {
			showChannelDetailsModal();
			hideLoading();
		}
	}, [draftsApprovalData]);

	const handleAction = async (action: string, value: ChannelTableRowData) => {
		channelStatus.current = value.status ?? '';
		setSelectedRow([{ ...value, checked: true }]);
		if (action == 'details') {
			if (hasViewPrimaryInfoChannelPermission) {
				dispatch(resetChannelFormValues());
				showLoading();
				try {
					let transformedData: FixMeLater = null;
					if (value.status === 'APPROVED_FOR_INTEGRATION') {
						const [primaryInfo, contractDetails, reports] = await Promise.all([
							UseGetChannelPrimaryInfo(value.id),
							UseGetChannelContract(value.id),
							UseGetChannelReports(value.id),
						]);

						[transformedData] = await Promise.all([
							transformApproved(
								primaryInfo.data,
								contractDetails.data,
								reports.data,
								primaryInfo.meta
							),
						]);
					} else {
						const approvalData = await getDraftDetails(value.id);

						transformedData = transformResponseToChannelDetails(
							approvalData.data,
							approvalData.meta
						);
					}

					setApprovalDetailId(value);
					setDraftsData(transformedData);
				} catch (err) {
					setErrorMessage({
						header: '',
						message: 'Something went wrong.',
						isCommonError: true,
					});
					showIsError();
				}
			} else {
				dispatch(showAccessDeniedModal());
			}
		}
		if (action == 'approve') {
			if (hasApprovalApproveChannelPermission) {
				showApprove();
			} else {
				dispatch(showAccessDeniedModal());
			}
		}
		if (action == 'reject') {
			if (hasApprovalRejectChannelPermission) {
				showReject();
			} else {
				dispatch(showAccessDeniedModal());
			}
		}
	};

	const approve = async (
		remarks: string,
		isRetry: boolean,
		isApprove: boolean,
		isBatch: boolean | undefined = false
	) => {
		setApprovalRemarks(remarks);
		const idLists = await Promise.all(
			selectedRows.map((element) => {
				return element.id;
			})
		);
		const idList = [selectedRow[0]?.id];
		if (idLists.length > 0 || idList.length > 0) {
			showLoading();
			await UseApproveRejectChannel(
				isBatch ? idLists : idList,
				remarks,
				isApprove
			)
				.then(() => {
					hideLoading();
					if (isBatch) {
						setSuccessMessage(
							<>Channel requests are {isApprove ? 'Approved' : 'Rejected'}.</>
						);
						showIsSuccess();
						hideIsError();
						isApprove ? hideBatchApprove() : hideBatchReject();
					}
					if (!isBatch) {
						setSuccessMessage(
							<>
								{selectedRow[0]?.name} is now{' '}
								<strong>{isApprove ? 'Approved' : 'Rejected'}</strong>
							</>
						);
						showIsSuccess();
						hideIsError();
						isApprove ? hideApprove() : hideReject();
					}
					setSelectedRow([]);
					setSelectedRows([]);
				})
				.catch((response) => {
					hideLoading();
					setErrorMessage({
						header: 'Something went wrong.',
						message: '',
						type: isApprove ? 'Approved' : 'Rejected',
						isBatch: isBatch,
						isCommonError: false,
					});
					isRetry ? null : showIsError();
				})
				.finally(() => {
					setAllRowsSelected(false);
				});
		}
		return;
	};

	const retry = (param, isBatch) => {
		approve(approvalRemarks, true, param == 'Approved', isBatch);
	};

	const updatedColumn = TABLE_COLUMNS.map((element) => {
		if (element.id === 'action') {
			return {
				...element,
				Header: () => <div className={cx(styles.centerAlign)}>Action</div>,
				accessor: (values: any) => values,
				Cell: ({ value }) => {
					return (
						<Actions
							value={value}
							action={handleAction}
							showApproveButton={hasApprovalApproveChannelPermission}
							showRejectButton={hasApprovalRejectChannelPermission}
						/>
					);
				},
			};
		}
		if (element.id === 'status') {
			return {
				...element,
				accessor: ({ status }) =>
					status
						.toLowerCase()
						.split('_')
						.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
						.join(' '),
				Cell: ({ value }) => {
					return (
						<div className={styles[value.replaceAll(' ', '')]}>{value}</div>
					);
				},
			};
		}
		if (element.id === 'checked') {
			return {
				...element,
				Header: () => {
					return (
						<div>
							<Checkbox
								label=""
								type="checkbox"
								onChange={(v) => {
									setSelectedRows([]);
									const updateData = tableData.map((element, index) => {
										if (element.status == 'FOR_REVIEW' && index < 10) {
											if (v) {
												setSelectedRows((prev) => {
													return [...prev, { ...element, checked: v }];
												});
											}
											return { ...element, checked: v };
										}
										return { ...element, checked: false };
									});
									setTableData(updateData);
									setAllRowsSelected(v);
								}}
								checked={isAllRowsSelected}
								disabled={
									!(
										hasApprovalApproveChannelPermission ||
										hasApprovalRejectChannelPermission
									)
								}
								className={
									hideParentCheckbox ? cx('slds-is-visually-empty') : ''
								}
							/>
						</div>
					);
				},
				accessor: (values: any) => values,
				Cell: ({ value }) => {
					if (value.status == 'FOR_REVIEW') {
						return (
							<div>
								<Checkbox
									label=""
									type="checkbox"
									onChange={(v) => {
										if (!v || selectedRows.length < 10) {
											const updateData = tableData.map((element) => {
												if (value.id == element.id) {
													return {
														...element,
														checked: v,
													};
												}
												return element;
											});
											setTableData(updateData);
											if (v) {
												setSelectedRows((prev) => {
													return [...prev, { ...value, checked: v }];
												});
											} else {
												setSelectedRows((prev) => {
													return prev.filter((item) => item.id !== value.id);
												});
											}
										} else {
											showIsError();
											setErrorMessage({
												header: '',
												message:
													'Only max of 10 request can be processed at a time.',
												isCommonError: true,
											});
										}
									}}
									checked={value.checked}
									disabled={
										!(
											hasApprovalApproveChannelPermission ||
											hasApprovalRejectChannelPermission
										)
									}
								/>
							</div>
						);
					} else return null;
				},
			};
		}
		if (element.id === 'remarks') {
			return {
				...element,
				Cell: ({ value }) => {
					const maxLength = 20;
					const truncatedValue =
						value && value.length > maxLength
							? value.substring(0, maxLength - 3) + '...'
							: value;
					const tooltipId = `${element.id}-${truncatedValue}-tooltip`;
					return (
						<div>
							<Tooltip
								id={tooltipId}
								content={value}
								align="bottom left"
								variant="list-item"
							>
								<div>{truncatedValue}</div>
							</Tooltip>
						</div>
					);
				},
			};
		}
		return element;
	});

	return (
		<>
			{hasListApprovalsChannelPermission ? (
				<>
					<ApprovalTabFilter
						control={control}
						name={name}
						reset={reset}
						filter={() => {
							filterDebounce();
							setTableFilter((prev) => ({ ...prev, page: 1 }));
						}}
						optionValues={optionValues}
					/>
					<Table
						columns={updatedColumn}
						data={tableData}
						count={responseTableFilter?.total}
						pageSize={responseTableFilter?.pageSize}
						page={responseTableFilter?.page}
						sortBy="createdAt"
						sort="desc"
						onPageChange={(page, limit) => {
							setTableFilter((prev) => ({ ...prev, page, limit }));
						}}
						onSort={(sortBy, sort) => {
							setTableFilter((prev) => ({
								...prev,
								sortBy,
								sort: sort.toLowerCase(),
							}));
						}}
						isNotEvenRowColor={false}
						modalIsOpen={false}
						preHeader={
							selectedRows.length > 0 && (
								<PreHeader
									selectedRows={selectedRows}
									showApprove={showBatchApprove}
									showReject={showBatchReject}
									hasApprovePermission={hasApprovalApproveChannelPermission}
									hasRejectPermission={hasApprovalRejectChannelPermission}
									setAllRowsSelected={setAllRowsSelected}
									targetLength={checkBoxLength > 10 ? 10 : checkBoxLength}
								/>
							)
						}
						withRowField={false}
					/>
					{isLoading && (
						<FullPageLoader open={isLoading} message={LOADING_MESSAGE} />
					)}
					{loader.isLoading && (
						<FullPageLoader open={loader.isLoading} message={loader.message} />
					)}
					{isChannelDetailsOpen && (
						<ChannelModal
							refetchAutoSave={() => {}}
							disabledForm={!isReject}
							onClose={() => {
								hideChannelDetailsModal();
								approvalListQuery?.refetch();
							}}
							open={isChannelDetailsOpen}
							mode={
								approvalDetailId.status == 'REJECTED'
									? FormModeType.REJECTED
									: FormModeType.APPROVAL
							}
							channelId={approvalDetailId.id}
							approvalData={draftsApprovalData}
							showApproveModal={showApprove}
							showRejectModal={showReject}
							hasApprovePermission={hasApprovalApproveChannelPermission}
							hasRejectPermission={hasApprovalRejectChannelPermission}
							origin="APPROVAL"
							channelStatus={channelStatus.current}
						/>
					)}

					{isApprove && (
						<ApproveRejectModal
							isOpen={isApprove}
							hideApprove={hideApprove}
							approveReject={approve}
							rowData={selectedRow}
							isApprove={true}
							isBatchApprove={false}
						/>
					)}
					{isReject && (
						<ApproveRejectModal
							isOpen={isReject}
							hideApprove={hideReject}
							approveReject={approve}
							rowData={selectedRow}
							isApprove={false}
							isBatchApprove={false}
						/>
					)}
					{isError && (
						<ApproveErrorModal
							errorMessage={errorMessage}
							hideErrorModal={hideIsError}
							isErrorModalShown={isError}
							retry={retry}
						/>
					)}
					{isSuccess && (
						<ApproveSuccessModal
							hideSuccessModal={hideIsSuccessAndChannelDetails}
							isSuccessModalShown={isSuccess}
							successMessage={successMessage}
						/>
					)}
					{isBatchApprove && (
						<ApproveRejectModal
							isOpen={isBatchApprove}
							hideApprove={hideBatchApprove}
							approveReject={approve}
							rowData={selectedRows}
							isApprove={true}
							isBatchApprove={true}
						/>
					)}
					{isBatchReject && (
						<ApproveRejectModal
							isOpen={isBatchReject}
							hideApprove={hideBatchReject}
							approveReject={approve}
							rowData={selectedRows}
							isApprove={false}
							isBatchApprove={true}
						/>
					)}
				</>
			) : null}
		</>
	);
};
export default ChannelApprovalTab;
