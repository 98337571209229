import React, { useState } from 'react';
import cx from 'classnames';

import { Modal } from '@salesforce/design-system-react';
import Grid from 'components/Grid/Grid';
import Filter, { FilterOuter } from 'components/Filter';
import ModalButton from 'components/Buttons/ModalButton/ModalButton';
import Divider from 'components/PartnerForm/Divider';
import ProductCard from 'components/Cards/Card';
import AuditTrailList from 'components/AuditTrailList/AuditTrailList';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';

import { ReactComponent as AuditTrailIcon } from 'assets/icons/ic-audit-trail.svg';

import client from 'helpers/ApiClient';
import { formatDate } from 'utils/common';
import { useErrorModal, useLoader, useToggle } from 'utils/hooks';
import { useHasUserPermissionWithModal } from 'utils/permissions';

import { PRODUCT_LIST } from 'constants/productList';
import { LOADING_LIST_MSG } from 'constants/loading';
import {
	ProductCardProps,
	ProductListProps,
	ProductStatus,
	AuditTrailProductResp,
	PartnerFilterOptions,
	UpdateProduct,
	UpdateProductResp,
} from '../types';
import { STATUS_MAPPING } from './constant';

import styles from './ProductList.module.css';

const productCardData = (data: ProductStatus[]) => {
	if (!data) return [];

	return PRODUCT_LIST.map((product) => {
		const filteredProduct =
			data?.filter(({ name }) => name === product.name)[0] || null;

		return {
			...product,
			id: filteredProduct ? filteredProduct.id : 0,
			api3Status: filteredProduct ? filteredProduct.api3_status : 'ACTIVE',
			initialStatus: filteredProduct
				? filteredProduct.status === 'ACTIVE' ||
				  filteredProduct.status === 'ENABLED'
					? 'ENABLED'
					: 'DISABLED'
				: '',
		};
	});
};

const VIEW_COLUMN_WIDTH = 100 / 3;

const VIEW_LOGS_COLUMNS = () => [
	{
		Header: 'Field Name',
		id: 'action',
		sortable: false,
		width: `${VIEW_COLUMN_WIDTH}%`,
	},
	{
		Header: 'Old Values',
		id: 'oldValues',
		sortable: false,
		width: `${VIEW_COLUMN_WIDTH}%`,
	},
	{
		Header: 'New Values',
		id: 'newValues',
		sortable: false,
		width: `${VIEW_COLUMN_WIDTH}%`,
	},
];

const COLUMN_WIDTH = 100 / 6;

const AUDIT_TRAIL_COLUMNS = (handleOnClickViewLogs) => [
	{
		Header: 'Date and Time',
		id: 'createdAt',
		sortable: false,
		width: `${COLUMN_WIDTH}%`,
		accessor: ({ createdAt }) => formatDate(createdAt),
	},
	{
		Header: 'Logged By',
		id: 'loggedBy',
		sortable: false,
		width: `${COLUMN_WIDTH}%`,
	},
	{
		Header: 'Action',
		id: 'action',
		sortable: false,
		width: `${COLUMN_WIDTH}%`,
	},
	{
		Header: 'Source IP',
		id: 'ipAddress',
		sortable: false,
		width: `${COLUMN_WIDTH}%`,
	},
	{
		Header: 'Remarks',
		id: 'remarks',
		sortable: false,
		width: `${COLUMN_WIDTH}%`,
	},
	{
		Header: '',
		id: 'logs',
		sortable: false,
		width: `${COLUMN_WIDTH}%`,
		Cell: ({ row }) => (
			<div className={styles.actionContainer}>
				<div
					className={cx(styles.action)}
					onClick={() => handleOnClickViewLogs(row.id)}
					onKeyDown={() => {
						return;
					}}
				>
					View Logs
				</div>
			</div>
		),
	},
];

const ProductList: React.FC<ProductListProps> = ({
	isProductLoading,
	showPartnerList,
	productList,
	fetchPartnerList,
	setClickCardTitle,
	setRefetchProductList,
}) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const [data, setData] = useState<AuditTrailProductResp | undefined>();
	const [logsData, setLogsData] = useState<AuditTrailProductResp | undefined>(
		{}
	);

	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		value: isAuditTrailOpen,
		valueOn: openAuditTrail,
		valueOff: closeAuditTrail,
	} = useToggle();
	const {
		value: isViewLogsOpen,
		valueOn: openViewLogs,
		valueOff: closeViewLogs,
	} = useToggle();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const updateProduct = async ({
		showLoader,
		hideLoader,
		showError,
		setSuccessLabel,
		showSuccessPopup,
		hideError,
		apiPayload,
	}: UpdateProduct) => {
		hideErrorModal();
		showLoader(LOADING_LIST_MSG);

		const status = apiPayload.bfaStatus === 'ACTIVE' ? 'enabled' : 'disabled';

		try {
			const result: UpdateProductResp = await client.put(
				`v2/bfa-admin-portal/product/update`,
				apiPayload
			);
			if (result?.error) {
				throw new Error(result?.error?.message);
			}

			setSuccessLabel(
				<div>
					{' '}
					<span className={styles.bodyHeaderEmphasis}>
						{apiPayload.name}
					</span>{' '}
					is now {status}.
				</div>
			);
			showSuccessPopup();
		} catch (error) {
			showError(
				'Timeout Error!',
				<div>
					{`A problem occured while ${status
						.slice(0, -2)
						.toLowerCase()}ing the product.`}
				</div>,
				null,
				() => {
					updateProduct({
						showLoader,
						hideLoader,
						showError,
						setSuccessLabel,
						showSuccessPopup,
						hideError,
						apiPayload,
					})
						.then(() => {
							// Handle successful resolution
						})
						.catch(() => {
							// Handle error
						});
				}
			);
		} finally {
			hideLoader();
		}
	};

	const handleOnClickViewLogs = (row: number) => {
		showLoadingMessage();
		const logsValue = data?.data?.[row];

		const newValues =
			logsValue?.newValues !== undefined ? logsValue?.newValues : '';

		const oldValues =
			logsValue?.oldValues !== undefined ? logsValue?.oldValues : '';

		const combinedData: AuditTrailProductResp = {
			data: logsValue
				? [
						{
							...logsValue,
							newValues: STATUS_MAPPING[newValues?.status],
							oldValues: STATUS_MAPPING[oldValues?.status],
							action: 'Action',
						},
				  ]
				: [],
			meta: data?.meta,
		};
		setLogsData(combinedData);
		setTimeout(() => {
			hideLoading();
			openViewLogs();
		}, 300);
	};

	const fetchAuditTrail = async (params?: PartnerFilterOptions) => {
		hideErrorModal();
		showLoadingMessage();

		try {
			const apiUrl = 'v2/bfa-admin-portal/product/logs';

			const param = {
				page: params?.page,
				limit: params?.limit,
			};

			await client
				.get(apiUrl, {
					params: param,
				})
				.then((response) => {
					if (response?.data?.data.length === 0) {
						return;
					}

					if (response?.data?.error) {
						throw new Error(response?.data?.error?.message);
					}

					setData(response.data);
				});
		} catch (error) {
			showErrorMessage(
				'Timeout Error!',
				'A problem occurred while loading the data.',
				null,
				() => {
					closeAuditTrail();
					fetchAuditTrail(params);
				}
			);
		} finally {
			hideLoading();
			openAuditTrail();
		}
	};

	const hideAllModal = () => {
		hideErrorModal();
		closeAuditTrail();
	};

	return (
		<>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			{isAuditTrailOpen && !isErrorModalShown && (
				<Modal
					isOpen={isAuditTrailOpen}
					ariaHideApp={false}
					size="medium"
					onRequestClose={closeAuditTrail}
				>
					<AuditTrailList
						title="Audit Trail"
						handleViewLogs={isViewLogsOpen}
						columns={AUDIT_TRAIL_COLUMNS(handleOnClickViewLogs)}
						onClose={closeAuditTrail}
						isViewLogsOpen={isViewLogsOpen}
						viewLogsColumn={VIEW_LOGS_COLUMNS()}
						auditTrailData={data}
						onCloseViewLogs={closeViewLogs}
						viewLogsData={logsData}
						hasViewLogsDetails={true}
						handleTableFetch={fetchAuditTrail}
					/>
				</Modal>
			)}
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={hideAllModal}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
			<div className={cx('slds-card', styles.paperContainer)}>
				<Filter
					title="Product List"
					padding={'1.2vmax 0.7vmax 1.2vmax 0.7vmax'}
					className={styles.filters}
				>
					<FilterOuter>
						<Grid container align="end">
							<Grid container column size={12} of={12} align="end">
								<ModalButton
									onClick={() => {
										if (hasUserPermission('product.audit-trail')) {
											fetchAuditTrail({ page: 1, limit: 25 });
										}
									}}
									name="Audit Trail"
									icon={<AuditTrailIcon />}
								/>
							</Grid>
						</Grid>
					</FilterOuter>
				</Filter>
				<Divider className={styles.divider} />
				<div className={styles.productContainer}>
					{!isProductLoading &&
						productList &&
						productCardData(productList).map(
							({
								id,
								name,
								code,
								cardTitle,
								iconData,
								initialStatus,
								api3Status,
							}: ProductCardProps) => {
								return (
									<ProductCard
										key={name}
										code={code}
										productName={name}
										cardTitle={cardTitle}
										iconData={iconData}
										initialStatus={initialStatus === 'ENABLED' ? true : false}
										onClick={(clickedProduct) => {
											if (hasUserPermission('list.partner')) {
												setClickCardTitle(clickedProduct);
												showPartnerList();
												fetchPartnerList({
													productId: id,
													params: {},
												});
											}
										}}
										isToggleDisabled={api3Status !== 'ACTIVE'}
										withConfirmModal={true}
										handleSubmit={updateProduct}
										setRefetchData={setRefetchProductList}
										tooltipContent={
											api3Status === 'ACTIVE'
												? undefined
												: 'This Product is disabled in API 3.0, status is not editable.'
										}
									/>
								);
							}
						)}
				</div>
			</div>
		</>
	);
};

export default ProductList;