import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { Tooltip } from '@salesforce/design-system-react';

import styles from '../Truncated/Truncated.module.css';

type Props = {
	data: string;
	className?: any;
};

const Truncated: React.FC<Props> = ({ data, className = '' }) => {
	const [scrollHeight, setScrollHeight] = useState<number>(0);
	const [clientHeight, setClientHeight] = useState<number>(0);

	const ref = useRef<any>(null);

	const isTruncated = scrollHeight > clientHeight;

	const count = isTruncated && data ? `(${data?.split(',').length})` : '';

	const text = (
		<div className={styles.textContainer}>
			<div ref={ref} className={styles.text}>
				{data}
			</div>
			<span className={styles.count}>{count}</span>
		</div>
	);

	useEffect(() => {
		const updateParentHeight = () => {
			if (ref?.current) {
				setScrollHeight(ref?.current?.scrollHeight);
				setClientHeight(ref?.current?.clientHeight);
			}
		};

		window.addEventListener('resize', updateParentHeight);
		updateParentHeight();

		return () => {
			window.removeEventListener('resize', updateParentHeight);
		};
	}, []);

	return (
		<div className={cx(styles.truncated, className && className)}>
			<Tooltip
				content={data}
				align="bottom"
				dialogClassName={styles.tooltip}
				isOpen={isTruncated ? undefined : false}
			>
				<button className={styles.button}>{text}</button>
			</Tooltip>
		</div>
	);
};

export default Truncated;
