import { Tooltip } from '@salesforce/design-system-react';
import FileUploadTable from 'components/FileUploadTable/FileUploadTable';
import Section, { SectionRow } from 'components/Section/Section';
import { useFormContext } from 'react-hook-form';
import { ACCREDITATION_REQUIREMENTS_OPTIONS } from '../ContractDetailsConstants';
import { ContractDetailsType } from '../ContractDetailsSchemas';
import styles from './AccreditationRequirements.module.css';
import { useEffect } from 'react';
import { transformAccreditation } from './utils';
import { useSelector } from 'react-redux';
import { FixMeLater } from 'types';
import { FormModeType } from '../../../types';
import cx from 'classnames';

type Props = {
	title: string;
	name: string;
	disabled: boolean;
	isChannelDetails: boolean;
	mode?: FormModeType;
	channelId?: number;
	isAutoSave?: boolean;
};
const AccreditationRequirements: React.FC<Props> = ({
	channelId,
	disabled,
	name,
	title,
	isChannelDetails,
	mode,
	isAutoSave,
}) => {
	const { control, setValue, getValues } =
		useFormContext<ContractDetailsType>();
	const accreditationValues = getValues().contractDetails.channelAccreditation;

	const selectedChannelId = useSelector((state: FixMeLater) => {
		return state.channels?.selectedChannel?.id as number;
	});
	const transformedAccreditation: any =
		transformAccreditation(accreditationValues);

	const isApproval = mode === FormModeType.APPROVAL;
	const isDraft = mode === FormModeType.DRAFT;
	const isRejected = mode === FormModeType.REJECTED;

	const isDownloadMode =
		isChannelDetails || isApproval || isDraft || isRejected || isAutoSave;

	const inquireType = isChannelDetails
		? 'channel_document_active'
		: 'channel_document_draft';

	return (
		<Section
			title={
				<div className={styles.accreditatioRequirementsTitle}>
					{title}
					<Tooltip
						align="bottom left"
						content={
							<>
								Kindly ensure to comply and secure the requirements when
								onboarding a partner.
							</>
						}
					/>
				</div>
			}
		>
			<SectionRow className={cx(styles.noMarginBottom, styles.noMarginSide)}>
				<FileUploadTable
					mainSetValue={setValue}
					name={name}
					disabled={disabled}
					data={
						isDownloadMode
							? transformedAccreditation
							: ACCREDITATION_REQUIREMENTS_OPTIONS
					}
					onChange={() => {
						null;
					}}
					fileFormats={['doc', 'docx', 'pdf', 'jpg', 'jpeg', 'png']}
					maxFileSize="25"
					maxFileSizeUnit="MB"
					inquireType={inquireType}
					channel={true}
					isChannelDetails={isDownloadMode}
					id={selectedChannelId || channelId}
					//id={id}
					//{...rest}
				/>
			</SectionRow>
		</Section>
	);
};

export default AccreditationRequirements;
