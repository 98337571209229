import React, { useState } from 'react';
import moment from 'moment';

import { SwitchWithTooltip } from 'components/Switch';
import ConfirmModalWithRemarks from 'components/Modal/ConfirmModalWithRemarks';
import ErrorModalContainer from 'components/Modal/ErrorModalContainer';
import SuccessModalContainer from 'components/Modal/SuccessModalContainer';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';

import volumeIcon from 'assets/icons/ic-card.svg';
import collectionIcon from 'assets/icons/ic-transaction.svg';
import defaultLogo from 'assets/icons/img-logo.svg';

import client from 'helpers/ApiClient';
import { useHasUserPermissionWithModal } from 'utils/permissions';
import {
	useErrorModal,
	useLoader,
	useSuccessModal,
	useToggle,
} from 'utils/hooks';
import { LOADING_UPDATE_MSG } from 'constants/loading';

import DetailsSection from './DetailsSection';
import { PartnerDetailsHeaderProps, UpdateStatusResp } from '../types';
import styles from './PartnerDetailsHeader.module.css';

const totalCountFormat = (v: any) => {
	return v.toLocaleString();
};

const PartnerDetailsHeader: React.FC<PartnerDetailsHeaderProps> = ({
	id,
	logoSrc,
	partnerName,
	partnerStatus,
	partnerCode,
	totalVolume = 10,
	totalCollection = 10,
	setRefetchData,
	api3Status,
	...rest
}) => {
	const hasUserPermission = useHasUserPermissionWithModal('bfaap');

	const initialToggleValue = partnerStatus === 'ENABLED' ? true : false;
	const [partnerToggleStatus, setPartnerToggleStatus] =
		useState<boolean>(initialToggleValue);
	const [remarks, setRemarks] = useState<string>('');
	const { isLoading, loadingMessage, showLoadingMessage, hideLoading } =
		useLoader();

	const {
		isErrorModalShown,
		showErrorMessage,
		hideErrorModal,
		errorHeader,
		errorMessage,
		errorInstruction,
		retryBtnOnClick,
	} = useErrorModal();

	const {
		isSuccessModalOpen,
		showSuccessMessage,
		hideSuccessModal,
		successMessage,
	} = useSuccessModal();

	const {
		value: isConfirmModalShown,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

	const bfaStatusForFe = partnerToggleStatus ? 'Enabled' : 'Disabled';

	const actionText = partnerToggleStatus ? 'Enable' : 'Disable';
	const tooltipValue = partnerToggleStatus ? 'Disable' : 'Enable';
	const modalHeader = `${actionText} Product Partner`;
	const bodyHeader = (
		<div>
			Are you sure you want to {actionText.toLowerCase()}{' '}
			<span className={styles.bodyHeaderEmphasis}>{partnerName}</span>?
		</div>
	);

	const errorModalBody = `A problem occurred while ${
		partnerToggleStatus ? 'enabling' : 'disabling'
	} the partner.`;

	const handleUpdate = async () => {
		hideErrorModal();
		showLoadingMessage(LOADING_UPDATE_MSG);

		try {
			const result: UpdateStatusResp = await client.put(
				`v2/bfa-admin-portal/partner/update`,
				{
					code: partnerCode,
					name: partnerName,
					partnerId: id,
					bfaStatus: partnerToggleStatus ? 'ACTIVE' : 'INACTIVE',
					remarks,
				}
			);
			if (result?.error) {
				throw new Error(result?.error?.message);
			}
			showSuccessMessage(
				<div>
					<span className={styles.bodyHeaderEmphasis}>{partnerName}</span> is
					now {bfaStatusForFe.toLocaleLowerCase()}.
				</div>
			);
		} catch (error) {
			showErrorMessage('Timeout Error!', `${errorModalBody}`, null, () =>
				handleUpdate()
			);
		} finally {
			hideLoading();
		}
	};

	const handleOnChange = (newValue) => {
		if (
			(partnerToggleStatus && hasUserPermission('partner.disable')) ||
			(!partnerToggleStatus && hasUserPermission('partner.enable'))
		) {
			setPartnerToggleStatus(newValue);
			showConfirmModal();
		}
	};

	return (
		<div>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			<div className={styles.headerContainer}>
				<div className={styles.leftHeaderContainer}>
					<div className={styles.logoFieldContainer}>
						<img src={logoSrc || defaultLogo} />
					</div>
					<div className={styles.partnerDetails}>
						<div className={styles.partnerNameContainer}>
							<div className={styles.partnerName}>{partnerName}</div>
							<div>
								<SwitchWithTooltip
									name="partnerStatus"
									checked={partnerToggleStatus}
									onChange={api3Status !== 'ACTIVE' ? () => {} : handleOnChange}
									toolTipContent={
										api3Status === 'ACTIVE'
											? tooltipValue
											: 'This Product Partner is disabled in API 3.0, status is not editable.'
									}
									isPointerDisabled={api3Status !== 'ACTIVE'}
								/>
							</div>
						</div>
						<div className={styles.statusContainer}>
							<div className={styles.statusLabel}>Partner Status: </div>
							<div
								className={
									partnerToggleStatus
										? styles.statusEnabled
										: styles.statusDisabled
								}
							>
								{bfaStatusForFe}
							</div>
						</div>
					</div>
				</div>
				<div className={styles.rightHeaderContainer}>
					<div className={styles.transactionContainer}>
						<div className={styles.transactionIcon}>
							<img src={volumeIcon} alt="total volume icon" />
						</div>
						<div>
							<div className={styles.transactionHeaderText}>
								Total Number of Volume
							</div>
							<div
								className={styles.transactionHeaderSubText}
							>{`YTD as of  ${String(moment().format('MMMM D, YYYY'))}`}</div>
							<div className={styles.transactionVolume}>
								{totalCountFormat(totalVolume || 0)}
							</div>
						</div>
					</div>
					<div className={styles.transactionContainer}>
						<div className={styles.transactionIcon}>
							<img src={collectionIcon} alt="total collection icon" />
						</div>
						<div>
							<div className={styles.transactionHeaderText}>
								Total Number of Collection
							</div>
							<div className={styles.transactionHeaderSubText}>
								{`YTD as of  ${String(moment().format('MMMM D, YYYY'))}`}
							</div>
							<div className={styles.transactionCollection}>
								{totalCountFormat(totalCollection || 0)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.subHeaderContainer}>
				<DetailsSection data={{ ...rest, partnerCode: partnerCode }} />
			</div>
			<ConfirmModalWithRemarks
				isOpen={isConfirmModalShown}
				onClose={hideConfirmModal}
				onCancel={() => {
					setPartnerToggleStatus(!partnerToggleStatus);
					hideConfirmModal();
				}}
				modalDisabled={false}
				confirmOnClick={handleUpdate}
				header={modalHeader}
				bodyHeader={bodyHeader}
				remarksOnChange={setRemarks}
				remarksSubLabel="Maximum of 1000 characters only."
				remarksMaxLength={1000}
			/>
			<ErrorModalContainer
				isOpen={isErrorModalShown}
				onClose={() => {
					hideErrorModal();
					setRefetchData && setRefetchData(true);
				}}
				errorHeader={errorHeader}
				errorMessage={errorMessage}
				errorInstruction={errorInstruction}
				retryBtnOnClick={retryBtnOnClick}
			/>
			<SuccessModalContainer
				isOpen={isSuccessModalOpen}
				onClose={() => {
					hideSuccessModal();
					setRefetchData && setRefetchData(true);
				}}
				successMessage={successMessage}
			/>
		</div>
	);
};
export default PartnerDetailsHeader;