import { Component } from 'react';
import styles from './Login.module.css';
import cx from 'classnames';
import RightPane from '../../components/Login/RightPane';
import LeftPane1 from '../../components/Login/LeftPane1';
import LeftPane2 from '../../components/Login/LeftPane2';
import SessionExpiredModal from '../SessionHandler/SessionExpiredModal';
import { createRandomString, getChallenge } from '../../utils/codeGenerator';

class Login extends Component {
	constructor() {
		super();
		this.state = {
			isContactUsActive: false,
			codeChallenge: null,
			state: null,
			isExpired: false,
		};
		this.onClick = this.onClick.bind(this);
		this.onLogin = this.onLogin.bind(this);
	}

	componentDidMount() {
		const codeVerifier = createRandomString(128);
		const state = createRandomString(40);
		const codeChallenge = getChallenge(codeVerifier);
		this.setState({
			codeChallenge: codeChallenge,
			state: state,
		});
		localStorage.setItem('state', state);
		localStorage.setItem('codeVerifier', codeVerifier);

		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get('session')) {
			this.setState({
				isExpired: true,
			});
		}
	}

	onClick() {
		this.setState({ isContactUsActive: !this.state.isContactUsActive });
	}

	onLogin() {
		const loginUrl = process.env.REACT_APP_LOGIN_URI;
		window.location =
			loginUrl +
			'?client_id=' +
			process.env.REACT_APP_APP_KEY +
			'&code_challenge=' +
			this.state.codeChallenge +
			'&code_challenge_method=S256' +
			'&response_type=code' +
			'&scope=' +
			'&state=' +
			this.state.state +
			'&redirect_uri=' +
			encodeURIComponent(process.env.REACT_APP_CALLBACK_URL);
	}

	render() {
		return (
			<>
				<SessionExpiredModal isExpired={this.state.isExpired} />
				<div
					className='slds-align_absolute-center'
					style={{ backgroundColor: '#DADFE2', height: '100%' }}
				>
					<div style={{ width: '80%' }}>
						<div
							className='slds-grid slds-grid_align-center'
							style={{ height: 'auto' }}
						>
							<div className='slds-cols slds-size_10-of-12'>
								<div className='slds-grid'>
									<div
										className={cx(
											styles.leftPane,
											'slds-col',
											'slds-size_2-of-5'
										)}
									>
										{this.state.isContactUsActive ? (
											<LeftPane2
												onReturnClick={this.onClick}
											/>
										) : (
											<LeftPane1
												onLoginClick={this.onLogin}
											/>
										)}
									</div>
									<div
										className={cx(
											styles.rightPane,
											'slds-col',
											'slds-size_3-of-5',
											'slds-align_absolute-center'
										)}
									>
										<RightPane />
									</div>
								</div>
							</div>
						</div>
						<div
							className='slds-grid slds-grid_align-center'
							style={{ height: 'auto', paddingTop: '20px' }}
						>
							<div className='slds-cols slds-size_10-of-12'>
								<div
									className={cx(
										styles.fontPoppins,
										'slds-grid'
									)}
								>
									<div
										className='slds-cols slds-size_1-of-2'
										style={{ textAlign: 'left' }}
									>
										<span style={{ fontSize: '10px' }}>
											© 2021 Bayad. All rights reserved.
										</span>
									</div>
									<div
										className='slds-cols slds-size_1-of-2'
										style={{ textAlign: 'right' }}
									>
										<span style={{ fontSize: '10px' }}>
											<a onClick={this.onClick}>
												Contact Us
											</a>{' '}
											| <a>BIP Policy</a>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Login;
